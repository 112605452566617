import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import Callaback from '../Callback'
import RejectedUser from '../../components/RejectedUser'
import CompanyAdminPage from '../CompanyAdminpage'
import Verify from '../Verify'
import PrivacyPolicy from '../PrivacyPolicy'
import UpdateProfile from '../../components/UpdateProfile'
import UserProfile from '../../components/UserProfile'
import CompanyProfilePage from "../CompanyProfilePage";
import UserAgreement from "../../components/UserAgreement"
import UserCreateProfile from "../../components/UserCreateProfile"
import UnderVerification from "../../components/UnderVerification"
import SystemAdminPage from "../SystemAdminPage"
import SystemAdminCompanies from "../SystemAdminCompaniesPage"
import ProspectiveClientPage from "../ProspectiveClientPage"
import CombinedLandingPage from "../CombinedLandingPage"
import SuspendedUser from '../../components/SuspendUser'
import ExpiredLandingPage from "../ExpiredLoadPage"
import AwardedLandingPage from "../AwardedLoadsPage";
import LoadsWonPage from "../LoadsWonPage";
import ActiveLoadsPage from "../ActiveLoadspage";
import { useAuth0 } from '@auth0/auth0-react';
import PreferredCarrierPage from '../PreferredCarrierPage';
import slugid from "slugid";
import SuspendedCompany from "../../components/SuspendedCompany";
import FeedbackUserPage from "../FeedbackPage";
import ExpiredBidPage from "../ExpiredBidPage";
import AdminPage from "../AdminPage";
import NotAuthorizedOrNoPath from "../NotAuthorizedNoPathPage";
import Page403Error from "../Page403Error";
import NotAuthenticatePage from "../NotAuthenticatedPage";
import SavedLanePage from "../SavedLanes"
import EmailSettingsPage from "../EmailSettingsPage";
import CertificationsPage from "../CertificationsPage";
import SupportPage from "../SupportPage";

const AllRoute = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  // Constant to handle logged in user role
  const [loggedInuserRole, setLoggedInuserRole] = useState(window.localStorage.getItem('user_role'));

  // Constant to handle logged in Company name
  const [userCompanyName, setUserCompanyName] = useState(window.localStorage.getItem('userCompanyName'));

  useEffect(() => {
    let urlFromEmail = window.location.href;
    let loadGuid = urlFromEmail.substring(urlFromEmail.indexOf("=") + 1)

    if (window.location.href.includes("loadboard?loadId")) {
      if (isAuthenticated) {
        if (slugid.decode(loadGuid)) {
          window.localStorage.setItem('LoadIdEmail', slugid.decode(loadGuid));
        }
      }
      else {
        if (slugid.decode(loadGuid)) {
          window.localStorage.setItem('LoadIdEmail', slugid.decode(loadGuid));
        }
        loginWithRedirect();
      }
    }
  }, [isAuthenticated, loginWithRedirect]);
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route path='/privacypolicy' component={PrivacyPolicy} />
          <Route path="/notauthenticated" component={NotAuthenticatePage} />
          <Route path='/feedback' component={FeedbackUserPage} />
          {isAuthenticated && (
            <React.Fragment>
              <Route path='/callback' component={Callaback} />
              <Route path='/updateprofile' component={UpdateProfile} />
              <Route path='/settings' component={UserProfile} />
              <Route path='/profile' component={UserProfile} />
              <Route path='/companyprofile' component={CompanyProfilePage} />
              <Route path='/companyadmin' component={CompanyAdminPage} />
              <Route path='/preferredcarrier' component={PreferredCarrierPage} />
              <Route path='/rejecteduser' component={RejectedUser} />
              <Route path='/usersuspended' component={SuspendedUser} />
              <Route path='/companysuspended' component={SuspendedCompany} />
              <Route path='/useragreement' component={UserAgreement} />
              <Route path='/createprofile' component={UserCreateProfile} />
              <Route path='/underverification' component={UnderVerification} />
              <Route path='/systemadmin' component={SystemAdminPage} />
              <Route path='/systemadmincompanies' component={SystemAdminCompanies} />
              <Route path='/prospectiveclients' component={ProspectiveClientPage} />
              <Route path='/loadboard' component={CombinedLandingPage} />
              <Route path='/expiredloads' component={ExpiredLandingPage} />
              <Route path='/awardedloads' component={AwardedLandingPage} />
              <Route path='/bidswon' component={LoadsWonPage} />
              <Route path='/activebids' component={ActiveLoadsPage} />
              <Route path='/activeloads' component={ActiveLoadsPage} />
              <Route path='/expiredbids' component={ExpiredBidPage} />
              <Route path='/admin' component={AdminPage} />
              <Route path="/savedlanes" component={SavedLanePage} />
              <Route path='/emailsettings' component={EmailSettingsPage} />
              <Route path='/documents' component={CertificationsPage} />
              <Route path='/mcverify' component={SupportPage} />
              <Route path='/forbidden' component={Page403Error} />
              <Route exact component={NotAuthorizedOrNoPath} />
            </React.Fragment>
          )
          }
          <Route path='/callback' component={Callaback} />
          <Route path='/verify' component={Verify} />
          <Route path='/feedback' component={FeedbackUserPage} />
          {loggedInuserRole === null && (userCompanyName !== "SystemAdmin" && loggedInuserRole !== "Driver" && userCompanyName !== "SupportPage") && (
            <Route exact component={NotAuthorizedOrNoPath} />
          )
          }
        </Switch>
      </Router>

    </div>
  );
}

export default AllRoute;