import React, { useState, useEffect } from "react";
import {
    TextField,
    Button,
    Grid,
    Paper,
    Box,
    CssBaseline,
    IconButton,
    Snackbar,
} from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Swal from 'sweetalert2';
import { Row, Col } from "react-bootstrap";
import * as AWS from "aws-sdk";
import CloseIcon from "@material-ui/icons/Close";
import ReCAPTCHA from "react-google-recaptcha";
import { uploadFile } from "react-s3";

import "./index.css"
import { environmentVariables } from '../../environment';

const s3 = new AWS.S3({
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, accessKeyId: process.env.REACT_APP_ACCESS_KEY, region: process.env.REACT_APP_S3_BUCKET_REGION
});

const styles = {
    root: {
        display: "flex",
    },
    header: {
        backgroundImage: `url(${"https://loadeo-logo.s3.us-east-2.amazonaws.com/Loadeo_pricing_1920x550.jpg"})`,
        height: '110vh',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    header1: {
        marginTop: "1%"
    },
    content: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content1: {
        marginLeft: "1%"
    }
}

const FeedbackUser = (props) => {
    const history = useHistory();
    const { user, isAuthenticated } = useAuth0();
    const [userName, setUserName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [userComments, setUserComments] = useState("");
    const [user_first_name, setUser_first_name] = React.useState("");
    const [user_last_name, setUser_last_name] = React.useState("");
    const [validateUser, setValidateUser] = useState(false)

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessageText, setSnackBarMessageText] = useState("");

    const [openDropZone, setOpenDropZone] = useState(false);

    const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
        region: process.env.REACT_APP_S3_BUCKET_REGION,
        directory: 'feedback_images',
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    }
    const validate = () => {
        let temp = {}
        temp.userName = userName ? "" : "error"
        temp.emailId = emailId ? "" : "error"
        temp.userComments = userComments ? "" : "error"
        if (userName === null && emailId === null && userComments === null) {
            Swal.fire({
                text: 'Full Name, Email Id and User Comments are Required',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonColor: '#0066cc'
            }
            );
        }
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "");
    }

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // Function to handle open drop down zone
    const handleOpen = () => {
        setOpenDropZone(true);
    }

    // Function to handle open drop down zone
    const handleClose = () => {
        setOpenDropZone(false);
    }

    // Function to upload selected file to s3 bucket
    async function handleSave(files) {
        uploadFile(files[0], config)
            .then((data) => {
            })
            .catch((err) => {
                console.log("error", err)
            }
            )
        setOpenDropZone(false)
    }

    const handleUserNameChange = (event) => {
        if (isAuthenticated !== true) {
            setUserName(event.target.value)
        }
    }

    const handleEmaiIdChange = (event) => {
        if (isAuthenticated !== true) {
            setEmailId(event.target.value)
        }
    }

    const handleUserCommentsChange = (event) => {
        setUserComments(event.target.value)
    }

    // on page load event
    useEffect(() => {
        setUserName(window.localStorage.getItem('userName'))
        if (user !== undefined) {
            setEmailId(user.email)
        }
        getAllResponse();
    }, []);

    //function to get user Name
    async function getAllResponse() {
        await axios({
            url: environmentVariables.getuserdetails,
            method: 'get'
        })
            .then(response => {
                let newResponse = JSON.parse(response.data.body)
                if (user !== undefined) {
                    const findUser = newResponse.find(
                        ({ email_address }) => email_address === user.email
                    );
                    setUser_first_name(findUser.first_name);
                    setUser_last_name(findUser.last_name);
                    setUserName(pascalCase(findUser.first_name) + " " + pascalCase(findUser.last_name),)
                    setEmailId(findUser.email_address)
                }
            })
    }

    //function to trigger on click of approve in newuser table
    async function handleSubmit() {
        let userId
        if (isAuthenticated === true && emailId === "") {
            userId = user.email
            setEmailId(user.email)
        }
        else {
            userId = emailId
        }
        if (validateUser === true) {
            let listValues =
            {
                "user_name": userName,
                "user_email": userId,
                "user_comments": userComments
            };
            if (validate()) {
                try {
                    let response = await axios({
                        url: environmentVariables.feedbackinfodetails,
                        method: "post",
                        data: listValues,
                    })
                    if (response) {
                        let newResponse = JSON.parse(response.data.body)
                        setSnackBarMessageText("FeedBack Submitted SuccessFully");
                        setOpenSnackBar(true);
                        try {
                            let approveMailResponse = await axios({
                                url: environmentVariables.feedbackemailtoadmin,
                                method: "post",
                                data: {
                                    email_address: newResponse.user_email,
                                    user_name: newResponse.user_name,
                                    user_comments: newResponse.user_comments,
                                },
                            })
                            if (approveMailResponse) {
                                if (window.location.pathname !== "/feedback") {
                                    handleCloseFeedBack(false)
                                }
                            }
                        }
                        catch (error) {
                            console.log("error", error)
                        }
                        setUserComments("")
                        if (window.location.pathname === "/feedback" && isAuthenticated === false) {
                            history.push({ pathname: '/' });
                        }
                        if (window.location.pathname === "/feedback" && isAuthenticated === true) {
                            history.push({ pathname: '/loadboard' });
                        }
                    }
                }
                catch (error) {
                    console.log("error", error)
                }
            }
        }
    }

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    //Function to handle CloseForm
    const handleCloseFeedBack = (event) => {
        props.closeForm(event)
    }

    function onChange(value) {
        if (value) {
            setValidateUser(true)
        }
    }
    return (
        <div className={styles.root}>
            <Snackbar open={openSnackBar} autoHideDuration={snackBarMessageText === "Data is loading for you...." ? null : 2000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <div style={window.location.pathname === "/feedback" ? styles.header : { margin: "1%" }}>
                <div style={window.location.pathname === "/feedback" ? styles.content : styles.content1}>
                    <Grid container direction="column" className="RowStyle" style={window.location.pathname === "/feedback" ? { minHeight: '90vh' } : { margin: "1%" }}>
                        <Grid container xs={12} sm={6} alignItems="center" direction="column" style={{ height: "90vh" }}>
                            <Paper id={window.location.pathname !== "/feedback" ? "overlap" : "center"}>
                                <Grid container direction="column" className="GridStyle" style={{ backgroundColor: "#0d2346" }}>
                                    <Row className="RowStyle" style={window.location.pathname !== "/feedback" ? { height: 25, marginBottom: "3%" } : { height: 25, marginTop: "3%" }}>
                                        <Col>
                                            <h6 style={{ color: 'white', fontFamily: "Cambria", fontWeight: 'bold', padding: "0px 10px" }}>Feedback </h6>
                                        </Col>
                                        <Col>
                                            {(window.location.pathname !== "/feedback") && (
                                                <IconButton onClick={() => handleCloseFeedBack(false)} style={{ marginLeft: "75%", paddingTop: "4%", paddingBottom: 0 }}>
                                                    <CloseIcon className="closeIconColor"
                                                        fontSize="small"
                                                        style={{ marginBottom: 10 }}
                                                    />
                                                </IconButton>
                                            )}
                                        </Col>
                                    </Row>
                                </Grid>
                                <Grid container direction="column" alignItems="center" className="GridStyle">
                                    <Row className="RowStyle" style={{ width: "92%", margin: 5, height: 50 }}>
                                        <Col>
                                            <TextField
                                                label="Full Name"
                                                style={{ color: (userName === "" || userName === null) ? "red" : "black", width: '100%', margin: '5px 0px 5px 0px' }}
                                                value={userName}
                                                error={errors.userName && (userName === "" || userName === null)}
                                                onChange={(val) => handleUserNameChange(val)} />
                                        </Col>
                                    </Row>
                                </Grid>
                                <Grid container direction="column" alignItems="center" style={{ width: "100%" }}>
                                    <Row className="RowStyle" style={{ width: "92%", margin: 5 }}>
                                        <Col>
                                            <TextField label="Email id"
                                                style={{ color: emailId === "" ? "red" : "black", width: '100%', margin: '5px 0px 5px 0px', height: 40 }}
                                                value={isAuthenticated ? user.email : emailId}
                                                error={errors.emailId && (isAuthenticated ? user.email === "" : emailId === "")}
                                                onChange={(val) => handleEmaiIdChange(val)} />
                                        </Col>
                                    </Row>
                                </Grid>
                                <Grid container style={{ width: "100%", height: "40%" }}>
                                    <Box border={0} padding={1} style={{ width: "100%", height: 290 }}>
                                        <Row className="RowStyle" style={{ width: "95%", margin: 5 }}>
                                            <Col>
                                                <TextField
                                                    label="Your Comments"
                                                    style={{ color: userComments === "" ? "red" : "black", width: '100%', margin: '5px 0px 5px 0px' }}
                                                    value={userComments}
                                                    error={errors.userComments && userComments === ""}
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    onChange={(val) => handleUserCommentsChange(val)} />
                                            </Col>
                                        </Row>

                                        <Row className="RowStyle" style={{ marginBottom: "2%" }}>
                                            <div class="rc-anchor rc-anchor-normal rc-anchor-light">
                                                <ReCAPTCHA width="200%"
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Row>
                                        {/* commented code for upload functionality currently unused */}
                                        {/* <Col>
                            <AttachFileIcon style={{ color: 'royalblue' }} />
                            <Typography variant='caption' style={{ fontSize: "16px", cursor: 'pointer', color: 'royalblue' }} onClick={handleOpen}>Upload your attachment </Typography>
                            <DropzoneDialog
                                open={openDropZone}
                                onSave={handleSave}
                                acceptedFiles={['.png']}
                                showPreviews={true}
                                maxFileSize={5000000}
                                onClose={handleClose}
                                filesLimit={1}
                            />
                        </Col> */}
                                        <Row className="RowStyle" style={{ marginLeft: 10, marginRight: 10 }}>
                                            <Col>
                                                <Button onClick={() => { handleSubmit() }} style={validateUser === true ? { margin: '0px', border: '1px solid #09F', backgroundColor: "#0d2346", color: 'white', fontWeight: 'bold', width: '100%', padding: '10px', cursor: 'pointer' } : { margin: '0px', color: 'white', backgroundColor: "#d8d8d8", fontWeight: 'bold', width: '100%', padding: '10px', cursor: 'pointer' }} >Submit </Button>
                                            </Col>
                                        </Row>
                                    </Box>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
export default FeedbackUser;