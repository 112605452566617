import React, { useState, useEffect, } from 'react';
import { useHistory } from "react-router";
import axios from 'axios';
import {
    Grid,
    CssBaseline,
    Typography,
    Tooltip,
    Switch
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {
    MuiThemeProvider,
    makeStyles,
    createMuiTheme
} from "@material-ui/core/styles";
import Swal from 'sweetalert2';
import { useAuth0 } from "@auth0/auth0-react";
import { environmentVariables } from "../../environment"

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

import MUIDrawer from "../Drawer";

//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    hide: {
        display: "none",
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    compRoleFormControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MuiSwitch: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#b8cae9"
                },
                colorSecondary: {
                    "&$checked": {
                        // Controls checked color for the thumb
                        color: "#142440"
                    }
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.7,
                    backgroundColor: "#b8cae9",
                    "$checked$checked + &": {
                        // Controls checked color for the track
                        opacity: 0.7,
                        backgroundColor: "#142440"
                    }
                }
            },
            MUIDataTable: {
                root: {},
                paper: {
                    boxShadow: "none",
                },
            },
            MUIDataTableBodyRow: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#ECECEC",
                    },
                    "&:hover": { backgroundColor: "#aab5be!important" },
                    "&:focus": { backgroundColor: "#000!important" },
                },
            },
            MUIDataTableBodyCell: {
                root: {
                    justifyContent: 'center',
                }
            },
            MUIDataTableHeadCell: {},
        },
    });

const CompanyDetails = () => {

    //-------------------------Declaring all the constants ------------------------------
    const history = useHistory();
    const classes = useStyles();
    const { user } = useAuth0();

    // Constant to handle main MuiDatatable
    const [gridPercent, setGridPercent] = useState("100%");
    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const [companyTableRowData, setCompanyTableRowData] = useState([]);

    //constant to handle loader for mui data table
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: "/forbidden" })
        }
        getAllCompanies();
    }, [history, user.email]);

    function getAllCompanies() {
        axios({
            url: environmentVariables.getcompanyinformation,
            method: 'get'
        }).then((response) => {
            let newResponse = JSON.parse(response.data.body)
            if (newResponse !== "CompanyNotFound") {
                if (newResponse.length === 0) {
                    setLoading(false);
                }
                else {
                    setLoading(true);
                    setCompanyTableRowData(newResponse);
                }
            }
            else {
                setCompanyTableRowData([]);
                if (newResponse.length === 0) {
                    setLoading(false)
                }
                else {
                    setLoading(true)
                }
            }
        })
    }

    const options = {
        onRowClick: (clickAllUserRowData, rowState) =>
            companyDetails(clickAllUserRowData, rowState),
        selectableRows: 'none',
        filter: false,
        download: false,
        search: true,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    'No companies are registered'
            },
        }
    };

    //Column definition for company details table
    const companyTableColumnDefs = [
        { label: "Company ID", name: "company_id", options: { display: false } },
        {
            label: "Company Name",
            name: 'company_name',
            options: {
                display: displaySpecificColums,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[1].toUpperCase()
                    )
                }
            }
        },
        {
            label: "MC Number",
            name: 'company_mc_number',
            options: { display: displaySpecificColums }
        },
        {
            label: "Role",
            name: 'company_role',
            options: {
                customBodyRender: (value) => {
                    if (value === "Broker&Carrier") {
                        return "Broker & Carrier"
                    }
                    else {
                        return value
                    }

                }
            }
        },
        {
            label: "Status",
            name: "status",
            options: {
                customBodyRender: (value) => {
                    if (value === "ProfileDeactivated") {
                        return "Not Active"
                    }
                    else if (value === "ProfileAccepted") {
                        return "Active"
                    }

                }
            }
        },
        { label: "Description", name: "companyDescription", options: { display: false } },
        { label: "Address", name: "address", options: { display: false } },
        { label: "City", name: "city", options: { display: false } },
        { label: "State", name: "state", options: { display: false } },
        { label: "Logo", name: "logo", options: { display: false } },
        { label: "Website", name: "website", options: { display: false } },
        { label: "Phone Number", name: "phone_number", options: { display: false } },
        { label: "Email Address", name: "email_address", options: { display: false } },
        {
            label: " ",
            name: "status",
            options: {
                customBodyRender: (value, row) => {
                    return (
                        <Tooltip title={value === "ProfileDeactivated" ? `Activate ${row.rowData[1]}` : `Deactivate ${row.rowData[1]}`}>
                            <Switch
                                disabled={value === "ProfileCreated" ? true : false}
                                checked={value === "ProfileDeactivated" ? false : true}
                                onClick={(event) => {
                                    if (value === "ProfileDeactivated") {
                                        event.stopPropagation();
                                        activateCompany(row.rowData);
                                    }
                                    else if (value === "ProfileAccepted") {
                                        event.stopPropagation();
                                        deactivateCompany(row.rowData);
                                    }
                                }}
                            >
                            </Switch>
                        </Tooltip>

                    )
                }
            }
        },
    ];

    function companyDetails(rowValues) {
        history.push({ pathname: '/companyprofile', mcNumber: rowValues[2] })
    }

    function activateCompany(rowValues) {
        const listValues = {
            company_id: rowValues[0],
            mc_number: rowValues[2],
            status: "ProfileAccepted"
        };
        Swal.fire({
            text: "Are you sure you want activate " + pascalCase(rowValues[1]),
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                axios({
                    url: environmentVariables.setcompanystatus,
                    method: 'patch',
                    data: listValues
                })
                    .then(() => getAllCompanies())
                    .catch((error) => { });
            }
        })
    }

    function deactivateCompany(rowValues) {
        const listValues = {
            company_id: rowValues[0],
            mc_number: rowValues[2],
            status: "ProfileDeactivated"
        };
        Swal.fire({
            text: "Are you sure you want deactivate " + pascalCase(rowValues[1]),
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                axios({
                    url: environmentVariables.setcompanystatus,
                    method: 'patch',
                    data: listValues
                })
                    .then(() => getAllCompanies())
                    .catch((error) => { });
            }
        })
    }

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: gridPercent }} id="Companies">
                            <div style={{ paddingBottom: "15%" }} >
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={"Company Details"}
                                        data={companyTableRowData}
                                        columns={companyTableColumnDefs}
                                        options={options}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </div>
                    </Grid>
                </Typography>
            </main >
        </div>
    )
};

export default CompanyDetails;