import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import {
    Divider,
    IconButton,
    Grid,
    Box,
    TextField,
    Typography
} from "@material-ui/core";
import { Autocomplete as MUIAutoComplete } from "@material-ui/lab";
import { Row, Col } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import cities from "cities";
import Swal from 'sweetalert2';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { DistanceMatrixService } from "@react-google-maps/api";

// Imports to have all the Icons
import CloseIcon from "@material-ui/icons/Close";
import PromptComponent from '../Prompt'
// import { RouterPrompt } from "../Prompt";

import Map from '../Pages/Map';
import { withScriptjs } from "react-google-maps";
/*GLOBAL VARIABLE*/
let today = new Date();
let UpdatedTime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
let currentTime
let dateChanged = false;
let pickUpDay
//--------------------------------------------*********************-----------------------------------------        
const PostLoadForm = (props, ref) => {

    const { user } = useAuth0();

    // constant to handle Prompt message
    const [Prompt, setShowPrompt, setPristine] = PromptComponent()

    // constant to Disable Post Load Button
    const [disablePostLoadButton, setDisablePostLoadButton] = useState(false)

    // Constants to handle from city,state,zip
    const [txtFromZip, updateTxtFromZip] = useState("");
    const [txtFromState, updateTxtFromState] = useState("");
    const [txtFromCity, updateTxtFromCity] = useState("");
    const [isFromCityValid, setIsFromCityValid] = useState(false)
    const [selectedTxtFromCity, setSelectedTxtFromCity] = useState("")

    // Constant to handle from latitude and longitude
    const [fromlatitude, setFromlatitude] = useState(0);
    const [fromlongitude, setFromlongitude] = useState(0);

    // Constants to handle pickupdate and pickupASAP
    const [pickUpDate, updatePickUpDate] = useState(new Date());
    const [disablePickUpWindow, setDisablePickUpWindow] = useState(false);

    // Constants to handle pick up from and to window
    const [pickUpFromHourInputValue, setPickUpFromHourInputValue] = useState("00");
    const [pickUpFromMinInputValue, setPickUpFromMinInputValue] = useState("00");
    const [pickUpToHourInputValue, setPickUpToHourInputValue] = useState("00");
    const [pickUpToMinInputValue, setPickUpToMinInputValue] = useState("00");

    // Constants to handle to city,state,zip
    const [txtToZip, updateTxtToZip] = useState("");
    const [txtToState, updateTxtToState] = useState("");
    const [txtToCity, updateTxtToCity] = useState("");
    const [isToCityValid, setIsToCityValid] = useState(false)
    const [selectedTxtToCity, setSelectedTxtToCity] = useState("")

    // Constant to handle to latitude and longitude
    const [tolatitude, settolatitude] = useState(0);
    const [tolongitude, settolongitude] = useState(0);

    // Constants to handle pick up from and deliver non stop
    const [deliverBydate, updateDeliverBydate] = useState("");
    const [enteredDate, setEnteredDate] = useState("")
    const [disableDeliverByWindow, setDisableDeliverByWindow] = useState(false);

    //constant to compare Deliver Date with approximate Distance Date
    const [approximateDate, setApproximateDate] = useState("")

    //constant to handle deliver date Validationn
    const [deliverDateValidation, setDeliveryDateValidation] = useState(false)

    // Constants to handle deliver from and to window
    const [deliverFromHourInputValue, setDeliverFromHourInputValue] = useState("00");
    const [deliverFromMinInputValue, setDeliverFromMinInputValue] = useState("00");
    const [deliverToHourInputValue, setDeliverToHourInputValue] = useState("00");
    const [deliverToMinInputValue, setDeliverToMinInputValue] = useState("00");

    // Constants to handle Hours For Window
    const hourValueForWindow = Array.from(new Array(12 * 2)).map((_, index) => `${index < 10 ? "0" : ""}${Math.floor(index / 1)}`);

    // Constants to handle Hours and minutes For Load Expiration
    const hourValue = ["00", "01", "03", "06", "12", "24"]
    const minutes = ["00", "15", "30", "45"];

    // Constant for max weight 
    const [txtMaxWeight, updateTxtMaxWeight] = useState("");

    // Constant for number of pieces
    const [txtTotalNoOfPices, updateTxtTotalNoOfPices] = useState("");

    // Constant to handle checkbox hazmat and stackable
    const [checkBoxHazmat, setCheckBoxHazmat] = useState(false);
    const [checkBoxDockHigh, setCheckBoxDockHigh] = useState(false);
    const [checkBoxStackable, setCheckBoxStackable] = useState(false);
    const [checkBoxTempControl, setCheckBoxTempControl] = useState(false);
    const [showCheckboxValidationMessage, setShowCheckboxValidationMessage] = useState(false)

    // Variable to handle length width height
    const [txtLength, setTxtLength] = useState("");
    const [txtWidth, setTxtWidth] = useState("");
    const [txtHeight, setTxtHeight] = useState("");

    // Constant List to handle truck type Checkboxes
    const [saveCheckboxes, setSaveCheckboxes] = useState([]);

    //---------- Hiding code as the feature is not necessary currently ------------//
    // const [txtRate, setTxtRate] = useState("");

    // Hidden code for above market rate checkbox
    // const [checkBoxAboveMarketRate, setCheckBoxAboveMarketRate] = React.useState(false);

    // Constant to handle load notes
    const [loadNotes, updateLoadNotes] = useState("");

    // Constants to handle LoadExperiation
    const [loadExpirationForHour, updateLoadExpirationForHour] = useState("00");
    const [loadExpirationForMin, updateLoadExpirationForMin] = useState("30");

    // Constants to handle Duration,Distance and Load Distance matrix
    const [distances, setDistance] = useState("");
    const [isDistanceApiloaded, setDistanceApiloaded] = useState(false);
    const [showDistanceMatrix, setShowDistanceMatrix] = useState(false);
    // Constant to handle logged in Company name
    const [userCompanyName, setUserCompanyName] = useState(window.localStorage.getItem('userCompanyName'));

    //constant to handle logged in user company mc number
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState(window.localStorage.getItem('company_mc_number'));

    // Constant to handle Load Update,Clear and Post buttons
    const [isValueChanged, setIsValueChanged] = useState(false)

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    // constant to handle map on post load form
    const MapLoader = withScriptjs(Map);

    useEffect(() => {
        if (props.selectedRowData !== undefined) {
            handlePostLoadFormOpen();
        }
        try {
            setInterval(async () => {
                today = new Date();
                UpdatedTime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                currentTime = UpdatedTime.split(":")[0].length < 2 ? "0" + UpdatedTime.split(":")[0] : UpdatedTime.split(":")[0]
            }, 10000);
        } catch (e) {
            console.log(e);
        }
    },
        [props]);
    // +++++++++++++++++++++++++++++++++++++ Function to handle edit changes +++++++++++++++++++++++++++++++++++++++++++++

    //Mahesh Validation Error Checking
    const validate = () => {
        if (props.functionality !== "Delete Load") {
            let temp = {}
            let str = ""
            if (txtMaxWeight === "0") {
                str = 0
            }
            temp.txtMaxWeight = txtMaxWeight ? str : "error"
            temp.txtFromCity = txtFromCity ? "" : "error"
            temp.txtFromZip = txtFromZip ? "" : "error"
            if (txtFromZip.length < 5) {
                temp.txtFromZipLength = "error"
            }
            else {
                temp.txtFromZipLength = ""
            }
            temp.txtToZip = txtToZip ? "" : "error"
            if (txtToZip.length < 5) {
                temp.txtToZipLength = "error"
            }
            else {
                temp.txtToZipLength = ""
            }
            temp.txtToCity = txtToCity ? "" : "error"
            temp.txtFromState = txtFromState ? "" : "error"
            temp.txtToState = txtToState ? "" : "error"
            temp.pickUpFromHourInputValue = pickUpFromHourInputValue ? "" : "error"
            temp.pickUpFromMinInputValue = pickUpFromMinInputValue ? "" : "error"
            temp.pickUpToHourInputValue = pickUpToHourInputValue ? "" : "error"
            temp.pickUpToMinInputValue = pickUpToMinInputValue ? "" : "error"
            temp.deliverFromHourInputValue = deliverFromHourInputValue ? "" : "error"
            temp.deliverFromMinInputValue = deliverFromMinInputValue ? "" : "error"
            temp.deliverToHourInputValue = deliverToHourInputValue ? "" : "error"
            temp.deliverToMinInputValue = deliverToMinInputValue ? "" : "error"
            temp.pickUpDate = pickUpDate ? "" : "error"
            temp.deliverBydate = deliverBydate ? "" : "error"
            if (pickUpDate == null && deliverBydate == null) {
                Swal.fire({
                    text: 'Pick up Date and Delivery Date is Required',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                }
                );
            }
            let pick = moment(pickUpDate, "MM/DD/YYYY");
            let deliver = moment(deliverBydate, "MM/DD/YYYY");
            let difference = deliver.diff(pick, 'days');
            if (difference < 0) {
                Swal.fire({
                    text: 'Delivery Date should be after the Pick up Date',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                }
                );
            }
            if (deliverDateValidation === true) {
                temp.deliverBydate = "error"
            }
            temp.isFromCityValid = isFromCityValid ? "" : "error"
            temp.isToCityValid = isToCityValid ? "" : "error"
            if (!isFromCityValid) {
                // setDisablePostLoadButton(true)
                // Swal.fire({
                //     text: 'invalid  From City name',
                //     type: null,
                //     allowOutsideClick: false,
                //     showConfirmButton: true,
                //     confirmButtonColor: '#0066cc'
                // }
                // );
                if (props.functionality === "Post Load") {
                    updateTxtFromCity("")
                    updateTxtFromState("")
                    updateTxtFromZip("")
                }
                if (props.functionality === "Edit Load" || props.functionality === "Reuse Load") {
                    updateTxtFromCity(selectedTxtFromCity)
                    setIsFromCityValid(true)
                }

            }
            if (!isToCityValid) {
                setDisablePostLoadButton(true)
                // Swal.fire({
                //     text: 'invalid To City name',
                //     type: null,
                //     allowOutsideClick: false,
                //     showConfirmButton: true,
                //     confirmButtonColor: '#0066cc'
                // }
                // );
                if (props.functionality === "Post Load") {
                    updateTxtToCity("")
                    updateTxtToState("")
                    updateTxtToZip("")
                }
                if (props.functionality === "Edit Load" || props.functionality === "Reuse Load") {
                    updateTxtToCity(selectedTxtToCity)
                    setIsToCityValid(true)
                }
            }
            if (saveCheckboxes.length === 0) {
                temp.checkBoxValidation = "error"
                setShowCheckboxValidationMessage(true)
            }
            setErrors({
                ...temp
            })
            return Object.values(temp).every(x => x === "");
        }
    }

    const handleCheckingTextFieldValueChange = () => {
        if (props.functionality === "Edit Load") {
            setIsValueChanged(true)
            setShowPrompt()
        }
    }

    //function Used Repeatedly
    function emptyField(field) {
        setShowDistanceMatrix(false)
        dateChanged = false
        updateDeliverBydate("")
        if (field === "from") {
            updateTxtFromState("")
        }
        if (field === "to") {

        }
    }

    function callDistanceMatrix() {
        setDistanceApiloaded(true);
        setShowDistanceMatrix(true);
    }
    // function to handle onchange value for pick up from city
    const onFromPlaceChanged = (val) => {
        emptyField("from")
        updateTxtFromZip("")
        if (txtFromCity !== "") {
            if (val.target.value === selectedTxtFromCity) {
                setIsFromCityValid(true)
            }
            else {
                setIsFromCityValid(false)
            }
        }
        updateTxtFromCity(val.target.value)
        handleCheckingTextFieldValueChange();
    }

    // function to update from city
    const onFromPlaceSelected = (place) => {
        dateChanged = false
        updateTxtFromState(getState(place.address_components));
        updateTxtFromCity(getArea(place.address_components));
        setSelectedTxtFromCity(getArea(place.address_components))
        setIsFromCityValid(true)
        if (place.geometry) {
            setFromlatitude(place.geometry.location.lat())
            setFromlongitude(place.geometry.location.lng())
            if (cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng())) {
                updateTxtFromZip(cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode);
            }
            if ((document.getElementById("AutoCompFromCity").value.trim() !== "" && document.getElementById("AutoCompToCity").value.trim() !== "") || (document.getElementById("FromZIP").value.trim() !== "" && document.getElementById("ToZIP").value.trim() !== "")) {
                if (place.geometry.location.lat() !== 0 && place.geometry.location.lng() !== 0 && tolatitude !== 0 && tolongitude !== 0) {
                    callDistanceMatrix()
                }
            }
        }
        handleCheckingTextFieldValueChange();
    };
    // function to update from zip code
    const updateFromZipCode = (event) => {
        emptyField("from")
        updateTxtFromCity("")
        updateTxtFromZip(event.target.value);
        if (event.target.value.length === 5) {
            updateTxtFromZip(event.target.value);
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                updateTxtFromState(getFromCityState.state_abbr);
                updateTxtFromCity(getFromCityState.city);
                setSelectedTxtFromCity(getFromCityState.city)
                setIsFromCityValid(true)
                let lat1 = getFromCityState.latitude;
                let long1 = getFromCityState.longitude;
                setFromlatitude(parseFloat(lat1))
                setFromlongitude(parseFloat(long1))
                // if (topTabPannelValue === 0) {
                if ((document.getElementById("AutoCompFromCity").value.trim() !== "" && document.getElementById("AutoCompToCity").value.trim() !== "") || (document.getElementById("FromZIP").value.trim() !== "" && document.getElementById("ToZIP").value.trim() !== "")) {
                    if (parseFloat(lat1) !== 0 && parseFloat(long1) !== 0 && tolatitude !== 0 && tolongitude !== 0) {
                        callDistanceMatrix()
                    }
                }
            }
            else {
                Swal.fire({
                    text: 'Not a valid Postal Code',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc',
                }
                );
                updateTxtFromZip("");
                updateTxtFromState("");
                updateTxtFromCity("");
            }
        }
        handleCheckingTextFieldValueChange();
    };
    const getArea = (addressArray) => {
        let area = " ";
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (
                            "sublocality_level_1" === addressArray[i].types[j] ||
                            "locality" === addressArray[i].types[j]
                        ) {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
        handleCheckingTextFieldValueChange();
    };
    const getState = (addressArray) => {
        let state = " ";
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] &&
                    "administrative_area_level_1" === addressArray[i].types[0]
                ) {
                    state = addressArray[i].short_name;
                    return state;
                }
            }
        }
        handleCheckingTextFieldValueChange();
    };

    // Function to handle pickupdate
    const handlePickUpDateChange = date => {
        pickUpDay = date
        dateChanged = false
        if (moment(date).isAfter(deliverBydate)) {
            updateDeliverBydate(date);
            updatePickUpDate(date);
        }
        else {
            updatePickUpDate(date);
        }
        if (props.functionality === "Edit Load" || props.functionality === "Reuse Load") {
            callDistanceMatrix()
        }
        handleCheckingTextFieldValueChange();
    };

    // Function to handle pick up from hour onChange value
    const handleOnChangePickUpFromHourValue = (event, newValue) => {
        setPickUpFromHourInputValue(newValue);
        handleCheckingTextFieldValueChange();
    }

    //Function to handle PickUp From user input hour values
    const handlePickUpFromHourChange = (event, newInputValue) => {
        if (newInputValue.length === 2) {
            if (newInputValue <= 23) {
                setPickUpFromHourInputValue(newInputValue);
            }
            else {
                setPickUpFromHourInputValue("23");
            }
        }
        else if (newInputValue.length > 2) {
            setPickUpFromHourInputValue("23");
        }
        else {
            setPickUpFromHourInputValue(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle onClose of pick up from hour field
    const handleOnCloseOfPickUpHourField = () => {
        let valueOnClose = pickUpFromHourInputValue
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setPickUpFromHourInputValue("0" + valueOnClose);
            }
        }
        else {
            setPickUpFromHourInputValue("00");
        }
    }

    // Function to handle pick up from hour onChange value
    const handleOnChangePickUpFromMinValue = (event, newValue) => {
        setPickUpFromMinInputValue(newValue);
        handleCheckingTextFieldValueChange();
    }

    //Function to handle PickUp From user input minute values
    const handlePickUpFromMinChange = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    setPickUpFromMinInputValue(newInputValue);
                } else if (newValue <= 2.5) {
                    let timeValue = JSON.stringify(newInputValue - newValue);
                    setPickUpFromMinInputValue(timeValue);
                } else if (newValue > 2.5) {
                    let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    setPickUpFromMinInputValue(timeValue);
                }
            }
            else {
                setPickUpFromMinInputValue("59");
            }
        } else if (newInputValue.length > 2) {
            setPickUpFromMinInputValue("59");
        }
        else {
            setPickUpFromMinInputValue(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle onClose of pick up from minute field
    const handleOnCloseOfPickUpMinField = () => {
        let valueOnClose = pickUpFromMinInputValue;
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setPickUpFromMinInputValue("0" + valueOnClose);
            }
        }
        else {
            setPickUpFromMinInputValue("00");
        }
    }

    // Function to handle pick up to hour onChange value
    const handleOnChangePickUpToHourValue = (event, newValue) => {
        setPickUpToHourInputValue(newValue);
        handleCheckingTextFieldValueChange();
    }

    //Function to handle PickUp To user input hour values
    const handlePickUpToHourChange = (event, newInputValue) => {
        if (newInputValue.length === 2) {
            if (newInputValue <= 23) {
                setPickUpToHourInputValue(newInputValue);
            } else {
                setPickUpToHourInputValue("23");
            }
        }
        else if (newInputValue.length > 2) {
            setPickUpToHourInputValue("23");
        }
        else {
            setPickUpToHourInputValue(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle onClose of pick up to hour field
    const handleOnCloseOfPickUpToHourField = () => {
        let valueOnClose = pickUpToHourInputValue
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setPickUpToHourInputValue("0" + valueOnClose);
            }
        }
        else {
            setPickUpToHourInputValue("00")
        }
    }

    // Function to handle pick up to minute onChange value
    const handleOnChangePickUpToMinValue = (event, newValue) => {
        setPickUpToMinInputValue(newValue);
        handleCheckingTextFieldValueChange();
    }

    //Function to handle PickUp To user input minute values
    const handlePickUpToMinChange = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    setPickUpToMinInputValue(newInputValue);
                } else if (newValue <= 2.5) {
                    let timevalue = JSON.stringify(newInputValue - newValue);
                    setPickUpToMinInputValue(timevalue);
                } else if (newValue > 2.5) {
                    let timevalue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    setPickUpToMinInputValue(timevalue);
                }
            }
            else {
                setPickUpToMinInputValue("59");
            }
        } else if (newInputValue.length > 2) {
            setPickUpToMinInputValue("59");
        }
        else {
            setPickUpToMinInputValue(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle onClose of pick up to minute field
    const handleOnCloseOfPickUpToMinField = () => {
        let valueOnClose = pickUpToMinInputValue
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setPickUpToMinInputValue("0" + valueOnClose);
            }
        }
        else {
            setPickUpToMinInputValue("00")
        }
    }


    // Function to handle pickup asap checkbox
    const handleCheckBoxPickUpASAP = (event) => {
        const target = event.target;
        if (target.checked) {
            setDisablePickUpWindow(true);
            setPickUpFromHourInputValue("00");
            setPickUpFromMinInputValue("00");
            setPickUpToHourInputValue("00")
            setPickUpToMinInputValue("00");
        } else {
            setDisablePickUpWindow(false);
            setPickUpFromHourInputValue("00");
            setPickUpFromMinInputValue("00");
            setPickUpToHourInputValue("00")
            setPickUpToMinInputValue("00");
        }
        handleCheckingTextFieldValueChange();
    };

    // function to handle onchange value for pick up from city
    const onToPlaceChanged = (val) => {
        emptyField("to")
        updateTxtToZip("")
        if (txtToCity !== "") {
            if (val.target.value === selectedTxtToCity) {
                setIsToCityValid(true)
            }
            else {
                setIsToCityValid(false)
            }
        }
        updateTxtToCity(val.target.value)
        handleCheckingTextFieldValueChange();
    }
    // function to update to city
    const onToPlaceSelected = (place) => {
        dateChanged = false
        updateTxtToState(getState(place.address_components));
        updateTxtToCity(getArea(place.address_components));
        setShowDistanceMatrix(false)
        setIsToCityValid(true)

        if (place.geometry) {
            settolatitude(place.geometry.location.lat())
            settolongitude(place.geometry.location.lng())
            if (cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng())) {
                updateTxtToZip(cities.gps_lookup(place.geometry.location.lat(), place.geometry.location.lng()).zipcode);
            }
            if ((document.getElementById("AutoCompFromCity").value.trim() !== "" && document.getElementById("AutoCompToCity").value.trim() !== "") || (document.getElementById("FromZIP").value.trim() !== "" && document.getElementById("ToZIP").value.trim() !== "")) {
                if (fromlatitude !== 0 && fromlongitude !== 0 && place.geometry.location.lat() !== 0 && place.geometry.location.lng() !== 0) {
                    callDistanceMatrix()
                }
            }
        }
        handleCheckingTextFieldValueChange();
    };

    // function to update to zip code
    const updateToZipCode = (event) => {
        emptyField("to")
        updateTxtToCity("")
        updateTxtToZip(event.target.value);
        if (event.target.value.length === 5) {
            updateTxtToZip(event.target.value);
            if (cities.zip_lookup(event.target.value)) {
                let getFromCityState = cities.zip_lookup(event.target.value);
                updateTxtToState(getFromCityState.state_abbr);
                updateTxtToCity(getFromCityState.city);
                setSelectedTxtToCity(getFromCityState.city)
                setIsToCityValid(true)
                let lat2 = getFromCityState.latitude;
                let long2 = getFromCityState.longitude;
                settolatitude(parseFloat(lat2))
                settolongitude(parseFloat(long2))
                if ((document.getElementById("AutoCompFromCity").value.trim() !== "" && document.getElementById("AutoCompToCity").value.trim() !== "") || (document.getElementById("FromZIP").value.trim() !== "" && document.getElementById("ToZIP").value.trim() !== "")) {
                    if (fromlatitude !== 0 && fromlongitude !== 0 && parseFloat(lat2) !== 0 && parseFloat(long2) !== 0) {
                        callDistanceMatrix()
                    }
                }
            }
            else {
                Swal.fire({
                    text: 'Not a valid Postal Code',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc',
                }
                );
                updateTxtToZip("");
                updateTxtToState("");
                updateTxtToCity("");
            }
        }
        handleCheckingTextFieldValueChange();
    };

    /**********************************************Deliver Date Change and Auto Calulate Function***********************************************/
    function setDateOnComparing(comparedDate) {
        updateDeliverBydate(comparedDate);
        setDeliveryDateValidation(false)
    }
    function handleSameDate(min, hour) {
        if (pickUpDate.getDate() === enteredDate.getDate()) {
            updateDeliverBydate(enteredDate)
            let mins = (parseInt(hour) * 60) + min
            let newDate = moment(pickUpDate).add(mins, 'minutes');
            setApproximateDate(newDate._d)
            setDeliveryDateValidation(true)
        }
    }

    //Function to Handle Repeated Code For Delivery Date
    function isDeliverDate(date) {
        if ((txtFromCity !== "" && txtFromZip !== "") && (txtToCity !== "" && txtToZip !== "")) {
            if (approximateDate !== "") {
                dateChanged = "updated"
                if ((date.getDate() > approximateDate.getDate()) || (date.getDate() === approximateDate.getDate())) {
                    setDateOnComparing(date)
                }
                else {
                    let convertDate = new Date(date)
                    let convertApproximateDate = new Date(approximateDate)
                    if ((convertDate > convertApproximateDate) || (convertDate === convertApproximateDate)) {
                        setDateOnComparing(date)
                    }
                    else {
                        setDeliveryDateValidation(true)
                        updateDeliverBydate(date);
                    }
                }
            }
            if (approximateDate === "") {
                if (pickUpDate.getDate() === date.getDate()) {
                    dateChanged = true
                }
                else {
                    dateChanged = "deliver"
                }
                setEnteredDate(date)
                callDistanceMatrix()
            }
        }
        else {
            updateDeliverBydate("");
        }
    }
    // function to handle deliver date
    const handleDeliverByDateChange = date => {
        if (date.getDate() !== pickUpDate.getDate()) {
            if (moment(date).isBefore(moment(pickUpDate))) {
                Swal.fire({
                    text: 'Delivery Date cannot be before the Pick up date',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc',
                });
                setDeliveryDateValidation(false)
            }
            else {
                isDeliverDate(date)
            }
        }
        else {
            isDeliverDate(date)
        }
        handleCheckingTextFieldValueChange();
    };

    //Function UpdateDateChange
    function updateDateChange(newDate) {
        if ((window.location.pathname === "/expiredloads" || props.functionality === "Edit Load") && enteredDate !== "") {
            let date1 = new Date(newDate)
            let date2 = new Date(enteredDate)
            if (newDate.getDate() === enteredDate.getDate() || date2 > date1) {
                setDateOnComparing(enteredDate)
            }
            else if (dateChanged === "deliver") {
                updateDeliverBydate(enteredDate)
                setDeliveryDateValidation(true)
            }
            else {
                updateDeliverBydate(newDate)
            }
        }
        else {
            updateDeliverBydate(newDate)
        }
        setApproximateDate(newDate)
    }
    /************************************************************************************************************************/
    //Function to handle on change value of deliver from hour value
    const handleOnChangeDeliverFromHourValue = (event, newValue) => {
        setDeliverFromHourInputValue(newValue);
        handleCheckingTextFieldValueChange();
    }

    //Function to handle Deliver From user input hour values
    const handleDeliverFromHourChange = (event, newInputValue) => {
        if (newInputValue.length === 2) {
            if (newInputValue <= 23) {
                setDeliverFromHourInputValue(newInputValue);
            } else {
                setDeliverFromHourInputValue("23")
            }
        }
        else if (newInputValue.length > 2) {
            setDeliverFromHourInputValue("23")
        }
        else {
            setDeliverFromHourInputValue(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle onClose of pick up to minute field
    const handleOnCloseOfDeliverFromHourField = () => {
        let valueOnClose = deliverFromHourInputValue
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setDeliverFromHourInputValue("0" + valueOnClose);
            }
        }
        else {
            setDeliverFromHourInputValue("00")
        }
    }

    //Function to handle on change value of deliver from min value
    const handleOnChangeDeliverFromMinValue = (event, newValue) => {
        setDeliverFromMinInputValue(newValue);
        handleCheckingTextFieldValueChange();
    }

    //Function to handle Deliver From user input minute values
    const handleDeliverFromMinChange = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    setDeliverFromMinInputValue(newInputValue);
                } else if (newValue <= 2.5) {
                    let timeValue = JSON.stringify(newInputValue - newValue);
                    setDeliverFromMinInputValue(timeValue);
                } else if (newValue > 2.5) {
                    let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    setDeliverFromMinInputValue(timeValue);
                }
            }
            else {
                setDeliverFromMinInputValue("59");
            }
        } else if (newInputValue.length > 2) {
            setDeliverFromMinInputValue("59");
        }
        else {
            setDeliverFromMinInputValue(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle onClose of deliver from minute field
    const handleOnCloseOfDeliverFromMinField = () => {
        let valueOnClose = deliverFromMinInputValue
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setDeliverFromMinInputValue("0" + valueOnClose);
            }
        }
        else {
            setDeliverFromMinInputValue("00")
        }
    }

    //Function to handle on change value of deliver to hour value
    const handleOnChangeDeliverToHourValue = (event, newValue) => {
        setDeliverToHourInputValue(newValue);
        handleCheckingTextFieldValueChange();
    }

    //Function to handle Deliver To user input hour values
    const handleDeliverToHourChange = (event, newInputValue) => {
        if (newInputValue.length === 2) {
            if (newInputValue <= 23) {
                setDeliverToHourInputValue(newInputValue);
            } else {
                setDeliverToHourInputValue("23")
            }
        }
        else if (newInputValue.length > 2) {
            setDeliverToHourInputValue("23")
        }
        else {
            setDeliverToHourInputValue(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle onClose of deliver to hour field
    const handleOnCloseOfDeliverToHourField = () => {
        let valueOnClose = deliverToHourInputValue
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setDeliverToHourInputValue("0" + valueOnClose);
            }
        }
        else {
            setDeliverToHourInputValue("00")
        }
    }

    //Function to handle on change value of deliver to min value
    const handleOnChangeDeliverToMinValue = (event, newValue) => {
        setDeliverToMinInputValue(newValue);
        handleCheckingTextFieldValueChange();
    }

    //Function to handle Deliver To user input minute values
    const handleDeliverToMinChange = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    setDeliverToMinInputValue(newInputValue);
                } else if (newValue <= 2.5) {
                    let timeValue = JSON.stringify(newInputValue - newValue);
                    setDeliverToMinInputValue(timeValue);
                } else if (newValue > 2.5) {
                    let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    setDeliverToMinInputValue(timeValue);
                }
            }
            else {
                setDeliverToMinInputValue("59");
            }
        } else if (newInputValue.length > 2) {
            setDeliverToMinInputValue("59");
        }
        else {
            setDeliverToMinInputValue(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle onClose of deliver to minute field
    const handleOnCloseOfDeliverToMinField = () => {
        let valueOnClose = deliverToMinInputValue
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                setDeliverToMinInputValue("0" + valueOnClose);
            }
        }
        else {
            setDeliverToMinInputValue("00")
        }
    }

    // function to handle deliver non stop checkbox
    const handleCheckBoxDeliverDirect = (event) => {
        const target = event.target;
        if (target.checked) {
            setDisableDeliverByWindow(true);
            setDeliverFromHourInputValue("00");
            setDeliverFromMinInputValue("00");
            setDeliverToHourInputValue("00");
            setDeliverToMinInputValue("00");
        } else {
            setDisableDeliverByWindow(false);
            setDeliverFromHourInputValue("00");
            setDeliverFromMinInputValue("00");
            setDeliverToHourInputValue("00");
            setDeliverToMinInputValue("00");
        }
        handleCheckingTextFieldValueChange();
    };

    // Function to set and check MaxWeight textfield
    const handelTxtMaxWeightChange = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    updateTxtMaxWeight(event.target.value);
                    handleCheckingTextFieldValueChange();
                }
            }
        }
    };

    // Function to handle CheckBoxStackable
    const handleCheckBoxStackable = (event) => {
        const target = event.target;
        if (target.checked) {
            setCheckBoxStackable(true);
        } else {
            setCheckBoxStackable(false);
        }
        handleCheckingTextFieldValueChange();
    };

    // Function to handle CheckBoxTempControl
    const handleCheckBoxTempControl = (event) => {
        const target = event.target;
        if (target.checked) {
            setCheckBoxTempControl(true);
        } else {
            setCheckBoxTempControl(false);
        }
        handleCheckingTextFieldValueChange();
    }

    // function to set Total number of pieces
    const handeltxtTotalNoOfPices = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    updateTxtTotalNoOfPices(event.target.value);
                    handleCheckingTextFieldValueChange();
                }
            }
        }
    };

    // Function to handle CheckBoxHazmat
    const handleCheckBoxHazmat = (event) => {
        const target = event.target;
        if (target.checked) {
            setCheckBoxHazmat(true);
        } else {
            setCheckBoxHazmat(false);
        }
        handleCheckingTextFieldValueChange();
    };

    // Function to handle CheckBoxDockHigh
    const handleCheckBoxDockHigh = (event) => {
        const target = event.target;
        if (target.checked) {
            setCheckBoxDockHigh(true);
        } else {
            setCheckBoxDockHigh(false);
        }
        handleCheckingTextFieldValueChange();
    };

    // Function to handle txtlength
    const handletxtLength = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    setTxtLength(event.target.value);
                    handleCheckingTextFieldValueChange();
                }
            }
        }
    };
    const handletxtWidth = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    setTxtWidth(event.target.value);
                    handleCheckingTextFieldValueChange();
                }
            }
        }
    };
    const handletxtHeight = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            if (event.target.value !== "0" || event.target.value === "0") {
                let regExp = /^0+$/g
                let found = regExp.test(event.target.value)
                let result = found ? true : false;
                if (result === false) {
                    setTxtHeight(event.target.value);
                    handleCheckingTextFieldValueChange();
                }
            }
        }
    };

    // Function to handle select all checkbox for truck type for post load
    const handleSelectAllCheckboxChange = (event) => {
        const target = event.target;
        setShowCheckboxValidationMessage(false)
        if (target.checked) {
            document.getElementById("Sprinter/Cargo Van").checked = true
            document.getElementById("Small Straight").checked = true
            document.getElementById("Large Straight").checked = true
            setSaveCheckboxes(["Sprinter/Cargo Van", "Small Straight", "Large Straight"])
        }
        else {
            document.getElementById("Sprinter/Cargo Van").checked = false
            document.getElementById("Small Straight").checked = false
            document.getElementById("Large Straight").checked = false
            if (document.getElementById("Sprinter/Cargo Van").checked || document.getElementById("Small Straight").checked || document.getElementById("Large Straight").checked) {
                setSaveCheckboxes(saveCheckboxes.filter((e) => e !== target.id));
            }
            else {
                setSaveCheckboxes([]);
            }
        }
        handleCheckingTextFieldValueChange();
    };

    // function to handle truck type checkbox changes
    const handleCheckBoxChange = (event) => {
        const target = event.target;
        setShowCheckboxValidationMessage(false)
        if (target.checked) {
            if (saveCheckboxes.includes(target.id) === false) {
                if (saveCheckboxes.length !== 0) {
                    setSaveCheckboxes([...saveCheckboxes, target.id]);
                    if (saveCheckboxes.length === 2 || saveCheckboxes[0] === "Sprinter/Cargo Van, Small Straight,Large Straight") {
                        document.getElementById("all trucks").checked = true
                    }
                }
                else {
                    setSaveCheckboxes([target.id]);
                }
            }
        }
        else {
            document.getElementById("all trucks").checked = false
            setSaveCheckboxes(saveCheckboxes.filter((e) => e !== target.id));
        }
        handleCheckingTextFieldValueChange();
    };

    //Function to handle load expiration hour onChange value
    const handleOnChangeLoadExpirationHourValue = (event, newValue) => {
        if (newValue === "00") {
            updateLoadExpirationForMin("30")
        }
        else {
            updateLoadExpirationForHour(newValue)
        }
        handleCheckingTextFieldValueChange();
    }

    // Function to handle LoadExpirationChange for hours
    const handleLoadExpirationChangeForHour = (event, newInputValue) => {
        if (newInputValue.length === 2) {
            if (newInputValue <= 23) {
                updateLoadExpirationForHour(newInputValue);
            } else {
                updateLoadExpirationForHour("23")
            }
        }
        else if (newInputValue.length > 2) {
            updateLoadExpirationForHour("23")
        }
        else {
            updateLoadExpirationForHour(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    // Function to handle LoadExpirationCloseForHours
    const handleLoadExpirationCloseForHour = () => {
        if (loadExpirationForHour === "00" && loadExpirationForMin === "00") {
            updateLoadExpirationForMin("30")
        }
        let valueOnClose = loadExpirationForHour
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                updateLoadExpirationForHour("0" + valueOnClose);
            }
        }
        else {
            updateLoadExpirationForHour("00")
        }
    }

    //Function to handle load expiration hour onChange value
    const handleOnChangeLoadExpirationMinValue = (event, newValue) => {
        if (loadExpirationForHour === "00") {
            if (newValue === "00") {
                updateLoadExpirationForMin("30")
            }
        } else {
            updateLoadExpirationForMin(newValue)
        }
        handleCheckingTextFieldValueChange();
    }

    // Function to handle LoadExpirationChange For minutes
    const handleLoadExpirationChangeForMin = (event, newInputValue) => {
        let newValue = newInputValue % 5;
        if (newInputValue.length === 2) {
            if (newInputValue < 58) {
                if (newInputValue % 5 === 0) {
                    updateLoadExpirationForMin(newInputValue);
                } else if (newValue <= 2.5) {
                    let timeValue = JSON.stringify(newInputValue - newValue);
                    updateLoadExpirationForMin(timeValue);
                } else if (newValue > 2.5) {
                    let timeValue = JSON.stringify(parseInt(newInputValue) + (newValue % 2) + 1);
                    updateLoadExpirationForMin(timeValue);
                }
            }
            else {
                updateLoadExpirationForMin("59");
            }
        } else if (newInputValue.length > 2) {
            updateLoadExpirationForMin("59");
        }
        else {
            updateLoadExpirationForMin(newInputValue);
        }
        handleCheckingTextFieldValueChange();
    };

    // Function to handle LoadExpirationClose For Minutes
    const handleLoadExpirationCloseForMin = () => {
        if (loadExpirationForHour === "00" && loadExpirationForMin === "00") {
            updateLoadExpirationForMin("30")
        }
        let valueOnClose = loadExpirationForMin
        if ((valueOnClose >= "0" && valueOnClose <= "9")) {
            if (valueOnClose.length < 2) {
                updateLoadExpirationForMin("0" + valueOnClose);
            }
        }
        else {
            updateLoadExpirationForMin("30")
        }
    }

    const handlePostLoadFormOpen = () => {
        setPristine()
        if (props.selectedRowData !== undefined && props.updatedUser === user.email) {
            if (props.selectedRowData.length !== 0) {
                let allData = props.selectedRowData
                updateTxtMaxWeight(allData.max_weight)
                updateTxtTotalNoOfPices(allData.total_item_count)
                setTxtHeight(allData.freight_dimension_height)
                setTxtLength(allData.freight_dimension_length)
                setTxtWidth(allData.freight_dimension_width)
                updateLoadNotes(allData.load_notes)
                let listPickUpCitySplitValues = allData.pickup_dest.split(",").map(function (item) { return item.trim(); });
                let listPickUpStateSplitValues = listPickUpCitySplitValues[1].split("-").map(function (item) { return item.trim(); });
                updateTxtFromCity(listPickUpCitySplitValues[0])
                setSelectedTxtFromCity(listPickUpCitySplitValues[0])
                updateTxtFromState(listPickUpStateSplitValues[0])
                updateTxtFromZip(listPickUpStateSplitValues[1])
                setIsFromCityValid(true)
                const fromZip = cities.zip_lookup(listPickUpStateSplitValues[1]);
                let latFromZip = fromZip.latitude;
                let longFromZip = fromZip.longitude;
                setFromlatitude(parseFloat(latFromZip))
                setFromlongitude(parseFloat(longFromZip))

                let listDeliverCitySplitValues = allData.delivery_dest.split(",").map(function (item) { return item.trim(); });
                let listDeliverStateSplitValues = listDeliverCitySplitValues[1].split("-").map(function (item) { return item.trim(); });
                updateTxtToCity(listDeliverCitySplitValues[0])
                updateTxtToState(listDeliverStateSplitValues[0])
                updateTxtToZip(listDeliverStateSplitValues[1])
                setSelectedTxtToCity(listDeliverCitySplitValues[0])
                setIsToCityValid(true)
                const toZip = cities.zip_lookup(listDeliverStateSplitValues[1]);
                let latToZip = toZip.latitude;
                let longToZip = toZip.longitude;
                settolatitude(parseFloat(latToZip))
                settolongitude(parseFloat(longToZip))

                if (allData.cities_distance) {
                    setDistance(allData.cities_distance)
                    setShowDistanceMatrix(true);
                }
                let arrayToHandleTruckType = allData.truck_type.toString().split(",").map(function (item) { return item.trim(); });
                if (arrayToHandleTruckType[0] !== "") {
                    setSaveCheckboxes(arrayToHandleTruckType);
                    for (let i = 0; i < arrayToHandleTruckType.length; i++) {
                        if (document.getElementById(arrayToHandleTruckType[i]))
                            document.getElementById(arrayToHandleTruckType[i]).checked = true;
                    }
                    if (document.getElementById("Sprinter/Cargo Van").checked && document.getElementById("Small Straight").checked && document.getElementById("Large Straight").checked) {
                        document.getElementById("all trucks").checked = true
                    }
                }
                if (showCheckboxValidationMessage) {
                    document.getElementById("Sprinter/Cargo Van").checked = false
                    document.getElementById("Small Straight").checked = false
                    document.getElementById("Large Straight").checked = false
                    document.getElementById("all trucks").checked = false
                }

                setCheckBoxHazmat(allData.hazmat)
                if (document.getElementById("Hazmat"))
                    document.getElementById("Hazmat").checked = allData.hazmat;

                setCheckBoxDockHigh(allData.dockhigh)
                if (document.getElementById("DockHigh"))
                    document.getElementById("DockHigh").checked = allData.dockhigh;

                setCheckBoxStackable(allData.stackable)
                if (document.getElementById("Stackable"))
                    document.getElementById("Stackable").checked = allData.stackable;
                setCheckBoxTempControl(allData.tempcontrol)
                if (document.getElementById("TemperatureControl"))
                    document.getElementById("TemperatureControl").checked = allData.tempcontrol;
                if (document.getElementById("PickupASAP")) {

                    if (document.getElementById("PickupASAP").checked = (allData.pickup_from_window === "ASAP" && allData.pickup_to_window === "ASAP")) {
                        setDisablePickUpWindow(true)
                        setPickUpFromHourInputValue((allData.pickup_from_window.split(":")[0]));
                        setPickUpFromMinInputValue((allData.pickup_from_window.split(":")[1]));
                        setPickUpToHourInputValue(allData.pickup_to_window.split(":")[0])
                        setPickUpToMinInputValue(allData.pickup_to_window.split(":")[1])
                    } else {
                        setDisablePickUpWindow(false)
                    }
                }
                updateDeliverBydate(new Date(allData.pickupto_date))
                if (document.getElementById("DeliverDirect")) {
                    if (document.getElementById("DeliverDirect").checked = (allData.deliver_from_window === "Deliver Direct(Non-Stop)" && props.selectedRowData.deliver_to_window === "Deliver Direct(Non-Stop)")) {
                        setDisableDeliverByWindow(true)
                        setPickUpFromHourInputValue((allData.pickup_from_window.split(":")[0]));
                        setPickUpFromMinInputValue((allData.pickup_from_window.split(":")[1]));
                        setPickUpToHourInputValue(allData.pickup_to_window.split(":")[0])
                        setPickUpToMinInputValue(allData.pickup_to_window.split(":")[1])
                    }
                    else {
                        setDisableDeliverByWindow(false)
                    }
                }
                let pick = moment(allData.pickupfrom_date, "MM/DD/YYYY");
                let deliver = moment(allData.pickupto_date, "MM/DD/YYYY");
                let pickUpDifference = pick.diff(new Date(), 'days');
                pickUpDifference < 0 ? updatePickUpDate(new Date()) : updatePickUpDate(new Date(allData.pickupfrom_date))
                if (props.functionality === "Reuse Load") {
                    updateDeliverBydate("")
                }
                else {
                    let deliverDifference = deliver.diff(new Date(), 'days');
                    deliverDifference < 0 ? updateDeliverBydate(new Date()) : updateDeliverBydate(new Date(allData.pickupto_date))
                }
                if (allData.deliver_from_window.includes("Non-Stop")) {
                    setDeliverFromHourInputValue("00");
                    setDeliverFromMinInputValue("00");
                }
                else {
                    setDeliverFromHourInputValue((allData.deliver_from_window.split(":")[0]));
                    setDeliverFromMinInputValue((allData.deliver_from_window.split(":")[1]));
                }

                if (allData.deliver_to_window.includes("Non-Stop")) {
                    setDeliverToHourInputValue("00");
                    setDeliverToMinInputValue("00");
                }
                else {
                    setDeliverToHourInputValue((allData.deliver_to_window.split(":")[0]));
                    setDeliverToMinInputValue((allData.deliver_to_window.split(":")[1]));
                }

                if (allData.pickup_from_window.includes("ASAP")) {
                    setPickUpFromHourInputValue("00");
                    setPickUpFromMinInputValue("00");
                }
                else {
                    setPickUpFromHourInputValue((allData.pickup_from_window.split(":")[0]));
                    setPickUpFromMinInputValue((allData.pickup_from_window.split(":")[1]));
                }

                if (allData.pickup_to_window.includes("ASAP")) {
                    setPickUpToHourInputValue("00");
                    setPickUpToMinInputValue("00");
                }
                else {
                    setPickUpToHourInputValue((allData.pickup_to_window.split(":")[0]));
                    setPickUpToMinInputValue((allData.pickup_to_window.split(":")[1]));
                }
                if (allData.load_expiration_time) {
                    updateLoadExpirationForHour((allData.load_expiration_time.split(":")[0]));
                    updateLoadExpirationForMin((allData.load_expiration_time.split(":")[1]));
                }
                return allData;
            }
            if (props.loadUpdatedStatus === true) {
                onTabPannelPostLoad();
            }
        }

    }

    //Function to prevent Default
    function Default(event) {
        if (event.ctrlKey && event.key === 'Enter') {
            props.handleNewOrderClick()
            return false
        }
    }

    useImperativeHandle(ref, () => ({
        handleSendingResponse,
        onTabPannelPostLoad
    }));

    const handleSendingResponse = () => {
        if (props.functionality === "Post Load" || props.functionality === "Reuse Load" || props.functionality === "Delete Load") {
            let pick = moment(pickUpDate, "MM/DD/YYYY");
            let deliver = moment(deliverBydate, "MM/DD/YYYY");
            let difference = deliver.diff(pick, 'days');
            if (difference < 0) {
                Swal.fire({
                    text: 'Delivery Date should be after the Pick up Date',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                }
                );
            }
            else if (validate()) {
                let filteredLoadType = saveCheckboxes.filter(function (el) {
                    return el != null;
                });
                let varPickUpFromWindow = pickUpFromHourInputValue + ":" + pickUpFromMinInputValue;
                let varPickpickUpToWindow = pickUpToHourInputValue + ":" + pickUpToMinInputValue;
                let varDeliverFromWindow = deliverFromHourInputValue + ":" + deliverFromMinInputValue;
                let varDeliverToWindow = deliverToHourInputValue + ":" + deliverToMinInputValue;
                let varLoadExpiration = loadExpirationForHour + ":" + loadExpirationForMin;
                if (document.getElementById("PickupASAP").checked) {
                    varPickUpFromWindow = "ASAP";
                    varPickpickUpToWindow = "ASAP";
                }
                if (document.getElementById("DeliverDirect").checked) {
                    varDeliverFromWindow = "Deliver Direct(Non-Stop)";
                    varDeliverToWindow = "Deliver Direct(Non-Stop)";
                }
                let listValues = [
                    {

                        deliverylat: String(tolatitude),
                        deliverylng: String(tolongitude),
                        pickuplat: String(fromlatitude),
                        pickuplng: String(fromlongitude),
                        posted_by_company: userCompanyName,
                        posted_by_user: user.email,
                        posted_by_mc_number: loggedInCompanyMCNumber,
                        pickupfrom_date: moment(pickUpDate).format("MM/DD/YYYY"),
                        pickup_from_window: varPickUpFromWindow,
                        pickup_to_window: varPickpickUpToWindow,
                        pickup_dest: txtFromCity + ", " + txtFromState + " - " + txtFromZip,
                        pickupto_date: moment(deliverBydate).format("MM/DD/YYYY"),
                        deliver_from_window: varDeliverFromWindow,
                        deliver_to_window: varDeliverToWindow,
                        delivery_dest: txtToCity + ", " + txtToState + " - " + txtToZip,
                        max_weight: txtMaxWeight,
                        total_item_count: txtTotalNoOfPices,
                        load_notes: loadNotes,
                        hazmat: checkBoxHazmat,
                        stackable: checkBoxStackable,
                        dockhigh: checkBoxDockHigh,
                        tempcontrol: checkBoxTempControl,
                        deliverycity: txtToCity,
                        deliverZip: txtToZip,
                        freight_dimension_length: txtLength,
                        freight_dimension_width: txtWidth,
                        freight_dimension_height: txtHeight,
                        truck_type: filteredLoadType
                            .toString(),
                        status: "new",
                        cities_distance: distances,
                        load_expiration_time: varLoadExpiration,
                        time_stamp: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                        watch_load: [],
                        saved_lane: [],
                        read_users: [],
                        isValueChanged: isValueChanged
                    },
                ];
                onTabPannelPostLoad();
                return listValues;
            }
            else {
                return "validate failed"
            }
        }
        else if (props.functionality === "Edit Load" || props.functionality === "Reopen Load") {
            let pick = moment(pickUpDate, "MM/DD/YYYY");
            let deliver = moment(deliverBydate, "MM/DD/YYYY");
            let difference = deliver.diff(pick, 'days');
            if (difference < 0) {
                Swal.fire({
                    text: 'Delivery Date should be after the Pick up Date',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                }
                );
            }
            else if (validate()) {
                // saveCheckboxes.filter(function (el) {
                //     return el != null;
                // });
                let trucktypeValue = ""
                if (saveCheckboxes === "all trucks") {
                    trucktypeValue = 'Sprinter/Cargo Van, Small Straight, Large Straight'
                }
                else {
                    trucktypeValue = saveCheckboxes.toString()
                }
                let varPickUpFromWindow = pickUpFromHourInputValue + ":" + pickUpFromMinInputValue;
                let varPickpickUpToWindow = pickUpToHourInputValue + ":" + pickUpToMinInputValue;
                let varDeliverFromWindow = deliverFromHourInputValue + ":" + deliverFromMinInputValue;
                let varDeliverToWindow = deliverToHourInputValue + ":" + deliverToMinInputValue;
                let varLoadExpiration = loadExpirationForHour + ":" + loadExpirationForMin;
                if (document.getElementById("PickupASAP").checked) {
                    varPickUpFromWindow = "ASAP";
                    varPickpickUpToWindow = "ASAP";
                }
                if (document.getElementById("DeliverDirect").checked) {
                    varDeliverFromWindow = "Deliver Direct(Non-Stop)";
                    varDeliverToWindow = "Deliver Direct(Non-Stop)";
                }
                let listValues = [
                    {
                        deliverylat: String(tolatitude),
                        deliverylng: String(tolongitude),
                        pickuplat: String(fromlatitude),
                        pickuplng: String(fromlongitude),
                        load_id: props.functionality !== "Reopen Load" ? props.selectedRowData.load_id : props.reopenId,
                        posted_by_company: userCompanyName,
                        posted_by_user: user.email,
                        posted_by_mc_number: loggedInCompanyMCNumber,
                        pickupfrom_date: moment(pickUpDate).format("MM/DD/YYYY"),
                        pickup_from_window: varPickUpFromWindow,
                        pickup_to_window: varPickpickUpToWindow,
                        pickup_dest: txtFromCity + ", " + txtFromState + " - " + txtFromZip,
                        pickupto_date: moment(deliverBydate).format("MM/DD/YYYY"),
                        deliver_from_window: varDeliverFromWindow,
                        deliver_to_window: varDeliverToWindow,
                        delivery_dest: txtToCity + ", " + txtToState + " - " + txtToZip,
                        max_weight: txtMaxWeight,
                        total_item_count: txtTotalNoOfPices,
                        load_notes: loadNotes,
                        hazmat: checkBoxHazmat,
                        dockhigh: checkBoxDockHigh,
                        stackable: checkBoxStackable,
                        tempcontrol: checkBoxTempControl,
                        // abovemarketrate: checkBoxAboveMarketRate,
                        deliverycity: txtToCity,
                        deliverzip: txtToZip,
                        freight_dimension_length: txtLength,
                        freight_dimension_width: txtWidth,
                        freight_dimension_height: txtHeight,
                        truck_type: trucktypeValue,//saveCheckboxes.toString(),
                        status: "Active",
                        cities_distance: distances,
                        load_number: props.selectedRowData.load_number,
                        load_expiration_time: varLoadExpiration,
                        load_expired_status: false,
                        time_stamp: props.functionality !== "Reopen Load" ? props.selectedRowData.time_stamp : moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                        watch_load: props.selectedRowData.watch_load,
                        saved_lane: props.selectedRowData.saved_lane,
                        read_users: props.selectedRowData.read_users,
                        isValueChanged: isValueChanged,
                        reopen_load: props.functionality !== "Reopen Load" ? false : true
                    },
                ];
                if (props.loadUpdatedStatus === true) {
                    onTabPannelPostLoad();
                }
                return listValues;
            }
            else {
                return "validate failed"
            }
        }
    }

    // Function to repopulate all the defaults for posting the load
    async function onTabPannelPostLoad() {
        dateChanged = false
        setDeliveryDateValidation(false)
        setIsValueChanged(false)
        updatePickUpDate(new Date());
        if (document.getElementById("PickupASAP"))
            document.getElementById("PickupASAP").checked = false;
        setPickUpFromHourInputValue("00");
        setPickUpFromMinInputValue("00");
        setPickUpToHourInputValue("00");
        setPickUpToMinInputValue("00");

        updateTxtFromZip("");
        updateTxtFromState("");
        updateTxtFromCity("");
        setIsFromCityValid(false)
        setSelectedTxtFromCity("")
        setFromlatitude("")

        setFromlongitude("")

        updateDeliverBydate("");
        if (document.getElementById("DeliverDirect"))
            document.getElementById("DeliverDirect").checked = false;
        setDeliverFromHourInputValue("00");
        setDeliverFromMinInputValue("00");
        setDeliverToHourInputValue("00");
        setDeliverToMinInputValue("00");

        updateTxtToZip("");
        updateTxtToState("");
        updateTxtToCity("");
        setSelectedTxtToCity("")
        setIsToCityValid(false)
        settolatitude("")
        settolongitude("")

        updateTxtMaxWeight("");
        updateTxtTotalNoOfPices("");

        setDistance("");
        setDistanceApiloaded(false)
        setShowDistanceMatrix(false);

        updateLoadNotes("");
        updateLoadExpirationForHour("00");
        updateLoadExpirationForMin("30");

        setDisablePickUpWindow(false);
        setDisableDeliverByWindow(false);

        if (document.getElementById("Stackable"))
            document.getElementById("Stackable").checked = false;

        if (document.getElementById("Hazmat"))
            document.getElementById("Hazmat").checked = false;

        if (document.getElementById("DockHigh"))
            document.getElementById("DockHigh").checked = false;

        if (document.getElementById("TemperatureControl"))
            document.getElementById("TemperatureControl").checked = false;
        if (document.getElementById("all trucks"))
            document.getElementById("all trucks").checked = false;

        setCheckBoxStackable(false);
        setCheckBoxTempControl(false)
        setCheckBoxHazmat(false);
        setCheckBoxDockHigh(false);
        setTxtLength("");
        setTxtWidth("");
        setTxtHeight("");

        if (document.getElementById("Sprinter/Cargo Van"))
            document.getElementById("Sprinter/Cargo Van").checked = false;

        if (document.getElementById("Small Straight"))
            document.getElementById("Small Straight").checked = false;

        if (document.getElementById("Large Straight"))
            document.getElementById("Large Straight").checked = false;
        setSaveCheckboxes([]);
    }
    return (
        <main>
            {Prompt}
            <Grid container direction="row">
                <Grid item xs={12} sm={8}>
                    {/*----------First row of the box which is for Pick up zip,state,city-----------*/}
                    <Row>
                        <Col sm>From <p style={{ color: (txtFromCity && txtFromZip) === "" ? "red" : "black", margin: 0, display: "inline" }}>*</p>:</Col>
                        <Col sm>
                            {
                                <Autocomplete
                                    id="AutoCompFromCity"
                                    style={{ height: 39, width: 140, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, }}
                                    value={txtFromCity}
                                    onChange={(val) => onFromPlaceChanged(val)}
                                    onPlaceSelected={(place) => {
                                        onFromPlaceSelected(place);
                                    }}
                                    disabled={props.functionality === "Reopen Load" ? true : false}
                                    placeholder="City"
                                    types={["(regions)"]}
                                    componentRestrictions={{ country: "us" }}
                                // onKeyDown={(event) => reuseOrEditFromCity(event)}
                                />
                            }
                        </Col>
                        <Col sm>
                            {
                                <TextField
                                    disabled
                                    placeholder="State"
                                    value={txtFromState}
                                    error={errors.txtFromState && txtFromState === ""}
                                    style={{ marginTop: 7, width: 140 }}
                                />
                            }
                        </Col>
                        <Col sm>
                            {
                                <TextField
                                    id="FromZIP"
                                    placeholder="Zip"
                                    autoComplete='off'
                                    value={txtFromZip}
                                    onChange={updateFromZipCode}
                                    disabled={props.functionality === "Reopen Load" ? true : false}
                                    inputProps={{ maxLength: 5 }}
                                    error={errors.txtFromZip && (txtFromZip === "" || txtFromZip.length !== 5)}
                                    style={{ marginTop: 7, width: 143 }}
                                />
                            }
                        </Col>
                    </Row>
                    <div style={{ height: 10 }} />
                    {/*----------Second row of the box which is for selecting pickup date and pick up ASAP-----------*/}
                    <Row>
                        <Col sm>Pick-Up Date <p className="datepicker-Box" style={{ color: pickUpDate === null ? "red" : "black", margin: 0, display: "inline" }}></p>:</Col>
                        <Col sm>
                            {
                                <MuiPickersUtilsProvider utils={DateFnsUtils} width={{ width: "100%" }}>
                                    <DatePicker
                                        id="pickup"
                                        selected={pickUpDate}
                                        dateFormat="MM/dd/yyyy"
                                        minDate={new Date()}
                                        onChange={handlePickUpDateChange}
                                        className={pickUpDate ? 'datepicker-Box' : 'errors'}
                                        onKeyDown={(event) => Default(event)}
                                        autoComplete='off'
                                    />
                                </MuiPickersUtilsProvider>
                            }
                        </Col>
                        <Col sm >{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, marginTop: 9, marginBottom: 9, marginRight: 9 }} id="PickupASAP" color="primary" onChange={handleCheckBoxPickUpASAP} />}Pick-Up ASAP</Col>
                        <Col sm></Col>
                    </Row>
                    <div style={{ height: 10 }} />
                    {/*----------Third row of the box which is for Pick up window-----------*/}
                    <Row>
                        <Col sm style={{ whiteSpace: "nowrap" }}>Pick-Up From Window <p style={{ color: (pickUpFromHourInputValue === null || pickUpFromHourInputValue.length === 1) && (pickUpFromMinInputValue === null || pickUpFromMinInputValue.length === 1) ? "red" : "black", margin: 0, display: "inline" }}></p>:</Col>
                        <Col sm>
                            <Grid container direction="row">
                                <MUIAutoComplete
                                    disabled={props.functionality === "Reopen Load" ? true : disablePickUpWindow}
                                    onClose={handleOnCloseOfPickUpHourField}
                                    className={pickUpFromHourInputValue ? '' : 'error'}
                                    disableClearable
                                    value={pickUpFromHourInputValue}
                                    onChange={(event, newValue) => {
                                        handleOnChangePickUpFromHourValue(event, newValue)
                                    }}
                                    inputValue={pickUpFromHourInputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handlePickUpFromHourChange(event, newInputValue)
                                    }
                                    style={{ width: "34%" }}
                                    options={hourValueForWindow}
                                    renderInput={(params) => (
                                        <TextField {...params} InputProps={{ ...params.InputProps, pattern: "[0-9]*" }} placeholder="HH" variant="standard" />
                                    )}
                                />
                                <p style={{ marginLeft: 5, marginRight: 5 }}>:</p>
                                <MUIAutoComplete
                                    disabled={props.functionality === "Reopen Load" ? true : disablePickUpWindow}
                                    onClose={handleOnCloseOfPickUpMinField}
                                    className={pickUpFromMinInputValue ? '' : 'error'}
                                    //freeSolo
                                    disableClearable
                                    value={pickUpFromMinInputValue}
                                    onChange={(event, newValue) => {
                                        handleOnChangePickUpFromMinValue(event, newValue)
                                    }}
                                    inputValue={pickUpFromMinInputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handlePickUpFromMinChange(event, newInputValue)
                                    }
                                    style={{ width: "34%" }}
                                    options={minutes}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="mm" variant="standard" />
                                    )}
                                />
                            </Grid>
                        </Col>
                        <Col sm style={{ whiteSpace: "nowrap" }}>Pick-Up To Window <p style={{ color: pickUpToHourInputValue === null && pickUpToMinInputValue === null ? "red" : "black", margin: 0, display: "inline" }}></p>:</Col>
                        <Col sm>
                            <Grid container direction="row">
                                <MUIAutoComplete
                                    disabled={props.functionality === "Reopen Load" ? true : disablePickUpWindow}
                                    onClose={handleOnCloseOfPickUpToHourField}
                                    className={pickUpToHourInputValue ? '' : 'error'}
                                    //freeSolo
                                    disableClearable
                                    value={pickUpToHourInputValue}
                                    onChange={(event, newValue) => {
                                        handleOnChangePickUpToHourValue(event, newValue)
                                    }}
                                    inputValue={pickUpToHourInputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handlePickUpToHourChange(event, newInputValue)
                                    }
                                    style={{ width: "34%" }}
                                    options={hourValueForWindow}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="HH" variant="standard" />
                                    )}
                                />
                                <p style={{ marginLeft: 5, marginRight: 5 }}>:</p>
                                <MUIAutoComplete
                                    disabled={props.functionality === "Reopen Load" ? true : disablePickUpWindow}
                                    onClose={handleOnCloseOfPickUpToMinField}
                                    className={pickUpToMinInputValue ? '' : 'error'}
                                    //freeSolo
                                    disableClearable
                                    value={pickUpToMinInputValue}
                                    onChange={(event, newValue) => {
                                        handleOnChangePickUpToMinValue(event, newValue)
                                    }}
                                    inputValue={pickUpToMinInputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handlePickUpToMinChange(event, newInputValue)
                                    }
                                    style={{ width: "34%" }}
                                    options={minutes}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="mm" variant="standard" />
                                    )}
                                />
                            </Grid>
                        </Col>
                    </Row>
                    <div style={{ height: 5 }} />
                    <Divider />
                    <div style={{ height: 5 }} />
                    {/*----------Fourth row of the box which is for to zip code-----------*/}
                    <Row>
                        <Col sm>To <p style={{ color: (txtToCity && txtToZip) === "" ? "red" : "black", margin: 0, display: "inline" }}>*</p>:</Col>
                        <Col sm>
                            {
                                <Autocomplete
                                    id="AutoCompToCity"
                                    style={{ height: 39, width: 140, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, }}
                                    value={txtToCity}
                                    onChange={(val) => onToPlaceChanged(val)}
                                    onPlaceSelected={(place) => {
                                        onToPlaceSelected(place);
                                    }}
                                    disabled={props.functionality === "Reopen Load" ? true : false}
                                    placeholder="City"
                                    types={["(regions)"]}
                                    componentRestrictions={{ country: "us" }}
                                />
                            }
                        </Col>
                        <Col sm>
                            {
                                <TextField
                                    disabled
                                    placeholder="State"
                                    value={txtToState}
                                    error={errors.txtToState && txtToState === ""}
                                    style={{ marginTop: 7, width: 140 }}
                                />
                            }
                        </Col>
                        <Col sm>
                            {
                                <TextField
                                    id="ToZIP"
                                    placeholder="Zip"
                                    autoComplete='off'
                                    value={txtToZip}
                                    onChange={updateToZipCode}
                                    disabled={props.functionality === "Reopen Load" ? true : false}
                                    inputProps={{ maxLength: 5 }}
                                    error={errors.txtToZip && (txtToZip === "" || txtToZip.length !== 5)}
                                    style={{ marginTop: 7, width: 140 }}
                                    helperText={(txtFromZip === txtToZip && txtToZip !== "") ? "Deliver ZipCode is Same as Pick Up Zip Code" : ""}
                                />
                            }
                        </Col>
                    </Row>
                    <div style={{ height: 10 }} />
                    {/*----------Fifth row of the box which is for max deliver time and Deliver direct-----------*/}
                    <Row>
                        <Col sm>Delivery Date  <p style={{ color: deliverBydate === null ? "red" : "black", margin: 0, display: "inline" }}></p>:</Col>
                        <Col sm>
                            {
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        id="deliverBy"
                                        selected={deliverBydate}
                                        dateFormat="MM/dd/yyyy"
                                        minDate={new Date()}
                                        onChange={handleDeliverByDateChange}
                                        className={deliverBydate ? 'datepicker-Box' : 'datepicker-Box'}
                                        onKeyDown={(event) => Default(event)}
                                        autoComplete='off'
                                    />
                                </MuiPickersUtilsProvider>
                            }
                        </Col>
                        <Col sm >{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, marginTop: 9, marginBottom: 9, marginRight: 9 }} id="DeliverDirect" color="primary" onChange={handleCheckBoxDeliverDirect} />}Deliver Direct</Col>
                        <Col sm></Col>
                    </Row>
                    <Row style={{ justifyContent: "center", width: "45%", paddingLeft: "27%" }}>
                        <Typography component={'div'} hidden={!deliverDateValidation} variant='caption' style={{ fontSize: "12px", paddingLeft: 8, color: "red" }}>Delivery Date cannot be less than estimated Date {moment(approximateDate).format("MM/DD/YYYY")}</Typography>
                    </Row>
                    <div style={{ height: 10 }} />
                    {/*----------Sixth row of the box which is for max deliver window-----------*/}
                    <Row>
                        <Col sm style={{ whiteSpace: "nowrap" }}>Delivery From Window <p style={{ color: deliverFromHourInputValue === null && deliverFromMinInputValue === null ? "red" : "black", margin: 0, display: "inline" }}></p>:</Col>
                        <Col sm>
                            <Grid container direction="row">
                                <MUIAutoComplete
                                    disabled={props.functionality === "Reopen Load" ? true : disableDeliverByWindow}
                                    onClose={handleOnCloseOfDeliverFromHourField}
                                    className={deliverFromHourInputValue ? '' : 'error'}
                                    //freeSolo
                                    disableClearable
                                    value={deliverFromHourInputValue}
                                    onChange={(event, newValue) => {
                                        handleOnChangeDeliverFromHourValue(event, newValue)
                                    }}
                                    inputValue={deliverFromHourInputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handleDeliverFromHourChange(event, newInputValue)
                                    }
                                    options={hourValueForWindow}
                                    style={{ width: "34%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="HH" variant="standard" />
                                    )}
                                />
                                <p style={{ marginLeft: 5, marginRight: 5 }}>:</p>
                                <MUIAutoComplete
                                    disabled={props.functionality === "Reopen Load" ? true : disableDeliverByWindow}
                                    onClose={handleOnCloseOfDeliverFromMinField}
                                    className={deliverFromMinInputValue ? '' : 'error'}
                                    //freeSolo
                                    disableClearable
                                    value={deliverFromMinInputValue}
                                    onChange={(event, newValue) => {
                                        handleOnChangeDeliverFromMinValue(event, newValue)
                                    }}
                                    inputValue={deliverFromMinInputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handleDeliverFromMinChange(event, newInputValue)
                                    }

                                    options={minutes}
                                    style={{ width: "34%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="mm" variant="standard" />
                                    )}
                                />
                            </Grid>
                        </Col>
                        <Col sm style={{ whiteSpace: "nowrap" }}>Delivery To Window <p style={{ color: (deliverToHourInputValue === null || deliverToHourInputValue.length === 1) && (deliverToMinInputValue === null || deliverToMinInputValue.length === 1) ? "red" : "black", margin: 0, display: "inline" }}></p>:</Col>
                        <Col sm>
                            <Grid container direction="row">
                                <MUIAutoComplete
                                    disabled={props.functionality === "Reopen Load" ? true : disableDeliverByWindow}
                                    onClose={handleOnCloseOfDeliverToHourField}
                                    className={deliverToHourInputValue ? '' : 'error'}
                                    //freeSolo
                                    disableClearable
                                    value={deliverToHourInputValue}
                                    onChange={(event, newValue) => {
                                        handleOnChangeDeliverToHourValue(event, newValue)
                                    }}
                                    inputValue={deliverToHourInputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handleDeliverToHourChange(event, newInputValue)
                                    }
                                    options={hourValueForWindow}
                                    style={{ width: "34%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="HH" variant="standard" />
                                    )}
                                />
                                <p style={{ marginLeft: 5, marginRight: 5 }}>:</p>
                                <MUIAutoComplete
                                    disabled={props.functionality === "Reopen Load" ? true : disableDeliverByWindow}
                                    onClose={handleOnCloseOfDeliverToMinField}
                                    className={deliverToMinInputValue ? '' : 'error'}
                                    //freeSolo
                                    disableClearable
                                    value={deliverToMinInputValue}
                                    onChange={(event, newValue) => {
                                        handleOnChangeDeliverToMinValue(event, newValue)
                                    }}
                                    inputValue={deliverToMinInputValue}
                                    onInputChange={(event, newInputValue) =>
                                        handleDeliverToMinChange(event, newInputValue)
                                    }
                                    options={minutes}
                                    style={{ width: "34%" }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder="mm" variant="standard" />
                                    )}
                                />
                            </Grid>
                        </Col>
                    </Row>
                    <div style={{ height: 5 }} />
                    <Divider />
                    <div style={{ height: 5 }} />
                    {/*----------Seventh row of the box which is for from Max weight, number of pieces-----------*/}
                    <Row>
                        <Col sm>Weight (lbs)<p style={{ color: txtMaxWeight === "" ? "red" : "black", margin: 0, display: "inline" }}>*</p>:</Col>
                        <Col sm>
                            {
                                <TextField
                                    placeholder="Unit (lbs)"
                                    autoComplete='off'
                                    value={txtMaxWeight}
                                    helperText={txtMaxWeight === "0" ? "Not Valid Input" : ""}
                                    inputProps={{ pattern: "[0-9]*", maxLength: 5 }}
                                    onChange={handelTxtMaxWeightChange}
                                    disabled={props.functionality === "Reopen Load" ? true : false}
                                    error={(errors.txtMaxWeight && txtMaxWeight === "") || txtMaxWeight === "0"}
                                    style={{ width: 140 }}
                                />
                            }
                        </Col>
                        <Col sm >{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, marginTop: 9, marginBottom: 9, marginRight: 9 }} id="Stackable" color="primary" onChange={handleCheckBoxStackable} />}Stackable</Col>
                        <Col sm >{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, marginTop: 9, marginBottom: 9, marginRight: 9 }} id="TemperatureControl" color="primary" onChange={handleCheckBoxTempControl} />}Temperature Control</Col>
                    </Row>
                    <div style={{ height: 10 }} />
                    <Row>
                        <Col sm>Number Of Pieces:</Col>
                        <Col sm>
                            {
                                <TextField value={txtTotalNoOfPices}
                                    autoComplete='off'
                                    error={txtTotalNoOfPices === "0"}
                                    helperText={txtTotalNoOfPices === "0" ? "Not Valid Input" : ""}
                                    inputProps={{ pattern: "[0-9]*" }}
                                    onChange={handeltxtTotalNoOfPices}
                                    disabled={props.functionality === "Reopen Load" ? true : false}
                                    style={{ width: 140 }} />
                            }
                        </Col>
                        <Col sm >{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, marginTop: 9, marginBottom: 9, marginRight: 9 }} id="Hazmat" color="primary" onChange={handleCheckBoxHazmat} />}Hazmat</Col>
                        <Col sm >{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, marginTop: 9, marginBottom: 9, marginRight: 9 }} id="DockHigh" color="primary" onChange={handleCheckBoxDockHigh} />}Dock High</Col>
                    </Row>
                    <div style={{ height: 10 }} />
                    <Row>
                        <Col sm={3}>Freight <br />Dimension (Inch):</Col>
                        <Col sm={9}>
                            <TextField
                                placeholder="Length"
                                autoComplete='off'
                                value={txtLength}
                                style={{ width: 100 }}
                                size="small"
                                inputProps={{ pattern: "[0-9]*", maxLength: 3 }}
                                onChange={handletxtLength}
                                disabled={props.functionality === "Reopen Load" ? true : false}
                            />

                            <IconButton disabled={true}>
                                <CloseIcon className="CloseIcon"
                                    fontSize="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </IconButton>

                            <TextField
                                placeholder="Width"
                                autoComplete='off'
                                value={txtWidth}
                                style={{ width: 100 }}
                                size="small"
                                inputProps={{ pattern: "[0-9]*", maxLength: 3 }}
                                onChange={handletxtWidth}
                                disabled={props.functionality === "Reopen Load" ? true : false}
                            />

                            <IconButton disabled={true}>
                                <CloseIcon className="CloseIcon"
                                    fontSize="small"
                                    style={{ marginBottom: 10 }}
                                />
                            </IconButton>

                            <TextField
                                placeholder="Height"
                                autoComplete='off'
                                value={txtHeight}
                                style={{ width: 100 }}
                                size="small"
                                inputProps={{ pattern: "[0-9]*", maxLength: 3 }}
                                onChange={handletxtHeight}
                                disabled={props.functionality === "Reopen Load" ? true : false}
                            />
                        </Col>
                    </Row>
                    {

                        (showDistanceMatrix) && (
                            <React.Fragment>
                                <div style={{ height: 10 }} />
                                <Row>
                                    <Col sm={2}></Col>
                                    <Col sm={3}></Col>
                                    <Col sm>
                                        <Box boxShadow={3} style={{ width: "100%", backgroundColor: "#dae3ea", alignItems: "center" }}>
                                            <Grid container>
                                                <div style={{ margin: 10 }}>
                                                    Approximate Distance (Drive Time) = {distances}
                                                </div>
                                            </Grid>
                                        </Box>
                                    </Col>
                                    <Col sm={2}></Col>
                                </Row>
                            </React.Fragment>
                        )
                    }
                </Grid>
                <Grid item sm={4}>
                    <div style={{ height: 5 }} />
                    <Grid>
                        <Row style={{ paddingLeft: 20 }}>
                            <Col sm={12}>Truck Type <p style={{ color: saveCheckboxes.length === 0 ? "red" : "black", margin: 0, display: "inline" }}>*</p>:  {<input id="all trucks" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, margin: 9 }} onChange={handleSelectAllCheckboxChange} type="checkbox" required name="terms" />}Select All</Col>
                        </Row>
                    </Grid>
                    <div style={{ height: 3 }} />
                    <Grid container direction="row">
                        <Grid>
                            <Col sm={12}>{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, margin: 9 }} value="1" id="Sprinter/Cargo Van" color="primary" onChange={handleCheckBoxChange} />}Sprinter/Cargo Van</Col>
                            <Col sm={12}>{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, margin: 9 }} value="2" id="Small Straight" color="primary" onChange={handleCheckBoxChange} />}Small Straight</Col>
                            <Col sm={12}>{<input type="checkbox" disabled={props.functionality === "Reopen Load" ? true : false} style={{ width: 17, height: 17, margin: 9 }} value="3" id="Large Straight" color="primary" onChange={handleCheckBoxChange} />}Large Straight</Col>
                            <Col>
                                <Typography className="helperText" component={'div'} hidden={!showCheckboxValidationMessage} variant='caption' style={{ fontSize: "12px", paddingLeft: 8, color: "red" }}>Please select truck type</Typography>
                            </Col>
                        </Grid>
                    </Grid>
                    <div style={{ height: 10 }} />
                    <Grid>
                        <TextField
                            label="Load Notes"
                            autoComplete='off'
                            multiline
                            rows={5}
                            variant="outlined"
                            disabled={props.functionality === "Reopen Load" ? true : false}
                            style={{ width: "95%", margin: 10, backgroundColor: "#ffffff" }}
                            value={loadNotes}
                            onChange={(val) => updateLoadNotes(val.target.value)}
                        />
                    </Grid>
                    <div style={{ height: 10 }} />
                    <Grid>
                        <Row style={{ paddingLeft: 10 }}>
                            <Col sm >
                                {
                                    <React.Fragment>
                                        <Grid container direction="row">
                                            <label style={{ paddingTop: 5, paddingLeft: 15 }}>Load Expiration:</label>
                                            <MUIAutoComplete
                                                disabled={props.functionality === "Reopen Load" ? true : false}
                                                onClose={() => handleLoadExpirationCloseForHour()}
                                                //freeSolo
                                                disableClearable
                                                value={loadExpirationForHour}
                                                onChange={(event, newValue) => {
                                                    handleOnChangeLoadExpirationHourValue(event, newValue)
                                                }}
                                                inputValue={loadExpirationForHour}
                                                onInputChange={(event, newInputValue) =>
                                                    handleLoadExpirationChangeForHour(event, newInputValue)
                                                }
                                                options={hourValue}
                                                style={{ paddingLeft: 10, width: "20%" }}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" />
                                                )}
                                            />
                                            <MUIAutoComplete
                                                disabled={props.functionality === "Reopen Load" ? true : false}
                                                onClose={() => handleLoadExpirationCloseForMin()}
                                                //freeSolo
                                                disableClearable
                                                value={loadExpirationForMin}
                                                onChange={(event, newValue) => {
                                                    handleOnChangeLoadExpirationMinValue(event, newValue)
                                                }}
                                                inputValue={loadExpirationForMin}
                                                onInputChange={(event, newInputValue) =>
                                                    handleLoadExpirationChangeForMin(event, newInputValue)
                                                }
                                                options={minutes}
                                                style={{ paddingLeft: 10, width: "20%" }}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" />
                                                )}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ height: 10 }} />
            {
                isDistanceApiloaded &&
                <DistanceMatrixService
                    options={{
                        destinations: [{ lat: tolatitude, lng: tolongitude }],
                        origins: [{ lat: fromlatitude, lng: fromlongitude }],
                        travelMode: "DRIVING",
                    }}
                    callback={
                        (response) => {
                            if (response !== null) {
                                if (document.getElementById("AutoCompFromCity").value.trim() !== "" && document.getElementById("AutoCompToCity").value.trim() !== "") {
                                    let dt = new Date()
                                    if ((txtFromCity !== "" && (txtFromZip !== "" || txtFromCity === selectedTxtFromCity)) && (txtToCity !== "" && (txtToZip !== "" || txtToCity === selectedTxtToCity))) {
                                        const strDistance = JSON.parse(JSON.stringify(response.rows[0].elements[0].distance));
                                        const kiloMeters = strDistance.value;
                                        let distInMiles = Math.round(kiloMeters * 0.00062137);
                                        let durationStr = distInMiles / 50;
                                        let hour = durationStr.toString().split(".")[0]
                                        let minutes = durationStr.toString().split(".")[1] ? Math.round(((durationStr.toString().split(".")[1]) * 60) / 100) : 0;
                                        if (dateChanged === false || dateChanged === "deliver") {
                                            if (deliverBydate === "" && (pickUpDate.getDate() === dt.getDate())) {
                                                if (window.location.pathname !== "/expiredloads") {
                                                    dt.setMinutes(pickUpDate.getMinutes() + minutes)
                                                    dt.setHours(pickUpDate.getHours() + parseInt(hour))
                                                }
                                                else {
                                                    dt.setMinutes(dt.getMinutes() + minutes)
                                                    dt.setHours(dt.getHours() + parseInt(hour))
                                                }
                                                updateDateChange(dt)
                                            }
                                            else {
                                                let mins = (parseInt(hour) * 60) + minutes
                                                let newDate = moment(pickUpDay).add(mins, 'minutes');
                                                updateDateChange(newDate._d)
                                            }
                                        }
                                        else if (dateChanged === true) {
                                            handleSameDate(minutes, hour)
                                        }
                                        setDistance(`${distInMiles} miles (${hour} hr ${minutes} min)`);
                                    }
                                }
                            }
                        }
                    }
                />
            }

        </main>
    )
}
export default forwardRef(PostLoadForm)