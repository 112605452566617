export const UnPinned = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" fill="none" />
            <path d="M39.8039 20.8075L28.1798 9.18329C28.1222 9.12521 28.0536 9.07911 27.9781 9.04765C27.9026 9.0162 27.8216 9 27.7399 9C27.6581 9 27.5771 9.0162 27.5016 9.04765C27.4261 9.07911 27.3575 9.12521 27.2999 9.18329L27.0273 9.45592C26.4529 10.0348 26.0538 10.7644 25.8762 11.5603C25.6986 12.3563 25.7497 13.1862 26.0235 13.9544L19.8273 20.1507C18.3186 19.497 16.6483 19.3116 15.033 19.6188C13.4176 19.9259 11.9319 20.7114 10.7685 21.8733L10.5578 22.084C10.4997 22.1416 10.4536 22.2101 10.4222 22.2856C10.3907 22.3611 10.3745 22.4421 10.3745 22.5239C10.3745 22.6057 10.3907 22.6867 10.4222 22.7622C10.4536 22.8377 10.4997 22.9062 10.5578 22.9638L17.8569 30.2631L9.18223 38.9379C9.12445 38.9957 9.07863 39.0643 9.04736 39.1397C9.01609 39.2152 9 39.2961 9 39.3778C9 39.4595 9.01609 39.5404 9.04736 39.6159C9.07863 39.6914 9.12445 39.76 9.18223 39.8178C9.24 39.8755 9.30858 39.9214 9.38407 39.9526C9.45955 39.9839 9.54045 40 9.62216 40C9.70386 40 9.78476 39.9839 9.86024 39.9526C9.93573 39.9214 10.0043 39.8755 10.0621 39.8178L18.7368 31.143L26.0359 38.4422C26.0935 38.5003 26.162 38.5464 26.2375 38.5778C26.313 38.6093 26.394 38.6255 26.4758 38.6255C26.5576 38.6255 26.6386 38.6093 26.7141 38.5778C26.7896 38.5464 26.8582 38.5003 26.9158 38.4422L27.1264 38.2315C28.2812 37.0687 29.0612 35.5867 29.366 33.9765C29.6709 32.3663 29.4864 30.7017 28.8366 29.1973L35.0328 23.001C35.8013 23.2794 36.6335 23.3316 37.4308 23.1515C38.228 22.9714 38.957 22.5665 39.5312 21.9848L39.8039 21.7122C39.8658 21.6542 39.9151 21.5842 39.9489 21.5064C39.9826 21.4286 40 21.3447 40 21.2599C40 21.1751 39.9826 21.0912 39.9489 21.0134C39.9151 20.9355 39.8658 20.8655 39.8039 20.8075V20.8075Z" fill="#0E1D25" />
            <rect x="9" y="16.4142" width="2" height="34" transform="rotate(-45 9 16.4142)" fill="white" />
            <rect x="8" y="17.4142" width="2" height="34" rx="1" transform="rotate(-45 8 17.4142)" fill="#0E1D25" />
        </svg>

    )
}