import React from 'react';
import {
    Link,
} from "@material-ui/core";
import './style.css'

const FooterSection = () => {

    return (
        <div className="wpo-footer-area">
            {/* <div className="wpo-footer-top">

            </div> */}
            <div className="wpo-footer-bottom">
                <div className="container">
                    <div className="wpo-footer-bottom-content">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-6 footer-t" style={{ marginTop: 0, marginBottom: 0 }}>
                                <span>© Copyrights 2022. All Rights Reserved.</span>
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-6 footer-t" style={{ right: "1%", marginTop: 0 }} >
                                <ul>
                                    <li style={{ textAlign: "right" }}><Link href="/privacypolicy#california" style={{ textDecoration: 'none', color: 'white' }} target="_blank" variant="body2">[Privacy Policy for California Residents]</Link>
                                        <Link href="/privacypolicy" style={{ marginLeft: "2%", textDecoration: 'none', color: 'white' }} target="_blank" variant="body2">[Privacy Policy]</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FooterSection;
