import React, { useState, useEffect, } from 'react';
import { useHistory } from "react-router";
import axios from 'axios';
import {
    Button,
    Tooltip,
    Switch,
    Box,
    TextField,
    Grid,
    IconButton,
    Snackbar,
    CssBaseline,
    Select,
    MenuItem,
    Typography,
    FormHelperText
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import MuiAlert from "@material-ui/lab/Alert";
import {
    MuiThemeProvider,
    makeStyles,
    useTheme,
    createMuiTheme
} from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import PhoneInput from 'react-phone-input-2';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import { environmentVariables } from "../../environment"

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

// import statements to have all icons
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from "@material-ui/icons/Edit";
import MUIDrawer from "../Drawer";

//  Constants and Css for drawer component
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    hide: {
        display: "none",
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    compRoleFormControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },

}));

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MuiSwitch: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#b8cae9"
                },
                colorSecondary: {
                    "&$checked": {
                        // Controls checked color for the thumb
                        color: "#142440"
                    }
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.8,
                    backgroundColor: "#b8cae9",
                    "$checked$checked + &": {
                        // Controls checked color for the track
                        opacity: 0.8,
                        backgroundColor: "#142440"
                    }
                }
            },
            MUIDataTable: {
                root: {},
                paper: {
                    boxShadow: "none",
                },
            },
            MUIDataTableBodyRow: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#ECECEC",
                    },
                    "&:hover": { backgroundColor: "#aab5be!important" },
                    "&:focus": { backgroundColor: "#000!important" },
                },
            },
            MUIDataTableBodyCell: {
                root: {
                    justifyContent: 'center',
                }
            },
            MUIDataTableHeadCell: {},
        },
    });

const SystemAdmin = () => {
    //-------------------------Declaring all the constants ------------------------------
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();
    const { user } = useAuth0();

    // Constants to handle snackbar
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [colorseverity, setColorseverity] = useState("");
    const [snackBarMessageText, setSnackBarMessageText] = useState("");

    //Constants to handle new user request pane textfields
    const [newUserName, setNewUserName] = useState("");
    const [newUserFirstName, setNewUserFirstName] = useState("");
    const [newUserLastName, setNewUserLastName] = useState("");
    const [newUsercompanyName, setNewUserCompanyName] = useState("");
    const [newUserPhoneNo, setNewUserPhoneNo] = useState("");
    const [newUserEmailId, setNewUserEmailId] = useState("");
    const [newUserRole, setNewUserRole] = useState("");
    const [companyRole, setCompanyRole] = useState("");
    const [newUserCity, setNewUserCity] = useState("");
    const [newUserState, setNewUserState] = useState("");
    const [newUserAddress, setNewUserAddress] = useState("")

    // Constants to handle user details pane textfields
    const [popupMCNumber, setPopupMCNumber] = useState([]);
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [compName, setCompName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [emailId, setEmailId] = useState("");
    const [userId, setUserId] = useState("");
    const [userRole, setUserRole] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [companyMcNumber, setCompanyMcNumber] = useState("");
    const [changedValue, setChangedValue] = useState("");
    const [state, setState] = useState("");
    const [dropOpenNewUser, setDropOpenNewUser] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isFieldEdited, setIsFieldEdited] = useState(false);
    const [isComapnyAdminSuspendable, setIsCompanyAdminSuspendable] = useState(false);
    const [isComapnyAdminDeletable, setIsCompanyAdminDeletable] = useState(false);
    const [noOfAcceptedCompanyAdmins, setNoOfAcceptedCompanyAdmins] = useState(false)
    const [isUniqueCompanyAdminForNewRequest, setIsUniqueCompanyAdminForNewRequest] = useState(false)
    const [dropopen, setDropopen] = useState(false);

    // Constants to set rowdata onclick of row
    const [clickRowData, setClickRowData] = useState([]);
    const [clickNewUserRowData, setClickNewUserRowData] = useState([]);

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    // Constant to handle main MuiDatatable
    const [mainGridPercent, setMainGridPercent] = useState("100%");
    const [gridPercent, setGridPercent] = useState("100%");
    const [companyDetailsGridPercent, setCompanyDetailsGridPercent] = useState("100%")
    const [displaySpecificColums, setDisplaySpecificColums] = useState(true);
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [userTableRowData, setUserTableRowData] = useState([]);
    const [userIformationData, setUserInformationData] = useState([])
    const [companyTableRowData, setCompanyTableRowData] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    // constant to handle disabling button first click
    const [disableApproveButton, setDisableApproveButton] = useState(false)
    const [disableRejectButton, setDisableRejectButton] = useState(false)

    //constant to handle mui data table loader
    const [loadingNewUsers, setLoadingNewUsers] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingCompanies, setLoadingCompanies] = useState(false);

    const disableTextColor = {
        style: { color: '#525252' }
    }

    function getAllCompanies() {
        axios({
            url: environmentVariables.getcompanyinformation,
            method: 'get'
        }).then((response) => {
            let newResponse = JSON.parse(response.data.body)
            if (newResponse !== "CompanyNotFound") {
                if (newResponse.length === 0) {
                    setLoadingCompanies(false);
                }
                else {
                    setLoadingCompanies(true);
                    setCompanyTableRowData(newResponse);
                }
            }
            else {
                setCompanyTableRowData([]);
                if (newResponse.length === 0) {
                    setLoadingCompanies(false)
                }
                else {
                    setLoadingCompanies(true)
                }
            }
        })
    }

    // options for new user request table
    const options = {
        headerStyle: { fontWeight: 600 },
        titleStyle: { fontWeight: 600 },
        filter: false,
        responsive: "vertical",
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        search: true,
        Add: false,
        onRowClick: (clickNewUserRowData, rowState) =>
            EditChanges(clickNewUserRowData, rowState),
        textLabels: {
            body: {
                noMatch: !loadingNewUsers ?
                    <CircularProgress /> :
                    'There are no new user request'
            },
        }
    };

    // options for user details table
    const options1 = {
        onRowClick: (clickAllUserRowData, rowState) =>
            UserDetails(clickAllUserRowData, rowState),
        selectableRows: 'none',
        filter: false,
        download: false,
        search: true,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingUsers ?
                    <CircularProgress /> :
                    'There are no users '
            },
        }
    };

    const optionsCompanyTable = {
        onRowClick: (clickAllUserRowData, rowState) =>
            companyDetails(clickAllUserRowData, rowState),
        selectableRows: 'none',
        filter: false,
        download: false,
        search: true,
        print: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: !loadingCompanies ?
                    <CircularProgress /> :
                    'No companies are registered'
            },
        }
    };

    // Column definition for new user request table
    const columnDefs = [
        { label: "UserId", name: "user_id", options: { display: false } },
        {
            label: "Name", name: 'user_name',
            options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        pascalCase(rowuserdata.rowData[1])
                    )
                }
            }
        },
        {
            label: "Company Name",
            name: 'company_name',
            options: {
                display: displaySpecificColums,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[2]
                    )
                }
            }
        },
        {
            label: "MC Number",
            name: 'mc_number',
            options: { display: displaySpecificColums }
        },
        {
            label: "Email Id",
            name: "email_address",
            options: { display: displaySpecificColums },
        },
        {
            name: "mobile_number",
            label: "Mobile Number",
            options: {
                filter: true,
                sort: true,
            }
        },
        { label: "Role", name: 'user_role' },
        { label: "Company_Status", name: "status", options: { display: false } }
    ];

    //Column definition for user details table
    const userTableColumnDefs = [
        { label: "UserId", name: "user_id", options: { display: false } },
        {
            name: "user_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        pascalCase(rowuserdata.rowData[1])
                    )
                }
            }
        },
        {
            name: "company_name",
            label: "Company Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    if (rowuserdata.rowData[2] !== null) {
                        return (
                            rowuserdata.rowData[2].toUpperCase()
                        )
                    }
                }
            }
        },
        {
            name: "company_mc_number",
            label: "MC Number",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "email_address",
            label: "Email Id",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "mobile_number",
            label: "Mobile Number",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "user_role",
            label: "Role",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[6] === "CompanyAdmin" ? "Company Admin" : rowuserdata.rowData[6] === "Broker&Carrier" ? "Broker & Carrier" : rowuserdata.rowData[6])
                }
            }
        },
        {
            name: "login_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[7] === "ProfileAccepted" ? "Accepted" : rowuserdata.rowData[7] === "ProfileRejected" ? "Not Approved" : rowuserdata.rowData[7] === "ProfileSuspended" ? "Suspended" : "Activated"
                    )
                }
            }
        },
        { label: "Company_Status", name: "status", options: { display: false } }
    ];

    //Column definition for company details table
    const companyTableColumnDefs = [
        { label: "Company ID", name: "company_id", options: { display: false } },
        {
            label: "Company Name",
            name: 'company_name',
            options: {
                display: displaySpecificColums,
                customBodyRender: (tableMeta, rowuserdata) => {
                    return (
                        rowuserdata.rowData[1].toUpperCase()
                    )
                }
            }
        },
        {
            label: "MC Number",
            name: 'company_mc_number',
            options: { display: displaySpecificColums }
        },
        {
            label: "Role",
            name: 'company_role',
            options: {
                customBodyRender: (value) => {
                    if (value === "Broker&Carrier") {
                        return "Broker & Carrier"
                    }
                    else {
                        return value
                    }

                }
            }
        },
        {
            label: "Status",
            name: "status",
            options: {
                customBodyRender: (value) => {
                    if (value === "ProfileDeactivated") {
                        return "Not Active"
                    }
                    else if (value === "ProfileAccepted") {
                        return "Active"
                    }
                    else if (value === "ProfileCreated") {
                        return "Created"
                    }

                }
            }
        },
        { label: "Description", name: "companyDescription", options: { display: false } },
        { label: "Address", name: "address", options: { display: false } },
        { label: "City", name: "city", options: { display: false } },
        { label: "State", name: "state", options: { display: false } },
        { label: "Logo", name: "logo", options: { display: false } },
        { label: "Website", name: "website", options: { display: false } },
        { label: "Phone Number", name: "phone_number", options: { display: false } },
        { label: "Email Address", name: "email_address", options: { display: false } },
        {
            label: " ",
            name: "status",
            options: {
                customBodyRender: (value, row) => {
                    return (
                        <Tooltip title={value === "ProfileDeactivated" ? `Activate ${row.rowData[1]}` : `Deactivate ${row.rowData[1]}`}>
                            <Switch
                                disabled={value === "ProfileCreated" ? true : false}
                                checked={value === "ProfileDeactivated" ? false : true}
                                onClick={(event) => {
                                    if (value === "ProfileDeactivated") {
                                        event.stopPropagation();
                                        activateCompany(row.rowData);
                                    }
                                    else if (value === "ProfileAccepted") {
                                        event.stopPropagation();
                                        deactivateCompany(row.rowData);
                                    }
                                }}
                            >
                            </Switch>
                        </Tooltip>
                    )
                }
            }
        },
    ];

    function companyDetails(rowValues) {
        history.push({ pathname: '/companyprofile', mcNumber: rowValues[2] })
    }

    function activateCompany(rowValues) {
        const listValues = {
            company_id: rowValues[0],
            mc_number: rowValues[2],
            status: "ProfileAccepted"
        };
        Swal.fire({
            text: "Are you sure you want activate " + pascalCase(rowValues[1]),
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                axios({
                    url: environmentVariables.setcompanystatus,
                    method: 'patch',
                    data: listValues
                })
                    .then(() => getAllCompanies())
                    .catch((error) => { });
            }
        })
    }

    function deactivateCompany(rowValues) {
        const listValues = {
            company_id: rowValues[0],
            mc_number: rowValues[2],
            status: "ProfileDeactivated"
        };
        Swal.fire({
            text: "Are you sure you want deactivate " + pascalCase(rowValues[1]),
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                axios({
                    url: environmentVariables.setcompanystatus,
                    method: 'patch',
                    data: listValues
                })
                    .then(() => getAllCompanies())
                    .catch((error) => { });
            }
        })
    }

    /* Function to Convert a String to PascalCase*/
    function pascalCase(str) {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

    // Function to fetch data from api for New user request table
    async function getNewUsers() {
        await axios({
            url: environmentVariables.getnewuserrequest,
            method: 'get'
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== "UserNotPresent") {
                    if (newResponse.length === 0) {
                        setLoadingNewUsers(false)
                    }
                    else {
                        setLoadingNewUsers(true)
                    }
                    let userDetails = [];
                    newResponse.forEach(element => {
                        if (element.mc_numbers) {
                            let temp = element["mc_numbers"][0]["mcNumber"]
                            userDetails.push({ ...element, "mc_number": temp })
                        }
                    });
                    userDetails.sort((a, b) =>
                        b.time_stamp.localeCompare(a.time_stamp)
                    );
                    setRowData(userDetails);
                }
                else {
                    setRowData([]);
                    if (newResponse.length === 0) {
                        setLoadingNewUsers(false)
                    }
                    else {
                        setLoadingNewUsers(true)
                    }
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }

    // function to get all user information
    async function getAllUserInformation() {
        try {
            let response = await axios({
                url: environmentVariables.getuserdetails,
                method: "get",
            })
            if (response) {
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== "UserNotPresent") {
                    if (newResponse.length !== 0) {
                        setUserInformationData(newResponse)
                    }
                }
            }
        }
        catch (error) { }
    }

    // Function to fetch data from api for User details table
    async function getAllUsers() {
        await axios({
            url: environmentVariables.getallusersforsystemadmin,
            method: 'get'
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== "UserNotPresent") {
                    if (newResponse.length === 0) {
                        setLoadingUsers(false)
                    }
                    else {
                        setLoadingUsers(true)
                    }
                    let userDetails = [];
                    newResponse.forEach(element => {
                        if (element.mc_numbers) {
                            let temp = element["mc_numbers"][0]["mcNumber"]
                            userDetails.push({ ...element, "mc_number": temp })
                        }
                    });
                    userDetails.sort((a, b) =>
                        b.time_stamp.localeCompare(a.time_stamp)
                    );
                    setUserTableRowData(userDetails);
                }
                else {
                    setUserTableRowData([]);
                    if (newResponse.length === 0) {
                        setLoadingUsers(false)
                    }
                    else {
                        setLoadingUsers(true)
                    }
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }

    useEffect(() => {
        if (user.email === process.env.REACT_APP_ADMIN_USER) {
            getNewUsers();
            getAllUsers();
            getAllUserInformation();
            getAllCompanies();
        }
        else {
            history.push({ pathname: '/forbidden' });
        }
    }, [history, user.email]);

    //function to handle validation for userdetail table
    const validate = () => {
        let temp = {}
        temp.state = state ? "" : "error"
        temp.city = city ? "" : "error"
        temp.userName = userName ? "" : "error"
        temp.firstName = firstName ? "" : "error"
        temp.lastName = lastName ? "" : "error"
        //temp.mobileNumber = mobileNumber ? "" : "error"
        temp.address = address ? "" : "error"
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "");
    }

    //function to handle validation for newuser table
    const newUserValidate = () => {
        let temp = {}
        temp.newUserName = newUserName ? "" : "error"
        temp.newUserFirstName = newUserFirstName ? "" : "error"
        temp.newUserLastName = newUserLastName ? "" : "error"
        //temp.newUserMobileNumber = newUserMobileNumber ? "" : "error"
        temp.newUserRole = newUserRole ? "" : "error"
        setErrors({
            ...temp
        })
        return Object.values(temp).every(x => x === "");
    }

    // Function to handle MuiAlert
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // Function To handel snackbar
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDropdownOpenNewUser = () => {
        setDropOpenNewUser(true);
    };

    const handleDropdownCloseNewUser = () => {
        setDropOpenNewUser(false);
    };

    // Function to handle Dropdown changes in NewUser Table of role
    const handleDropdownOpen = () => {
        setDropopen(true);
    };

    const handleDropdownClose = () => {
        setDropopen(false);
    };

    //function to close secondary pane
    const closeSecondaryDetailPane = () => {
        setGridPercent("100%");
        setIsDetailOpen(false);
    };

    // function to set changed values
    const handleChangedValue = (event) => {
        if (event.target.validity.valid || event.target.value === "") {
            setChangedValue(true);
        }
        else {
            setChangedValue(false);
        }
    }

    // Function to handle FirstName changes in NewUser Table
    const handleNewUserFirstNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserFirstName(event.target.value);
        }
    };

    // Function to handle LastName changes in NewUser Table
    const handleNewUserLastNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserLastName(event.target.value);
        }
    };

    // Function to handle role changes in NewUser Table
    const handleNewUserRoleChange = (event) => {
        setNewUserRole(event.target.value);
    };

    const handleNewUserCity = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserCity(event.target.value);
        }
    };

    const handleNewUserAddress = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserAddress(event.target.value);
        }
    };

    const handleNewUserState = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setNewUserState(event.target.value);
        }
    };

    //Function to handle user table phone no change
    const handleNewUserPhoneNoChange = (value) => {
        setNewUserPhoneNo(value)

    }

    // Function to handle FirstName changes in UserDetails
    const handleFirstNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setFirstName(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to handle LastName changes in UserDetails
    const handleLastNametxt = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setLastName(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to show Details
    const handleCompanyName = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCompName(event.target.value);
            setIsFieldEdited(true);
        }
    };

    // Function to handle City Changes
    const handleCity = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setCity(event.target.value);
            setIsFieldEdited(true);
        }
    };

    const handleState = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setState(event.target.value);
            setIsFieldEdited(true);
        }
    };

    const handleAddress = (event) => {
        handleChangedValue(event);
        if (changedValue) {
            setAddress(event.target.value);
            setIsFieldEdited(true);
        }
    };

    //Function to handle user table phone no change
    const handleUserPhoneNoChange = (value) => {
        setPhoneNo(value)
        setIsFieldEdited(true);

    }

    //function to close secondary pane of userdetails Table
    const closeSecondaryUserPane = () => {
        setGridPercent("100%");
        setIsDetailOpen(false);
    };

    // Function to handle role changes in UserDetails Table
    const handleRoleChange = (event) => {
        setUserRole(event.target.value);
        setIsFieldEdited(true);
    };

    // Function to handle the change of mc_number role dropdown
    const handleMCNumberRoleChange = (event) => {
        const value = event.target.value;
        const updatedPopupMCNumber = popupMCNumber.map(mcNumberObj => mcNumberObj.mcNumber === event.target.name ? { ...mcNumberObj, 'role': value } : { ...mcNumberObj })
        setPopupMCNumber([...updatedPopupMCNumber]);
    };

    // Function to handle pane close
    const closeSecondaryPane = () => {
        setMainGridPercent("100%");
        setIsPaneOpen(false);
    };

    async function handleUpdateUserDetailsApiCall(listValues) {
        if (listValues.email_settings === null || listValues.email_settings === "") {
            listValues = { ...listValues, "email_settings": [] };
        }
        try {
            let response = await axios({
                url: environmentVariables.updateloadeouserdetails + "?page=" + "SystemAdmin",
                method: 'patch',
                data: listValues
            })
            return response
        }
        catch (error) { }
    }

    //function to trigger on click of edit in userdetail table
    const EditChanges = (selectedRowData, selectedRowState) => {
        setClickNewUserRowData(selectedRowData);
        setDisableApproveButton(false)
        setDisableRejectButton(false)
        setMainGridPercent("45%");
        if (selectedRowData !== undefined && selectedRowData !== null) {
            const completeSelectedRowData = rowData.find(({ user_id }) => user_id === selectedRowData[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setPopupMCNumber([...completeSelectedRowData.mc_numbers]);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number)
                setNewUserName(completeSelectedRowData.user_name);
                setNewUserAddress(completeSelectedRowData.address);
                setNewUserCity(completeSelectedRowData.city);
                setNewUserCompanyName(completeSelectedRowData.company_name);
                setNewUserEmailId(completeSelectedRowData.email_address);
                setNewUserPhoneNo(completeSelectedRowData.mobile_number);
                setNewUserState(completeSelectedRowData.state);
                if (completeSelectedRowData.first_name === undefined) {
                    setNewUserFirstName("")
                }
                else {
                    setNewUserFirstName(completeSelectedRowData.first_name)
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setNewUserLastName("")
                }
                else {
                    setNewUserLastName(completeSelectedRowData.last_name)
                }
                setNewUserRole(completeSelectedRowData.user_role);
                setCompanyRole(completeSelectedRowData.company_role);
                setUserId(completeSelectedRowData.user_id);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number);
                setIsPaneOpen(true);
                if (completeSelectedRowData.user_role === "CompanyAdmin") {
                    let mcNumer = completeSelectedRowData.company_mc_number;
                    let noOfCompanyAdmins = 0;
                    for (let i = 0; i < userIformationData.length; i++) {
                        if ((userIformationData[i].company_mc_number === mcNumer && userIformationData[i].user_role === "CompanyAdmin")) {
                            noOfCompanyAdmins += 1;
                            if (noOfCompanyAdmins <= 1) {
                                setIsUniqueCompanyAdminForNewRequest(false);
                            }
                            else {
                                setIsUniqueCompanyAdminForNewRequest(true);
                            }
                        }
                    }
                }
                else {
                    setIsUniqueCompanyAdminForNewRequest(true);
                }

            }
        }
    }

    async function checkFirstUserLoginStatus(rowValues) {
        setDisableApproveButton(true)
        try {
            let response = await axios({
                url: environmentVariables.getcompanyinformation + "?mc_number=" + rowValues[3],
                method: 'get'
            })
            if (response) {
                let newResponse = JSON.parse(response.data.body)
                if (newUserRole === "CompanyAdmin") {
                    approveUserInformation(rowValues);
                }
                else if (newUserRole !== "CompanyAdmin") {
                    if (newResponse[0].status !== "ProfileAccepted") {
                        Swal.fire({
                            text: `${rowValues[2]} company registration not approved`,
                            type: null,
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            confirmButtonColor: '#0066cc'
                        });
                    }
                    else {
                        approveUserInformation(rowValues);
                    }
                }
                else {
                    approveUserInformation(rowValues);
                }
            }
        }
        catch (error) { }
        setDisableApproveButton(false)
    }

    //function to trigger on click of approve in newuser table
    async function approveUserInformation(rowValues) {
        if (newUserValidate()) {
            if ((newUserPhoneNo.length === 13 || newUserPhoneNo.length === 14 || newUserPhoneNo.length === 10) && newUserPhoneNo !== "") {
                let completeSelectedRowData = rowData.find(({ user_id }) => user_id === userId)
                if ((userRole === "Carrier") || (userRole === "Broker&Carrier")) {
                    completeSelectedRowData = { ...completeSelectedRowData, 'carrier_preferred': 'Preferred' }
                }
                let listValues = completeSelectedRowData;
                listValues.user_name = newUserFirstName + " " + newUserLastName;
                listValues.address = newUserAddress;
                listValues.city = newUserCity;
                listValues.state = newUserState;
                listValues.first_name = newUserFirstName;
                listValues.last_name = newUserLastName;
                listValues.mobile_number = newUserPhoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
                listValues.user_role = newUserRole;
                listValues.new_user_status = "false";
                listValues.login_status = "ProfileAccepted";
                listValues.company_mc_number = companyMcNumber;
                if (rowValues[7] !== "ProfileAccepted" && newUserRole === "CompanyAdmin") {
                    listValues.status = "ProfileAccepted"
                }
                else {
                    listValues.status = "ProfileAccepted"
                }
                try {
                    let approveUserResponse = await handleUpdateUserDetailsApiCall(listValues);
                    if (approveUserResponse) {
                        getAllUsers();
                        getNewUsers();
                        getAllUserInformation();
                        setColorseverity("success")
                        setSnackBarMessageText(rowValues[1] + " has been approved")
                        setOpenSnackBar(true);
                        closeSecondaryPane()
                        try {
                            await axios({
                                url: environmentVariables.approveusernotification,
                                method: "put",
                                data: {
                                    email_address: completeSelectedRowData.email_address,
                                },
                            })
                        }
                        catch (error) { }
                    }
                }
                catch (error) {
                    console.log(error)
                }
            }
            else {
                Swal.fire({
                    text: 'Mobile number is not valid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    }

    //function to trigger on click of reject in newuser table
    async function rejectedUser(rowValues) {
        setDisableRejectButton(true)
        let completeSelectedRowData = rowData.find(({ user_id }) => user_id === userId)
        let listValues = completeSelectedRowData;
        listValues.mobile_number = newUserPhoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
        listValues.login_status = "ProfileRejected"
        listValues.new_user_status = "false"
        if (rowValues[7] !== "ProfileAccepted") {
            listValues.status = "ProfileRejected"
        }
        listValues = { ...listValues, "rejected_by": "SystemAdmin" };
        listValues = { ...listValues }
        try {
            let rejectUserResponse = await handleUpdateUserDetailsApiCall(listValues);
            if (rejectUserResponse) {
                getNewUsers();
                getAllUsers();
                getAllUserInformation();
                closeSecondaryPane()
                setColorseverity("error")
                setSnackBarMessageText(rowValues[1] + " has been rejected")
                setOpenSnackBar(true);
                try {
                    await axios({
                        url: environmentVariables.sendRejectedMail,
                        method: "patch",
                        data: {
                            email_address: completeSelectedRowData.email_address,
                        },
                    })
                }
                catch (error) { }
            }
        }
        catch (error) {
            console.log(error)
        }
        setDisableRejectButton(false)
    }

    // Function to show pop-up while Suspend
    function handleRejectConfirmClick(rowValues) {
        handleClose()
        Swal.fire({
            text: "Are you sure you want to reject " + rowValues[1] + "?",
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                rejectedUser(rowValues)
            }
        })
    }

    // function to set values onclick of edit button
    function EditSelected(rowvalues) {
        setIsEditOpen(false)
        setGridPercent("45%");
        handleClose()
        if (userTableRowData !== undefined && userTableRowData !== null) {
            const completeSelectedRowData = userTableRowData.find(({ user_id }) => user_id === rowvalues[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setUserName(completeSelectedRowData.user_name);
                setAddress(completeSelectedRowData.address);
                setCity(completeSelectedRowData.city);
                if (completeSelectedRowData.first_name === undefined) {
                    setFirstName("")
                }
                else {
                    setFirstName(completeSelectedRowData.first_name)
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setLastName("")
                }
                else {
                    setLastName(completeSelectedRowData.last_name)
                }
                setCompName(completeSelectedRowData.company_name);
                setEmailId(completeSelectedRowData.email_address);
                setPhoneNo(completeSelectedRowData.mobile_number);
                setState(completeSelectedRowData.state);
                setUserRole(completeSelectedRowData.user_role);
                setUserId(completeSelectedRowData.user_id);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number);
            }
        }
    }

    //function to update the changes on save changes of userdetails table
    async function EditUserDetails(rowValues) {
        if (validate()) {
            if ((phoneNo.length === 13 || phoneNo.length === 14 || phoneNo.length === 10) && phoneNo !== "") {
                let completeSelectedRowData = userTableRowData.find(({ user_id }) => user_id === userId)
                if ((userRole === "Carrier") || (userRole === "Broker&Carrier")) {
                    completeSelectedRowData = { ...completeSelectedRowData, 'carrier_preferred': 'Preferred' }
                }
                completeSelectedRowData = { ...completeSelectedRowData, 'status': 'ProfileAccepted' }
                let listValues = completeSelectedRowData;
                listValues.user_name = firstName + " " + lastName;
                listValues.first_name = firstName;
                listValues.last_name = lastName;
                listValues.address = address;
                listValues.city = city;
                listValues.state = state;
                listValues.mobile_number = phoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
                listValues.user_role = userRole;
                listValues.new_user_status = "false";
                try {
                    let response = await handleUpdateUserDetailsApiCall(listValues);
                    if (response) {
                        getNewUsers();
                        getAllUsers();
                        getAllUserInformation();
                        setColorseverity("success")
                        setSnackBarMessageText(rowValues[1] + " has been updated")
                        setOpenSnackBar(true);
                        closeSecondaryUserPane()
                    }
                }
                catch (error) {
                    console.log(error)
                }
            }
            else {
                Swal.fire({
                    text: 'Mobile number is not valid',
                    type: null,
                    allowOutsideClick: false,
                    showConfirmButton: true,
                    confirmButtonColor: '#0066cc'
                });
            }
        }
    }

    // Function to suspend the user
    async function SuspendUser(rowValues) {
        const completeSelectedRowData = userTableRowData.find(({ user_id }) => user_id === rowValues[0])
        let listValues = completeSelectedRowData;
        listValues.login_status = "ProfileSuspended"
        listValues = { ...listValues }
        await axios({
            url: environmentVariables.updateloadeouserdetails + "?page=" + "SystemAdmin",
            method: "patch",
            data: listValues
        })
            .then((response) => {
                getNewUsers();
                getAllUsers();
                getAllUserInformation();
                setColorseverity("error")
                setSnackBarMessageText(rowValues[1] + " has been suspended")
                setOpenSnackBar(true);
            })
        closeSecondaryUserPane()
    }

    // Function to show pop-up while Suspend
    function handleSuspendConfirmClick(rowValues) {
        handleClose()
        if (isComapnyAdminSuspendable) {
            Swal.fire({
                text: "Are you sure you want to suspend " + rowValues[1] + "?",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    SuspendUser(rowValues)
                }
            })
        }
        else (
            Swal.fire({
                text: "Cannot suspend Company Admin",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#0066cc'
            })
        )
    }

    // Function to activate user
    async function ActivateUser(rowValues) {
        const completeSelectedRowData = userTableRowData.find(({ user_id }) => user_id === rowValues[0])
        let listValues = completeSelectedRowData;
        listValues.login_status = "ProfileAccepted"
        if (completeSelectedRowData.status === "ProfileRejected" && userRole === "CompanyAdmin") {
            listValues.status = "ProfileAccepted"
        }
        else {
            listValues.status = "ProfileAccepted"
        }
        listValues = { ...listValues }
        try {
            let response = await handleUpdateUserDetailsApiCall(listValues);
            if (response) {
                getAllUsers();
                getAllUserInformation();
                setColorseverity("success")
                setSnackBarMessageText(rowValues[1] + " has been activated")
                setOpenSnackBar(true);
            }
            closeSecondaryUserPane()
        }
        catch (error) {
            console.log(error)
        }
    }

    // Function to show pop-up while Activate
    function handleActivateConfirmClick(rowValues) {
        handleClose()
        Swal.fire({
            text: "Are you sure you want to activate " + rowValues[1] + "?",
            cancelButtonColor: '#0066cc',
            confirmButtonText: 'Ok',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0066cc'
        }).then((result) => {
            if (result.value) {
                ActivateUser(rowValues)
            }
        })
    }

    // Function to delete the user
    async function handleSoftDelete(rowValues) {
        const completeSelectedRowData = userTableRowData.find(({ user_id }) => user_id === rowValues[0])
        let listValues = completeSelectedRowData;
        listValues.login_status = "ProfileDeleted"
        listValues = { ...listValues }
        await axios({
            url: environmentVariables.updateloadeouserdetails + "?page=" + "SystemAdmin",
            method: "patch",
            data: listValues
        })
            .then((response) => {
                getNewUsers();
                getAllUsers();
                getAllUserInformation();
                setColorseverity("error")
                setSnackBarMessageText(rowValues[1] + " has been deleted")
                setOpenSnackBar(true);
            })
        closeSecondaryUserPane()
    }

    // Function to show pop-up while delete
    function handleSoftDeleteConfirmClick(rowValues) {
        const completeSelectedRowData = userTableRowData.find(({ user_id }) => user_id === rowValues[0])
        handleClose()
        if ((completeSelectedRowData.login_status === "ProfileRejected") || (isComapnyAdminDeletable && completeSelectedRowData.login_status !== "ProfileAccepted") || (isComapnyAdminDeletable && noOfAcceptedCompanyAdmins && completeSelectedRowData.login_status === "ProfileAccepted") || (completeSelectedRowData.user_role !== "CompanyAdmin")) {
            Swal.fire({
                text: "Are you sure you want to delete " + rowValues[1] + "?",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    handleSoftDelete(rowValues)
                }
            })
        }
        else (
            Swal.fire({
                text: "Cannot delete Company Admin",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#0066cc'
            })
        )
    }

    // Function to show Details
    function UserDetails(rowValues) {
        setIsEditOpen(true);
        setClickRowData(rowValues);
        setGridPercent("45%");
        handleClose()
        if (userTableRowData !== undefined && userTableRowData !== null) {
            const completeSelectedRowData = userTableRowData.find(({ user_id }) => user_id === rowValues[0])
            if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                setUserName(completeSelectedRowData.user_name);
                setAddress(completeSelectedRowData.address);
                if (completeSelectedRowData.first_name === undefined) {
                    setFirstName("")
                }
                else {
                    setFirstName(completeSelectedRowData.first_name)
                }
                if (completeSelectedRowData.last_name === undefined) {
                    setLastName("")
                }
                else {
                    setLastName(completeSelectedRowData.last_name)
                }
                setCity(completeSelectedRowData.city);
                setCompName(completeSelectedRowData.company_name);
                setEmailId(completeSelectedRowData.email_address);
                setPhoneNo(completeSelectedRowData.mobile_number);
                setCompanyMcNumber(completeSelectedRowData.company_mc_number)
                setState(completeSelectedRowData.state);
                setUserRole(completeSelectedRowData.user_role);
                setCompanyRole(completeSelectedRowData.company_role);
                setUserId(completeSelectedRowData.user_id);
                setIsDetailOpen(true);
                if (completeSelectedRowData.user_role === "CompanyAdmin" && completeSelectedRowData.login_status === "profileRejected") {
                    setIsCompanyAdminDeletable(true);
                }
                else if (completeSelectedRowData.user_role === "CompanyAdmin") {
                    let mcNumer = completeSelectedRowData.company_mc_number;
                    let noOfActiveCompanyAdmins = 0;
                    let noOfCompanyAdmins = 0;
                    let noOfSuspendedCompanyAdmins = 0;
                    for (let i = 0; i < userTableRowData.length; i++) {
                        if ((userTableRowData[i].company_mc_number === mcNumer && userTableRowData[i].user_role === "CompanyAdmin" && userTableRowData[i].login_status === "ProfileAccepted")) {
                            noOfActiveCompanyAdmins += 1;
                            if (noOfActiveCompanyAdmins <= 1) {
                                setIsCompanyAdminSuspendable(false);
                            }
                            else {
                                setIsCompanyAdminSuspendable(true);
                                setIsCompanyAdminDeletable(true);
                                setNoOfAcceptedCompanyAdmins(true);
                            }
                        }
                    }
                    for (let i = 0; i < userTableRowData.length; i++) {
                        if (userTableRowData[i].company_mc_number === mcNumer && userTableRowData[i].user_role === "CompanyAdmin") {
                            noOfCompanyAdmins += 1;
                            if (noOfCompanyAdmins <= 1) {
                                setIsCompanyAdminDeletable(false);
                            }
                            else {
                                setIsCompanyAdminDeletable(true);
                            }
                        }
                    }
                    for (let i = 0; i < userTableRowData.length; i++) {
                        if (userTableRowData[i].company_mc_number === mcNumer && userTableRowData[i].user_role === "CompanyAdmin" && userTableRowData[i].login_status === "ProfileSuspended") {
                            noOfSuspendedCompanyAdmins += 1;
                            if (noOfActiveCompanyAdmins - noOfSuspendedCompanyAdmins === 1 || noOfActiveCompanyAdmins - noOfSuspendedCompanyAdmins === 0) {
                                setNoOfAcceptedCompanyAdmins(false)
                            }
                        }
                    }
                }
                else {
                    setIsCompanyAdminDeletable(true);
                    setIsCompanyAdminSuspendable(true);
                }
            }
        }
    }

    // Function to show pop-up while delete
    function handleCancelButtonConfirmClick(rowValues) {
        handleClose()
        if (isFieldEdited) {
            Swal.fire({
                text: "Are you sure you want to save changes before cancelling ",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    EditUserDetails(rowValues)
                    UserDetails(rowValues);
                }
            })
        }
        else {
            UserDetails(rowValues);
        }
    }

    return (
        <div className={classes.root}>
            <Snackbar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={colorseverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <CssBaseline />
            <MUIDrawer />
            <main className={classes.content}>
                <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: mainGridPercent }}>
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={"New User Request"}
                                    data={rowData}
                                    columns={columnDefs}
                                    options={options}
                                ></MUIDataTable>
                            </MuiThemeProvider>
                        </div>
                        {isPaneOpen && (
                            <React.Fragment>
                                <div style={{ width: "1%" }}></div>
                                <div style={{ width: "54%" }} border={1}>
                                    <Box
                                        boxShadow={3}
                                        style={{ width: "100%", overflowY: "scroll", maxHeight: "100%", height: "100%" }}
                                        color="black"
                                        border={0}
                                        padding={2}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                            </Grid>
                                            <Grid item xs={3} style={{ fontSize: 18 }} justify-content="center">
                                                <b>User Details</b>
                                            </Grid>
                                            <Grid container xs={5} justify="flex-end">
                                                <IconButton onClick={closeSecondaryPane}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Row >
                                            <Col sm>
                                            </Col>
                                        </Row>
                                        <div style={{ height: 5, paddingTop: "5%" }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <TextField disabled label="Company Name" inputProps={disableTextColor} style={{ width: "85%" }} value={pascalCase(newUsercompanyName)}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="MC Number" inputProps={disableTextColor} style={{ width: "85%" }} value={companyMcNumber}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Company Role" inputProps={disableTextColor} style={{ width: "85%" }} value={companyRole}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={newUserRole !== "CompanyAdmin"} label="Address *" inputProps={disableTextColor} style={{ width: "85%" }} value={pascalCase(newUserAddress)} onChange={handleNewUserAddress}>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={newUserRole !== "CompanyAdmin"} label="City *" style={{ width: "85%" }} inputProps={disableTextColor} value={pascalCase(newUserCity)} onChange={handleNewUserCity}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={newUserRole !== "CompanyAdmin"} label="State *" style={{ width: "85%", paddingTop: "4%" }} inputProps={disableTextColor} value={pascalCase(newUserState)} onChange={handleNewUserState} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <TextField disabled label="Email Address" inputProps={disableTextColor} style={{ width: "85%" }} value={newUserEmailId} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField label="First Name *" style={{ width: "85%" }} error={errors.newUserFirstName} value={pascalCase(newUserFirstName)} onChange={handleNewUserFirstNametxt} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField label="Last Name *" style={{ width: "85%" }} error={errors.newUserLastName} value={pascalCase(newUserLastName)} onChange={handleNewUserLastNametxt} editable='true'>{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <div style={{ paddingTop: "3%" }} />
                                                        <PhoneInput
                                                            value={newUserPhoneNo}
                                                            onChange={(value) => { handleNewUserPhoneNoChange(value) }}
                                                            localization={'us'}
                                                            onlyCountries={['us']}
                                                            autoFormat={true}
                                                            country={'us'}
                                                            defaultCountry={"us"}
                                                            disableDropdown={true}
                                                            disableCountryCode={true}
                                                            placeholder='(xxx) xxx-xxxx'
                                                            error={errors.mobileNumber}
                                                            containerStyle={{
                                                                width: "90%",
                                                                border: "1px solid black"
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="User Role" inputProps={disableTextColor} style={{ width: "85%", paddingTop: "2%" }} value={newUserRole} ></TextField>
                                                    </Grid>
                                                    <Grid container>
                                                        <FormControl className={classes.formControl} disabled={(newUserRole === "CompanyAdmin" && !isUniqueCompanyAdminForNewRequest)}>
                                                            <InputLabel id="demo-controlled-open-select-label" >User Role</InputLabel>
                                                            <Select
                                                                labelId="demo-controlled-open-select-label"
                                                                id="demo-controlled-open-select"
                                                                open={dropOpenNewUser}
                                                                onClose={handleDropdownCloseNewUser}
                                                                onOpen={handleDropdownOpenNewUser}
                                                                error={errors.newUserRole}
                                                                value={newUserRole}
                                                                style={{ width: "100%", paddingTop: "3%" }}
                                                                onChange={handleNewUserRoleChange}
                                                            >
                                                                <MenuItem value="CompanyAdmin">Company Admin</MenuItem>
                                                                <MenuItem hidden={companyRole === "Carrier"} value="Broker">Broker</MenuItem>
                                                                <MenuItem hidden={companyRole === "Broker"} value="Carrier">Carrier</MenuItem>
                                                                <MenuItem hidden={companyRole !== "Broker&Carrier"} value="Broker&Carrier">Broker & Carrier</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <div style={{ paddingTop: "3%" }}>
                                            <Grid container justify="space-around" spacing={3}>
                                                <Grid item xs={9} style={{ paddingLeft: "10%" }} >
                                                    {popupMCNumber.map((mcNumberObj) => (
                                                        <Row key={mcNumberObj.mcNumber + 'role'}>
                                                            <Col key={mcNumberObj.mcNumber + 'col1'} style={{ paddingTop: "5%" }} sm>MC Number:</Col>
                                                            <Col key={mcNumberObj.mcNumber + 'col2'} style={{ paddingTop: "5%", marginLeft: "1%" }} sm={3}>{mcNumberObj.mcNumber}
                                                            </Col>
                                                            <Col key={mcNumberObj.mcNumber + 'col3'} sm>
                                                                <FormControl className={classes.compRoleFormControl}>
                                                                    <Select
                                                                        // labelId="demo-controlled-open-company-role-label"
                                                                        id={mcNumberObj.mcNumber + 'select'}
                                                                        key={mcNumberObj.mcNumber}
                                                                        style={{ width: "100%" }}
                                                                        value={mcNumberObj.role}
                                                                        onChange={handleMCNumberRoleChange}
                                                                        inputProps={{
                                                                            name: mcNumberObj.mcNumber,
                                                                        }}>
                                                                        <MenuItem key={mcNumberObj.mcNumber + 'brokerage'} value={'Brokerage'}>Brokerage</MenuItem>
                                                                        <MenuItem key={mcNumberObj.mcNumber + 'Carriage'} value={'Carriage'}>Carriage</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText>Company Role</FormHelperText>
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Grid container direction="column" spacing={2}></Grid>
                                                </Grid>
                                            </Grid>
                                        </div> */}
                                        <div style={{ paddingTop: "5%" }}>
                                            <Grid container justify="space-around" spacing={4}>
                                                <Grid item xs={1}>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Button variant="contained" color="primary" disabled={disableApproveButton} style={{
                                                            position: "absolute",
                                                            margin: "2px, auto",
                                                            textTransform: "none",
                                                        }} onClick={() => checkFirstUserLoginStatus(clickNewUserRowData)} >
                                                            Approve
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1}>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Button variant="contained" color="primary" disabled={disableRejectButton} style={{
                                                            position: "absolute",
                                                            margin: "0px, auto",
                                                            textTransform: "none",
                                                        }} onClick={() => handleRejectConfirmClick(clickNewUserRowData)} >
                                                            Reject
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <Grid style={{ fontSize: 14 }} xs={12}>
                                            <Grid container direction="row">
                                                <div style={{ paddingTop: "5%" }}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </React.Fragment>
                        )}
                        <br></br>
                        <div style={{ width: gridPercent }} id="Users">
                            <div style={{ paddingTop: "8%", paddingBottom: "25%" }} >
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={"User Details"}
                                        data={userTableRowData}
                                        columns={userTableColumnDefs}
                                        options={options1}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </div>
                        {isDetailOpen && (
                            <React.Fragment>
                                <div style={{ width: "1%" }}></div>
                                <div style={{ width: "54%" }} border={1}>
                                    <div style={{ paddingTop: "7%" }} />
                                    <Box
                                        boxShadow={3}
                                        style={{ width: "100%", overflowY: "scroll", maxHeight: "90%", height: "100%" }}
                                        color="black"
                                        border={0}
                                        padding={2}
                                    >
                                        <Grid container spacing={3}>
                                            <Grid item xs={4}>
                                            </Grid>
                                            <Grid item xs={3} style={{ fontSize: 18 }} justify-content="center">
                                                <b hidden={!isEditOpen}>View Details</b>
                                                <b hidden={isEditOpen}>Edit</b>
                                            </Grid>
                                            <Grid container xs={5} justify="flex-end">
                                                <React.Fragment>
                                                    <IconButton onClick={() => EditSelected(clickRowData)} hidden={clickRowData[7] === "Suspended" || clickRowData[7] === "Not Approved"}>
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleSoftDeleteConfirmClick(clickRowData)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton onClick={closeSecondaryDetailPane}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </React.Fragment>

                                            </Grid>
                                        </Grid>
                                        <div style={{ height: 5, paddingTop: "8%" }} />
                                        <div style={{ height: 5, paddingTop: "8%" }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <TextField disabled label="Company Name" inputProps={disableTextColor} style={{ width: "85%" }} onChange={handleCompanyName} value={pascalCase(compName)} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="MC Number" inputProps={disableTextColor} style={{ width: "85%" }} value={companyMcNumber}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="Company Role" inputProps={disableTextColor} style={{ width: "85%" }} value={companyRole}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen || userRole !== "CompanyAdmin"} label="Address *" inputProps={disableTextColor} style={{ width: "85%" }} error={errors.address} value={pascalCase(address)} onChange={handleAddress}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen || userRole !== "CompanyAdmin"} label="City *" inputProps={disableTextColor} style={{ width: "85%" }} error={errors.city} value={pascalCase(city)} onChange={handleCity}></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen || userRole !== "CompanyAdmin"} label="State *" inputProps={disableTextColor} style={{ width: "85%" }} error={errors.state} value={pascalCase(state)} onChange={handleState}></TextField>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Grid container direction="column" spacing={2}>
                                                    <Grid item>
                                                        <TextField disabled label="Email Address" inputProps={disableTextColor} style={{ width: "85%" }} value={emailId} >{userName}</TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen} label="First Name *" inputProps={disableTextColor} style={{ width: "85%" }} error={errors.firstName} value={pascalCase(firstName)} onChange={handleFirstNametxt} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled={isEditOpen} label="Last Name *" inputProps={disableTextColor} style={{ width: "85%" }} error={errors.lastName} value={pascalCase(lastName)} onChange={handleLastNametxt} ></TextField>
                                                    </Grid>
                                                    <Grid item>
                                                        <div style={{ paddingTop: "3%" }} />
                                                        <PhoneInput
                                                            disabled={isEditOpen}
                                                            value={phoneNo}
                                                            onChange={(value) => { handleUserPhoneNoChange(value) }}
                                                            localization={'us'}
                                                            onlyCountries={['us']}
                                                            country={'us'}
                                                            defaultCountry={"us"}
                                                            disableDropdown={true}
                                                            disableCountryCode={true}
                                                            placeholder='(xxx) xxx-xxxx'
                                                            error={errors.mobileNumber}
                                                            containerStyle={{
                                                                border: "1px solid black"
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField disabled label="User Role" inputProps={disableTextColor} style={{ width: "85%" }} value={userRole} ></TextField>
                                                    </Grid>
                                                    <Grid container>
                                                        <FormControl className={classes.formControl} disabled={isEditOpen || !isComapnyAdminSuspendable} >
                                                            <InputLabel style={{ width: "100%" }} id="demo-controlled-open-select-label" >User Role</InputLabel>
                                                            <Select
                                                                labelId="demo-controlled-open-select-label"
                                                                id="demo-controlled-open-select"
                                                                open={dropopen}
                                                                onClose={handleDropdownClose}
                                                                onOpen={handleDropdownOpen}
                                                                value={userRole}
                                                                style={{ width: "90%" }}
                                                                onChange={handleRoleChange}
                                                            >
                                                                <MenuItem value="CompanyAdmin">Company Admin</MenuItem>
                                                                <MenuItem hidden={companyRole === "Carrier"} value="Broker">Broker</MenuItem>
                                                                <MenuItem hidden={companyRole === "Broker"} value="Carrier">Carrier</MenuItem>
                                                                <MenuItem hidden={companyRole !== "Broker&Carrier"} value="Broker&Carrier">Broker & Carrier</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid style={{ fontSize: 14 }} xs={12}>
                                                    <Grid container direction="row">
                                                        <div style={{ paddingTop: "5%" }}>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={1}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs style={{ paddingLeft: "9%" }}>
                                                <Button variant="contained" color="primary" style={{
                                                    position: "absolute",
                                                    marginLeft: "10px",
                                                    textTransform: "none",
                                                }} onClick={() => EditUserDetails(clickRowData)} hidden={isEditOpen} >
                                                    Save Changes
                                                </Button>
                                            </Grid>
                                            <Grid item xs>
                                                <Button variant="contained" color="primary" style={{
                                                    position: "absolute",
                                                    margin: "0px, auto",
                                                    textTransform: "none",
                                                }} onClick={() => handleSuspendConfirmClick(clickRowData)} hidden={clickRowData[7] === "Suspended" || clickRowData[7] === "Not Approved" || !isEditOpen} >
                                                    Suspend
                                                </Button>
                                                <Button variant="contained" color="primary" style={{
                                                    position: "absolute",
                                                    margin: "0px, auto",
                                                    textTransform: "none",
                                                }} onClick={() => handleActivateConfirmClick(clickRowData)} hidden={(clickRowData[7] !== "Suspended" && clickRowData[7] !== "Not Approved") || !isEditOpen} >
                                                    Activate
                                                </Button>
                                            </Grid>
                                            <Grid item xs>
                                                <Button variant="contained" color="primary" style={{
                                                    position: "absolute",
                                                    margin: "0px, auto",
                                                    textTransform: "none",
                                                }} onClick={() => handleCancelButtonConfirmClick(clickRowData)} hidden={isEditOpen}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ fontSize: 14 }} xs={12}>
                                            <Grid container direction="row">
                                                <div style={{ paddingTop: "5%" }}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={1}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </React.Fragment>
                        )}
                    </Grid>
                </Typography>
                {/* <Typography component={'div'}>
                    <Grid container direction="row" margin={2}>
                        <div style={{ width: companyDetailsGridPercent }} id="Companies">
                            <div style={{ paddingTop: "8%", paddingBottom: "25%" }} >
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={"Company Details"}
                                        data={companyTableRowData}
                                        columns={companyTableColumnDefs}
                                        options={optionsCompanyTable}
                                    />
                                </MuiThemeProvider>
                            </div>
                        </div>
                    </Grid>
                </Typography> */}
            </main >
        </div >
    );
};

export default SystemAdmin;