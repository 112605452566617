import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import NotificationsIcon from '@material-ui/icons/Notifications';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { IconButton, Grid, Paper, Checkbox, FormGroup, FormControlLabel, Tooltip } from "@material-ui/core";
import SvgIcon from '@material-ui/core/SvgIcon';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from "@mui/material/Divider";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import moment from "moment";
import { environmentVariables } from "../../environment"
import CloseIcon from "@material-ui/icons/Close";
import Typography from '@mui/material/Typography';
import MUIDrawer from "../Drawer"
import "./index.css"

export default function Notifications(props) {
    const [state, setState] = React.useState(false);
    const { user } = useAuth0();
    const history = useHistory();
    const [notificationData, setNotificationData] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0)
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
    const [isIndividualCheckboxChecked, setIsIndividualCheckboxChecked] = useState(false)
    const [isDrawerValueChanged, setIsDrawerValueChanged] = useState(false);
    const [notificationIds, setNotificationIds] = useState([])

    // Constant to handle logged in company role
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState(window.localStorage.getItem('company_role'));

    useEffect(() => {
        handleGetNotifications()
    }, [props.notificationSent === true]);


    // Function to handle getting notifications 
    async function handleGetNotifications() {
        axios({
            url:
                environmentVariables.getnotifications + '?user_id=' + user.email,
            method: "get",
        })
            .then((response) => {
                let newResponse = JSON.parse(response.data.body)
                if (newResponse !== undefined) {
                    let notificationDetails = [];
                    let noOfUnreadMessages = 0
                    newResponse.forEach(element => {
                        if (element.read_status === false) {
                            noOfUnreadMessages = noOfUnreadMessages + 1
                            notificationDetails.push({ ...element })
                        }
                        else {
                            notificationDetails.push({ ...element })
                        }

                    });
                    const pattern = /^\(\d+\)/;
                    if (noOfUnreadMessages === 0 || pattern.test(document.title)) {
                        document.title = document.title.replace(pattern, noOfUnreadMessages === 0 ? "" : "(" + noOfUnreadMessages + ")");
                      }
                    else {
                        document.title = "(" + noOfUnreadMessages + ") " + document.title
                    }
                    setUnreadCount(noOfUnreadMessages);
                    notificationDetails.sort((a, b) =>
                        b.time_stamp_notification.localeCompare(a.time_stamp_notification)
                    );
                    setNotificationData(notificationDetails);
                    let data = JSON.stringify(notificationDetails)
                    const arr = []
                    notificationDetails.forEach(element => {
                        arr.push(element)
                    })
                    return data
                }
            })
            .catch((error) => {
                console.log("get all notifications error", error)
            });
    }

    // Function to get the difference of notification posted from today
    const handleDaysDifference = (generatedTimeStamp, todaysTimeStamp) => {
        var difference = new Date(todaysTimeStamp).getTime() - new Date(generatedTimeStamp).getTime();
        var daysDifference = difference / (1000 * 3600 * 24);
        if (daysDifference < 1) {
            return "Today"
        }
        else if (daysDifference === 1) {
            return daysDifference + " day ago"
        }
        else {
            return daysDifference + " days ago";
        }
    }

    // Function to handle update notification chnages
    async function handleUpdateNotification(element) {
        if (props.closeSecondaryPane !== undefined) {
            props.closeSecondaryPane()
        }
        if (element.message === "YourLoadHasBennAccepted") {
            if (loggedInuserCompanyRole === "Broker" || (loggedInuserCompanyRole === "Broker&Carrier" && element.load_posted_by_user === user.email)) {
                history.push({ pathname: '/awardedloads' })
                window.localStorage.setItem('LoadId', element.load_id);
            }
            else {
                history.push({ pathname: '/bidswon' })
                window.localStorage.setItem('LoadId', element.load_id);
            }
        }
        else if (element.message === "ThereIsANewBid" || element.message === "YourBidHasExpired" || element.message === "ABidHasBeenRemoved") {
            if (element.load_expired_status === true && element.load_posted_by_user !== user.email) {
                history.push({ pathname: '/expiredbids' })
                window.localStorage.setItem('LoadId', element.load_id);
            }
            else if (element.load_expired_status === true && element.load_posted_by_user === user.email) {
                history.push({ pathname: '/expiredloads' })
                window.localStorage.setItem('LoadId', element.load_id);
            }
            else {
                history.push({ pathname: '/activeloads' })
                window.localStorage.setItem('LoadId', element.load_id);
            }
        }
        else if (element.message === "ThereIsANewMessageFromBroker" || element.message === "ThereIsANewMessageFromCarrier") {
            if (element.won_message === true) {
                if (element.load_posted_by_user === user.email) {
                    history.push({ pathname: '/awardedloads' })
                    window.localStorage.setItem('LoadId', element.load_id);
                }
                else {
                    history.push({ pathname: '/bidswon' })
                    window.localStorage.setItem('LoadId', element.load_id);
                }
            }
            else {
                history.push({ pathname: '/activeloads' })
                window.localStorage.setItem('LoadId', element.load_id);
            }
        }
        else if (element.message === "ThereIsACounterOffer" || element.message === "CounterOfferIsUpdated") {
            history.push({ pathname: '/activeloads' })
            window.localStorage.setItem('LoadId', element.load_id);
        }
        else {
            history.push({ pathname: '/loadboard' })
            window.localStorage.setItem('LoadId', element.load_id);
        }
        let listValues = element
        listValues = { ...listValues, "user_id": user.email };
        if (listValues.read_users_list.length !== undefined) {
            const findUsers = listValues.read_users_list.find(
                ({ name }) => name === user.email
            );
            if (findUsers === undefined) {
                listValues.read_users_list.push({ name: user.email })
            }
        }
        else {
            listValues.read_users_list = [{ name: user.email }]
        }
        let newList = []
        listValues = { ...listValues }
        newList.push(listValues)
        try {
            let Response = await axios({
                url: environmentVariables.editnotification + "?action=read",
                method: "patch",
                data: newList
            });
            if (Response) {
                handleGetNotifications();
                setIsDrawerValueChanged(true)
            }
        } catch (e) {
            console.log(e);
        }
    }

    //Function to handle Delete individual notification
    async function handleDeleteNotification(element) {
        let listValues = element
        listValues = { ...listValues, "user_id": user.email };
        listValues = { ...listValues, "functionality": "delete" };
        if (listValues.deleted_by_users.length !== undefined) {
            if (listValues.deleted_by_users) {
                const findUsers = listValues.deleted_by_users.find(
                    ({ name }) => name === user.email
                );
                if (findUsers === undefined) {
                    listValues.deleted_by_users.push({ name: user.email })
                }
            }
        }
        else {
            listValues.deleted_by_users = [{ name: user.email }]
        }
        let newList = []
        listValues = { ...listValues }
        newList.push(listValues)
        try {
            let Response = await axios({
                url: environmentVariables.editnotification  + "?action=delete",
                method: "patch",
                data: newList
            });
            if (Response) {
                handleGetNotifications();
                setIsDrawerValueChanged(true)
            }
        } catch (e) {
            console.log(e);
        }
    }

    //Function to handle Delete all notifications
    async function handleBulkDeleteNotification() {
        let listValues = []
        if (document.getElementById("checkbox").checked) {
            listValues = notificationData
        }
        else {
            listValues = notificationIds
        }
        let newList = []
        listValues.forEach(element => {
            let userCount = 0
            if (element.deleted_by_users) {
                for (let i = 0; i < element.deleted_by_users.length; i++) {
                    if (i === user.email) {
                        userCount = userCount + 1
                    }
                }
                if (userCount === 0) {
                    element.deleted_by_users.push({ name: user.email })
                }
            }
            newList.push(element)
        })
        let data = JSON.stringify(newList)
        try {
            let Response = await axios({
                url: environmentVariables.editnotification + "?action=delete",
                method: "patch",
                data: newList
            });
            if (Response) {
                <MUIDrawer />
                handleGetNotifications();
                setIsDrawerValueChanged(true)
            }
        } catch (e) {
            console.log(e);
        }
    }

    //Function to handle Delete all notifications
    async function handleBulkReadNotification() {
        let listValues = notificationIds
        let newList = []
        listValues.forEach(element => {
            let userCount = 0
            if (element.read_users_list) {
                for (let i = 0; i < element.read_users_list.length; i++) {
                    if (i === user.email) {
                        userCount = userCount + 1
                    }
                }
                if (userCount === 0) {
                    if (listValues.read_users_list !== undefined) {
                        element.read_users_list.push({ name: user.email })
                    }
                    else {
                        listValues.read_users_list = [{ name: user.email }]
                    }
                }
            }
            newList.push(element)
        })
        let data = JSON.stringify(newList)
        try {
            let Response = await axios({
                url: environmentVariables.editnotification + "?action=read",
                method: "patch",
                data: newList
            });
            if (Response) {
                <MUIDrawer />
                handleGetNotifications();
                setIsDrawerValueChanged(true)
            }
        } catch (e) {
            console.log(e);
        }
    }

    // function to handle open and close drawer panel
    const toggleDrawer = (open) => {
        setState(open);
        handleGetNotifications();
        setIsCheckboxChecked(false)
    };

    const handleChange = () => {
        if (document.getElementById("checkbox").checked === true) {
            setIsCheckboxChecked(true)
        }
        else {
            setIsCheckboxChecked(false)
        }
    };

    const handleIndividualCheckboxChange = (notification) => {
        if (document.getElementById(notification.notification_id) && document.getElementById(notification.notification_id).checked === true) {
            // notificationsIds.push(notificationId)
            let idCount = 0
            for (var i = 0; i < notificationIds.length; i++) {
                if (notificationIds[i].notification_id === notification.notification_id) {
                    idCount = idCount + 1
                }
            }
            if (idCount === 0) {
                setIsIndividualCheckboxChecked(true)
                notificationIds.push(notification)
            }
        }
        else if (document.getElementById(notification.notification_id) && document.getElementById(notification.notification_id).checked === false) {
            for (var i = 0; i < notificationIds.length; i++) {
                if (notificationIds[i].notification_id === notification.notification_id) {
                    notificationIds.splice(notification);
                }
            }
        }
    }

    // Function to handle UI for drawer panel
    const list = (anchor) => (
        <Box
            sx={{ width: 400 }}
            style={{ backgroundColor: '#f5f5f0', height: '160%', paddingTop: 10, overflowX: 'hidden' }}
        >
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <FormGroup style={{ marginLeft: 18 }}>
                        <FormControlLabel style={{ marginTop: 4 }} control={<Checkbox id="checkbox" onChange={handleChange} color="default"
                            inputProps={{ 'aria-label': 'controlled' }} />} label="Select All" />
                    </FormGroup>
                </Grid>
                {/* <Grid item xs={1}></Grid> */}
                <Grid item xs={2} >
                    <IconButton hidden={!isCheckboxChecked && !isIndividualCheckboxChecked} style={{ position: 'relative', marginTop: 4, marginLeft: 9 }} onClick={() => {
                        handleBulkReadNotification()
                    }}>
                        <Tooltip title="Mark as Read">
                            {/* <Icon >
                            <img src="" />
                        </Icon> */}
                            <SvgIcon {...props}>
                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#696969"><rect fill="none" height="24" width="24" /><path d="M22,7h-9v2h9V7z M22,15h-9v2h9V15z M5.54,11L2,7.46l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L5.54,11z M5.54,19L2,15.46 l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L5.54,19z" /></svg>
                            </SvgIcon>
                            {/* <DeleteOutlineOutlinedIcon hidden={!isCheckboxChecked} style={{ color: '#696969', marginTop: 4}} /> */}
                        </Tooltip>
                    </IconButton>
                </Grid>
                <Grid item xs={2} >
                    <IconButton style={{ position: 'relative', marginTop: 4, marginLeft: 16 }} onClick={() => {
                        handleBulkDeleteNotification()
                    }}>
                        <Tooltip title="Delete">
                            <DeleteOutlineOutlinedIcon hidden={!isCheckboxChecked && !isIndividualCheckboxChecked} style={{ color: '#696969' }} />
                        </Tooltip>
                    </IconButton>
                </Grid>
                <Grid container item xs={2} justify="flex-end">
                    <IconButton style={{ marginTop: 4 }} onClick={() => toggleDrawer(false)}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            < Paper sx={{ width: 400 }}
                style={{ backgroundColor: 'ccecfa', height: '160%', paddingTop: 10, overflowX: 'hidden' }}>
                {
                    (notificationData.length !== 0) && (
                        <div>
                            {
                                notificationData.map((value) => {
                                    const labelId = `checkbox-list-label-${value}`;
                                    let days
                                    return (
                                        <List >
                                            <ListItem className={isCheckboxChecked ? "FilledBackColor" : "EmptyBackColor"}
                                                key={value}
                                            >
                                                <div className="delete" >
                                                    <FormGroup >
                                                        <FormControlLabel control={<Checkbox id={value.notification_id} onChange={handleIndividualCheckboxChange(value)} color="default"
                                                            inputProps={{ 'aria-label': 'controlled' }} />} />
                                                    </FormGroup>
                                                    {/* <input type="checkbox" onChange = {handleIndividualCheckboxChange(value)} style={{ width: 17, height: 17, margin: 9, marginLeft: 0, marginRight: 10 }} value="2" id="individualId" color="#808080" /> */}
                                                </div>
                                                <ListItemButton style={{ width: '100%' }} onClick={() => { handleUpdateNotification(value) }} >
                                                    <ListItemText style={{ color: '#142440', textTransform: "none" }} id={labelId}
                                                        primary={value.custom_message}
                                                        primaryTypographyProps={{
                                                            fontSize: 15,
                                                            color: '#142440',
                                                            fontWeight: value.read_status === true ? 'normal' : 'bold',
                                                            letterSpacing: 0,
                                                        }}
                                                        secondary={days = handleDaysDifference(value.generated_date, moment(new Date()).format("MM/DD/YYYY"))}
                                                        secondaryTypographyProps={{
                                                            fontSize: 16,
                                                            textAlign: 'right',
                                                            color: '#142440',
                                                            letterSpacing: 0,
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </List>
                                    );
                                })
                            }
                        </div>
                    )
                }
                {
                    (notificationData.length === 0) && (
                        <div>
                            <Typography component={'div'} variant="h5" display="block" style={{ marginTop: 360, marginLeft: 70 }} gutterBottom>
                                No Notifications
                            </Typography>
                        </div>
                    )
                }
            </Paper>
        </Box >
    );

    return (
        <div>
            <MUIDrawer isValueChanged={isDrawerValueChanged} />

            <React.Fragment>
                {/* <IconButton onClick={() => toggleDrawer(true)} style={{ display: 'flex-end' }}>
                    <Badge badgeContent={unreadCount} color="error">
                        <NotificationsIcon style={{ color: 'white' }} />
                    </Badge>
                </IconButton> */}
                <Drawer
                    anchor="right"
                    open={state}
                >
                    {list("right")}
                </Drawer>
            </React.Fragment>
        </div>
    );
}