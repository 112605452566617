import React, { useState, useEffect } from "react";
import {
    Grid,
    CssBaseline,
    Card,
    CardContent,
    Box,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import { useHistory } from "react-router";
import addNotification from 'react-push-notification';
import useWebSocket, { } from 'react-use-websocket';

//import CSS, Drawer, Envirnoment
import { environmentVariables } from "../../environment";
import './index.css';
import MUIDrawer from "../Drawer";

//import Icons
import { AwardedLoadAdminIcon } from "../../SvgIcons/AwardedLoadAdmin";
import { CompanyManagementAdminIcon } from "../../SvgIcons/CompanyManagementAdmin"
import { ExpiredBidsAdminIcon } from "../../SvgIcons/ExpiredBidsAdminIcon";
import { ExpiredLoadsAdminIcon } from "../../SvgIcons/ExpiredLoadsAdmin"
import { LoadsWonAdminIcon } from "../../SvgIcons/LoadsWonAdmin"
import { ProspectiveClientAdminIcon } from "../../SvgIcons/prospectiveClientAdmin"
import { LoadboardAdminIcon } from "../../SvgIcons/LoadBoardAdmin"
import { UserManagementAdminIcon } from "../../SvgIcons/UserManagementAdmin"
import { SettingsAdminIcon } from "../../SvgIcons/SeetingsAdmin"
import { MCVerifyIcon } from "../../SvgIcons/MCVerifyIcon";
import { MCVerifyAdminIcon } from "../../SvgIcons/MCVerifyAdminIcon";

/*************************************************************************************** */
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
}));

const Admin = () => {
    const classes = useStyles();
    const { user } = useAuth0();
    const history = useHistory();

    // Constant for User Count
    const [userCount, setUserCount] = useState("");

    //Constant for prospective Client count
    const [prospectiveClientCount, setProspectiveClientCount] = useState("");

    //Constant for prospective Client count
    const [allLoadsCount, setAllLoadsCount] = useState("");

    //Constant for prospective Client count
    const [awardedLoadsCount, setAwardedLoadsCount] = useState("");

    //Constant for prospective Client count
    const [expiredLoadsCount, setExpiredLoadsCount] = useState("");

    //Constant to handle Expired Bids Count
    const [expiredBidsCount, setExpiredBidsCount] = useState("");

    //Constant for prospective Client count
    const [loadsWonCount, setLoadsWonCount] = useState("");

    //Constant for prospective Client count
    const [companiesCount, setCompaniesCount] = useState("");

    //Constant for MC certificate count
    const [certificateCount, setCertificateCount] = useState("")


    /***************************************To handle function******************************************************* */
    useEffect(() => {
        checkUserRole()
        fetchAllActiveLoad()
        fetchExpiredLoad()
        BidsWonLoadsAwarded()
        fetchExpiredBids()
        getAllUserInformation();
        getPospectiveClients();
        getAllCompanies();
        getAllCertificates()
    }, []);

    //function to check user Role
    function checkUserRole() {
        if (user.email !== process.env.REACT_APP_ADMIN_USER) {
            history.push({ pathname: "/forbidden" });
        }
    }
    // Constant to handle socket connections
    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    // Function to recive all the socket io messages
    async function recieveMessageForSocketIo(message) {
        let recievedMessage = message.data.replace(/\"/g, "");

        // Recieveing a message from carrier for new load
        if (recievedMessage === "newLoadHasBeenPlaced") {
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: 'There is a new Load',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
        }

        if (recievedMessage === "newUserUnderVerification") {
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: 'There is a new Load',
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            getAllUserInformation();
        }

        if (recievedMessage.includes("BrokerLoadHasBeenExpired")) {
            let messageText = "";
            if (recievedMessage.includes("BrokerLoadHasBeenExpired")) {
                messageText = 'Load has been Expired';
            }
            addNotification({
                title: 'Notification',
                subtitle: 'There is a new notification',
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
        }

        if (recievedMessage.includes("BidHasBeenAccepted") || recievedMessage.includes("LoadHasBeenAwarded")) {
            addNotification({
                title: 'Notification',
                subtitle: "There is a Message",
                message: "There is a Message",
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
        }

        if (recievedMessage.includes("ABidHasExpired")) {
            if (recievedMessage.split("||")[2].split(":")[1] === user.email) {
                addNotification({
                    title: 'Notification',
                    subtitle: 'Your Bid has been Expired',
                    message: 'Your Bid has been Expired',
                    theme: 'darkblue',
                    duration: 10000,
                    native: true // when using native, your OS will handle theming.
                });
                fetchMainMuiDatatbleValues();
            }
        }
        // Recive a message on new bid
        if (recievedMessage.includes("ThereIsANewBid") || recievedMessage.includes("ABidHasBeenRemoved")) {
            let messageText = "";
            if (recievedMessage.includes("ThereIsANewBid")) {
                messageText = 'There is a new Bid';
            }
            if (recievedMessage.includes("ABidHasBeenRemoved")) {
                messageText = 'A Bid has been Removed';
            }
            addNotification({
                title: 'Notification',
                subtitle: messageText,
                message: messageText,
                theme: 'darkblue',
                duration: 10000,
                native: true // when using native, your OS will handle theming.
            });
            fetchMainMuiDatatbleValues();
        }

        // Recieve message for update bid
        if (recievedMessage.includes("BrokerLoadHasBeenWatchList") || recievedMessage.includes("BrokerLoadHasBeenWatchListRemoved")) {
            fetchMainMuiDatatbleValues();
        }
    }

    // Function to get all prospective clients
    async function getPospectiveClients() {
        try {
            let response = await axios({
                url: environmentVariables.getprospectiveclients,
                method: "get",
            })
            if (response) {
                if (response.data !== "UserNotPresent") {
                    setProspectiveClientCount(response.data.length)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    //Function to Fetch All active load
    async function fetchAllActiveLoad() {
        await axios({
            url:
                environmentVariables.getcarrierloadboard +
                "?carrier_name=" +
                user.email,
            method: "get",
        })
            .then((response) => {
                let data = JSON.parse(response.data.body)
                let arrayToRemove = data.filter(function (singleVal) {
                    return singleVal['load_delete_status'] !== true && singleVal['load_expired_status'] !== true && singleVal['status'] !== "Closed" && singleVal['status'] !== "Awarded"
                });
                setAllLoadsCount(arrayToRemove.length)
            })
            .catch((error) => {
                console.log(error, "Fetch Admin Data")
            });
    }

    //Function to fetch Expired Load
    async function fetchExpiredLoad() {
        try {
            let response = await axios({
                url: environmentVariables.getexpiredloads +
                    "?carrier_name=" +
                    user.email,
                method: "get",
            })
            if (response) {
                let newResponse = JSON.parse(response.data.body)
                setExpiredLoadsCount(newResponse.length);
            }
        } catch (error) {
            console.log("fetch load error", error)
        }
    }

    //Function to fetch Bids won and Loads Awarded Count
    async function BidsWonLoadsAwarded() {
        try {
            let response = await axios({
                url: environmentVariables.getawardedloads +
                    "?carrier_name=" +
                    user.email,
                method: "get",
            })
            if (response) {
                let newResponse = JSON.parse(response.data.body)
                setAwardedLoadsCount(newResponse.length);
                setLoadsWonCount(newResponse.length);
            }
        } catch (error) {
            console.log("fetch load error", error)
        }
    }

    //Function to fetch Expired Bids
    async function fetchExpiredBids() {
        try {
            let response = await axios({
                url: environmentVariables.getbidhistory +
                    "?carrier_name=" +
                    user.email,
                method: "get",
            })
            if (response) {
                let newResponse = JSON.parse(response.data.body)
                setExpiredBidsCount(newResponse.length);
            }
        } catch (error) {
            console.log("fetch load error", error)
        }
    }

    // Function to fetch all the data main MuiDatatble
    async function fetchMainMuiDatatbleValues() {
        fetchAllActiveLoad()
        fetchExpiredLoad()
        BidsWonLoadsAwarded()
        fetchExpiredBids()
    }

    // function to get all user information
    async function getAllUserInformation() {
        try {
            let response = await axios({
                url: environmentVariables.getuserdetails,
                method: "get",
            })
            if (response) {
                let newResponse = JSON.parse(response.data.body)
                setUserCount(newResponse.length)
            }
        }
        catch (error) {
            console.log(error, "All user Info")
        }
    }

    async function getAllCompanies() {
        try {
            await axios({
                url: environmentVariables.getcompanyinformation,
                method: 'get'
            }).then((response) => {
                let newResponse = JSON.parse(response.data.body)
                setCompaniesCount(newResponse.length)
            })
        }
        catch (error) {
            console.log(error, "All company Info")
        }
    }

    async function getAllCertificates() {
        try {
            await axios({
                url: environmentVariables.getmccertificate,
                method: 'get'
            }).then((response) => {
                let newResponse = JSON.parse(response.data.body)
                setCertificateCount(newResponse.length)
            })
        }
        catch (error) {
        }
    }

    /*****************************************************************************************************/
    return (
        <div className={classes.root}>
            <CssBaseline />
            <MUIDrawer />
            <Grid container direction="row-reverse" alignItems="center" justify="center" style={{ minHeight: '80vh' }}>
                <Grid container alignItems="center" justify="center" direction="column">
                    <Box style={{ backgroundColor: "white" }}>
                        <main className={classes.content}>
                            <div style={{ height: 10 }}></div>
                            <Box className="box" boxShadow={3} color="black" border={0} padding={2} margin={1}>
                                <Col style={{ paddingLeft: 20 }}></Col>
                                <Row>
                                    <Card className="Card"  >
                                        <CardContent className="CardContent">
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "#8ea3b9" }}>
                                                    <ProspectiveClientAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" style={{ paddingLeft: 0 }}>
                                                    <a className="Link" style={{ paddingRight: 20 }} href="/prospectiveclients" rel="prospectiveclients" >
                                                        Prospective Clients
                                                    </a>
                                                    <div className="Number"> {prospectiveClientCount}</div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="Card"  >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "Orange" }}>
                                                    <LoadboardAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="Link" href="/loadboard" rel="LoadBoard" >
                                                        LoadBoard
                                                    </a>
                                                    <div className="Number"> {allLoadsCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="Card"  >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "red" }}>
                                                    <AwardedLoadAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="Link" href="/awardedloads" rel="AwardedLoads" >
                                                        Awarded Loads
                                                    </a>
                                                    <div className="Number"> {awardedLoadsCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="Card" >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "Violet" }}>
                                                    <LoadsWonAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="Link" href="/bidswon" rel="LoadsWon" >
                                                        Bids Won
                                                    </a>
                                                    <div className="Number" > {loadsWonCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Row>
                                <div style={{ height: 40 }} />
                                <Row>
                                    <Card className="Card" >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "#17b8a6" }}>
                                                    <ExpiredLoadsAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="Link" href="/expiredloads" rel="ExpiredLoads" >
                                                        Expired Loads
                                                    </a>
                                                    <div className="Number" > {expiredLoadsCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="Card" >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "#14b93a" }}>
                                                    <ExpiredBidsAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="Link" href="/expiredbids" rel="ExpireBids" >
                                                        Expired Bids
                                                    </a>
                                                    <div className="Number" > {expiredBidsCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="Card"  >
                                        <CardContent className="CardContent">
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "#349cffcc" }}>
                                                    <UserManagementAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="Link" href="/systemadmin" rel="NewUsers" >
                                                        User Management
                                                    </a>
                                                    <div className="Number" > {userCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="Card" >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "rgb(0 48 255 / 62%)" }}>
                                                    <CompanyManagementAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                    <a className="Link" href="/systemadmincompanies" rel="Active" >
                                                        Company management
                                                    </a>
                                                    <div className="Number" > {companiesCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Row>
                                <div style={{ height: 40 }} />
                                <Row>
                                    <Card className="Card" >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "#6c757d" }}>
                                                    <SettingsAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="Link" href="/settings" rel="settings" >
                                                        Settings
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Card className="Card"  >
                                        <CardContent className="CardContent" >
                                            <Grid container direction="row">
                                                <Box className="Icons" style={{ backgroundColor: "salmon" }}>
                                                    <MCVerifyAdminIcon />
                                                </Box>
                                                <Grid item xs className="xs" >
                                                    <a className="Link" href="/mcverify" rel="MCVerify" >
                                                        MC Verify
                                                    </a>
                                                    <div className="Number"> {certificateCount} </div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Row>
                            </Box>
                        </main >
                    </Box>
                </Grid>
            </Grid>
        </div >
    )
};

export default Admin;
