import React from "react"
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useWebSocket, { } from 'react-use-websocket';
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import Badge from '@mui/material/Badge';
/***********************SVG ICON***************************/
import { LoadboardIcon } from "../../SvgIcons/LoadboardIcon";
import { ActiveListIcon } from "../../SvgIcons/ActiveListIcon";
import { AllUserIcon } from "../../SvgIcons/AllUser";
import { CompanyProfileIcon } from "../../SvgIcons/CompanyProfileIcon";
import { AwardedLoadIcon } from "../../SvgIcons/AwardedLoadIcon";
import { ExpiredLoadsIcon } from "../../SvgIcons/ExpiredLoadIcon";
import { UserIcon } from "../../SvgIcons/UserIcon";
import { PreferredCarrierIcon } from "../../SvgIcons/PreferredCarrierIcon"
import { LoadsWonIcon } from "../../SvgIcons/LoadsWonIcon"
import { ProspectiveClientIcon } from "../../SvgIcons/ProspectiveCllientIcon"
import { CertificatesIcon } from "../../SvgIcons/CertificatesIcon";
import { DashBoardIcon } from "../../SvgIcons/DashBoardIcon"
import { ExpiredBidIcon } from "../../SvgIcons/ExpiredBidIcon"
import { CompanyManagementIcon } from "../../SvgIcons/CompanyManagement"
import { SaveIcon } from "../../SvgIcons/save"
import { MailSettingIcon } from '../../SvgIcons/MailSettingIcon';
import { MyProfileIccon } from '../../SvgIcons/MyProfileIcon';
import { HomeIcon } from '../../SvgIcons/HomeIcon';
import { MCVerifyIcon } from "../../SvgIcons/MCVerifyIcon";

/*******************************************************************/
import {
    Drawer as MUIDrawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    IconButton,
    Grid,
    Tooltip,
} from "@material-ui/core";
import { environmentVariables } from "../../environment"

/********************************************************************/
const drawerWidth = 230;
const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginLeft: 10,
        marginRight: 2,
        color: "#0b2042"
    },
    hide: {
        display: "none",
    },
    drawer: {
        position: 'relative',
        width: drawerWidth,
        flexShrink: 0,
        marginTop: 91,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        /*zIndex: "auto",*/
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaper: {
        zIndex: "auto",
        width: drawerWidth,
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        /*zIndex: "auto",*/
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    bottomTool: {
        marginBottom: 91,
    }
}));

const Drawer = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { history } = props;
    const { user } = useAuth0();

    const [open, setOpen] = useState(localStorage.getItem('drawerValue'));

    // constants for storing applications details
    const [applicationVersion, setApplicationVersion] = useState("")

    // Constant to handle logged in user role
    const [loggedInuserRole, setLoggedInuserRole] = useState("");

    // Constant to handle logged in company role
    const [loggedInuserCompanyRole, setLoggedInuserCompanyRole] = useState("");

    const [unreadCountForAwardedLoads, setUnreadCountForAwardedLoads] = useState(0)
    const [unreadCountForLoadsWon, setUnreadCountForLoadsWon] = useState(0)
    const [unreadCountForActiveLoads, setUnreadCountForActiveLoads] = useState(0)
    const [unreadCountForSavedLanes, setUnreadCountForSavedLane] = useState(0)

    // Constant to handle Path for SystemAdminUser
    const systemAdminUser = {
        pathname: '/systemadmin',
        hash: '#users',
    }

    // Constant to handle Path for CompanyAdminUser
    const companyAdminUser = {
        pathname: '/companyadmin',
        hash: '#users',
    }

    useEffect(() => {
        checkUserRole();
        getApplicationDetails();
        handleGetNotifications()
    }, [props.notificationSent === true]);

    // const {
    //     sendJsonMessage,
    // } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
    //     {
    //         onMessage: (msg) => recieveMessageForSocketIo(msg),
    //         shouldReconnect: (closeEvent) => true,
    //     });

    // Function to handle send Socket IO messages
    async function sendMessageForSocketIO(message, recieverId) {
        try {
            let response = await axios({
                url: environmentVariables.websocketfetchallusers,
                method: 'get'
            });
            if (response) {
                let connectionIds = []
                response.data.Items.forEach(element => {
                    if (recieverId === null || recieverId === "") {
                        connectionIds.push(element.connectionId)
                    }
                    else {
                        if (recieverId === element.userid) {
                            connectionIds.push(element.connectionId)
                        }
                    }
                });
                // sendJsonMessage({ "action": "onMessage", "message": message, "connectionIds": connectionIds.toString() });
            }
        }
        catch (error) { }
    }

    async function recieveMessageForSocketIo(message) {
        let recievedMessage = message.data.replace(/\"/g, "");
        // Recieve message for a new chat
        if (recievedMessage.includes("newNotification") || recievedMessage.includes("ThereIsNewNotifiction")) {
            handleGetNotifications();
        }
    }

    //Function to check the user role
    function checkUserRole() {
        if (window.localStorage.ifUserExist) {
            if (window.localStorage.getItem('user_role')) {
                setLoggedInuserRole(window.localStorage.getItem('user_role'));
            }
            if (window.localStorage.getItem('company_role')) {
                setLoggedInuserCompanyRole(window.localStorage.getItem('company_role'));
            }
        }
        else if (user.email !== process.env.REACT_APP_ADMIN_USER && user.email !== process.env.REACT_APP_SUPPORT_USER) {
            history.push({ pathname: '/callback' });
        }
    }

    // function to get application details
    async function getApplicationDetails() {
        try {
            let response = await axios({
                url: environmentVariables.getapplicationdetails,
                method: "get",
            })
            let newResponse = JSON.parse(response.data.body)
            setApplicationVersion(newResponse[0][0])
        } catch (error) {
            console.log(error)
        }
    }
    // Function to handle drawer close
    const handleDrawerClose = () => {
        const closed = "false"
        localStorage.setItem('drawerValue', closed)
        setOpen(closed);
    };
    // Function to handle drawer open
    const handleDrawerOpen = () => {
        const opened = "true"
        localStorage.setItem('drawerValue', opened);
        setOpen(opened);
    };

    async function handleGetNotifications() {
        axios({
            url:
                environmentVariables.getnotifications + '?user_id=' + user.email,
            method: "get",
        })
            .then((response) => {
                if (response.data !== undefined) {
                    let notificationDetails = [];
                    let noOfUnreadAwaredMessages = 0
                    let noOfUnreadWonMessages = 0
                    let noOfUnreadActiveMessages = 0
                    let noOfUnreadCountForSavedLanes = 0
                    response.data.forEach(element => {
                        if (element.read_status === false) {
                            if ((element.message === "ThereIsANewMessageFromBroker" && element.won_message === true) || (element.message === "YourLoadHasBennAccepted")) {
                                noOfUnreadWonMessages = noOfUnreadWonMessages + 1
                                notificationDetails.push({ ...element })
                            }
                            else if (element.message === "ThereIsANewMessageFromCarrier" && element.won_message === true) {
                                noOfUnreadAwaredMessages = noOfUnreadAwaredMessages + 1
                                notificationDetails.push({ ...element })
                            }
                            else if ((element.message === "ThereIsANewMessageFromCarrier" || element.message === "ThereIsANewMessageFromBroker" || element.message === "ThereIsANewBid" || element.message === "ABidHasBeenRemoved" || element.message === "ABidHasExpired" || element.message === "ThereIsACounterOffer" || element.message === "CounterOfferIsUpdated")) {
                                noOfUnreadActiveMessages = noOfUnreadActiveMessages + 1
                            }
                            else if (element.message === "ThereIsALoadMatchesYourSavedLane") {
                                noOfUnreadCountForSavedLanes = noOfUnreadCountForSavedLanes + 1
                                notificationDetails.push({ ...element })
                            }
                        }
                        else {
                            notificationDetails.push({ ...element })
                        }
                    });
                    setUnreadCountForAwardedLoads(noOfUnreadAwaredMessages)
                    setUnreadCountForLoadsWon(noOfUnreadWonMessages)
                    setUnreadCountForActiveLoads(noOfUnreadActiveMessages)
                    setUnreadCountForSavedLane(noOfUnreadCountForSavedLanes)
                    notificationDetails.sort((a, b) =>
                        b.time_stamp_notification.localeCompare(a.time_stamp_notification)
                    );
                    let data = JSON.stringify(notificationDetails)
                    const arr = []
                    notificationDetails.forEach(element => {
                        arr.push(element)
                    })
                    sendMessageForSocketIO("ThereIsNewNotifiction", "");
                    return data
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    //function to check logged Role
    function checkLoggedRole() {
        if (loggedInuserCompanyRole === "Broker" || loggedInuserRole === "Broker") {
            history.push({ pathname: '/activeloads' })
        }
        else if ((loggedInuserCompanyRole === "Carrier" || loggedInuserRole === "Carrier")) {
            history.push({ pathname: '/activebids' })
        }
    }
    /***************************************************************************************************************** */
    return (
        <Grid>
            {(props.isValueChanged === undefined) && (
                <MUIDrawer open
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open === "true",
                        [classes.drawerClose]: open === "false",
                    })}
                    classes={{
                        paper: clsx(classes.drawerPaper, {
                            [classes.drawerOpen]: open === "open",
                            [classes.drawerClose]: open === "false",
                        }),
                    }}
                >
                    <div className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            hidden={open === "true"}
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, { [classes.hide]: open === "true", })}>
                            <MenuIcon />
                        </IconButton>
                        <IconButton hidden={open === "false"} onClick={handleDrawerClose}  >
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </div>
                    <Divider />
                    {(window.location.pathname !== "/settings" && window.location.pathname !== "/profile" && window.location.pathname !== "/emailsettings") && (
                        <div>
                            {
                                (user.email === process.env.REACT_APP_ADMIN_USER || user.email === process.env.REACT_APP_SUPPORT_USER) && (
                                    <List >
                                        <ListItem button onClick={() => user.email === process.env.REACT_APP_ADMIN_USER ? history.push({ pathname: '/admin' }) : history.push({ pathname: '/mcverify' })} >
                                            <ListItemIcon>
                                                <Tooltip title="DashBoard">
                                                    <div>
                                                        <DashBoardIcon />
                                                    </div>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={"DashBoard"} />
                                        </ListItem>
                                    </List>
                                )}
                            {
                                (user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                    <div>
                                        <List >
                                            <ListItem button onClick={() => { history.push({ pathname: '/loadboard' }) }} >
                                                <ListItemIcon>
                                                    <Tooltip title="LaodBoard">
                                                        <div>
                                                            <LoadboardIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={"LoadBoard"} />
                                            </ListItem>
                                        </List>
                                        <Divider />
                                    </div>
                                )}

                            {
                                (user.email === process.env.REACT_APP_ADMIN_USER) && (
                                    <div>
                                        <List >
                                            <ListItem button onClick={() => history.push({ pathname: '/mcverify' })} >
                                                <ListItemIcon>
                                                    <Tooltip title="MC Verify">
                                                        <div>
                                                            <MCVerifyIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={"MC Verify"} />
                                            </ListItem>
                                        </List>
                                        <Divider />
                                    </div>
                                )}

                            {/* {
                        (user.email === process.env.REACT_APP_ADMIN_USER) && (
                            <List >
                                    <ListItem button onClick={() => { history.push({ pathname: '/prospectiveclients' }) }} >
                                        <ListItemIcon>
                                            <Tooltip title="Prospective Clients">
                                                <div>
                                                    <ProspectiveClientIcon />
                                                </div>
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={"Prospective Clients"} />
                                    </ListItem>
                                </List>
                        )} */}
                            <Divider />
                            <List>
                                {
                                    (loggedInuserCompanyRole !== "Broker" && loggedInuserRole !== "Broker" && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => { history.push({ pathname: '/savedlanes' }) }} >
                                            <ListItemIcon>
                                                <Tooltip title="Saved Lane">
                                                    <Badge badgeContent={unreadCountForSavedLanes} color="error">
                                                        <SaveIcon />
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={"Saved Lane"} />
                                        </ListItem>
                                    )
                                }
                                {
                                    (user.email !== process.env.REACT_APP_ADMIN_USER && loggedInuserRole !== "Driver" && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => { checkLoggedRole() }} >
                                            <ListItemIcon >
                                                <Tooltip title={(loggedInuserCompanyRole !== "Broker" && loggedInuserRole !== "Broker") ? "Active Bids" : "Active Loads"}>
                                                    <Badge badgeContent={unreadCountForActiveLoads} color="error">
                                                        <ActiveListIcon />
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={(loggedInuserCompanyRole !== "Broker" && loggedInuserRole !== "Broker") ? "Active Bids" : "Active Loads"} />
                                        </ListItem>
                                    )
                                }
                                {
                                    (loggedInuserCompanyRole !== "Carrier" && loggedInuserRole !== "Carrier" && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => { history.push({ pathname: '/awardedloads' }) }} style={(user.email === process.env.REACT_APP_ADMIN_USER && classes.drawerOpen === "open") ? { paddingLeft: "6%" } : { paddingLeft: 14 }}>
                                            <ListItemIcon>
                                                <Tooltip title="Awarded Loads">
                                                    <Badge badgeContent={unreadCountForAwardedLoads} color="error">
                                                        {(user.email !== process.env.REACT_APP_ADMIN_USER) ? <LoadsWonIcon /> : <AwardedLoadIcon />}
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={"Awarded Loads"} />
                                        </ListItem>
                                    )
                                }
                                {
                                    (loggedInuserCompanyRole !== "Broker" && loggedInuserRole !== "Broker" && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => { history.push({ pathname: '/bidswon' }) }} >
                                            <ListItemIcon>
                                                <Tooltip title="Bids Won">
                                                    <Badge badgeContent={unreadCountForLoadsWon} color="error">
                                                        <LoadsWonIcon />
                                                    </Badge>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={"Bids Won"} />
                                        </ListItem>
                                    )
                                }
                                <ListItem button hidden={(loggedInuserRole === "Carrier" || loggedInuserCompanyRole === "Carrier" || user.email === process.env.REACT_APP_SUPPORT_USER) ? true : false} onClick={() => { history.push({ pathname: '/expiredloads' }) }}>
                                    <ListItemIcon>
                                        <Tooltip title="Expired Loads">
                                            <div>
                                                {(user.email !== process.env.REACT_APP_ADMIN_USER) ? <ExpiredBidIcon /> : <ExpiredLoadsIcon />}
                                            </div>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={"Expired Loads"} />
                                </ListItem>
                                {
                                    (loggedInuserCompanyRole !== "Broker" && loggedInuserRole !== "Broker" && user.email !== process.env.REACT_APP_SUPPORT_USER) && (
                                        <ListItem button onClick={() => { history.push({ pathname: '/expiredbids' }) }} >
                                            <ListItemIcon>
                                                <Tooltip title="Expired Bids">
                                                    <div>
                                                        <ExpiredBidIcon />
                                                    </div>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={"Expired Bids"} />
                                        </ListItem>
                                    )
                                }
                            </List>
                            <Divider />
                            {/* {loggedInuserRole === "CompanyAdmin" && (
                        <List >
                            <ListItem button onClick={() => { history.push({ pathname: '/companyprofile' }) }} >
                                <ListItemIcon>
                                    <Tooltip title="Company Profile">
                                        <div>
                                            <CompanyProfileIcon />
                                        </div>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={"Company Profile"} />
                            </ListItem>
                        </List>
                    )} */}
                            {
                                (user.email === process.env.REACT_APP_ADMIN_USER) && (
                                    <div>
                                        <List >
                                            <ListItem className={classes.nestedItems} button onClick={() => { history.push({ pathname: '/systemadmin' }) }}>
                                                <ListItemIcon>
                                                    <Tooltip title="All User">
                                                        <div>
                                                            <AllUserIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={"All User"} />
                                            </ListItem>
                                            <a href="/systemadmin#users">
                                                <ListItem button onClick={() => { history.push(systemAdminUser) }}>
                                                    <ListItemIcon>
                                                        <Tooltip title="Users">
                                                            <div>
                                                                <UserIcon />
                                                            </div>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary={"Users"} style={{ color: "black" }} />
                                                </ListItem>
                                            </a>
                                            {/* <ListItem button onClick={() => { history.push({ pathname: '/systemadmincompanies' }) }}>
                                        <ListItemIcon>
                                            <Tooltip title="Companies">
                                                <CompanyManagementIcon />
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={"Companies"} style={{ color: "black" }} />
                                    </ListItem> */}
                                        </List>
                                    </div>
                                )
                            }
                            {
                                (loggedInuserRole === "CompanyAdmin") && (
                                    <div>
                                        <List >
                                            <ListItem button onClick={() => { history.push({ pathname: '/companyadmin' }) }}>
                                                <ListItemIcon>
                                                    <Tooltip title="All User">
                                                        <div>
                                                            <AllUserIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={"All User"} />
                                            </ListItem>
                                            <a href="/companyadmin#users">
                                                <ListItem button onClick={() => { history.push(companyAdminUser) }}>
                                                    <ListItemIcon>
                                                        <Tooltip title="Users">
                                                            <div>
                                                                <UserIcon />
                                                            </div>
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary={"Users"} style={{ color: "black" }} />
                                                </ListItem>
                                            </a>
                                            {/* <ListItem className={classes.nestedItems} button >
                                    <ListItemIcon>
                                        <Tooltip title="Company Profile">
                                            <DashboardIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={"Company Profile"} />
                                </ListItem> */}
                                            <ListItem style={{ paddingLeft: 10 }} button onClick={() => { history.push({ pathname: '/preferredcarrier' }) }} hidden={(loggedInuserCompanyRole === "Carrier")}>
                                                <ListItemIcon>
                                                    <Tooltip title="Preferred Carriers">
                                                        <div>
                                                            <PreferredCarrierIcon />
                                                        </div>
                                                    </Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary={"Preferred Carriers"} />
                                            </ListItem>
                                        </List>
                                    </div>
                                )
                            }
                            {
                                (loggedInuserRole === "CompanyAdmin" && loggedInuserCompanyRole === "Carrier" && user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                    <List>
                                        <ListItem button style={{ paddingTop: 0 }} onClick={() => { history.push({ pathname: '/documents' }) }}>
                                            <ListItemIcon>
                                                <Tooltip title="Documents">
                                                    <div>
                                                        <CertificatesIcon />
                                                    </div>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={"Documents"} />
                                        </ListItem>
                                    </List>
                                )}
                        </div>)}

                    {(window.location.pathname === "/settings" || window.location.pathname === "/profile" || window.location.pathname === "/emailsettings") && (
                        <div>
                            <List>
                                <ListItem button onClick={() => { history.push({ pathname: '/callback' }) }} >
                                    <ListItemIcon>
                                        <Tooltip title="Home">
                                            <div>
                                                <HomeIcon />
                                            </div>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={"Home"} />
                                </ListItem>
                            </List>
                            <ListItem button onClick={() => { history.push({ pathname: '/profile' }) }} >
                                <ListItemIcon>
                                    <Tooltip title="Profile">
                                        <div>
                                            <MyProfileIccon />
                                        </div>
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={"Profile"} />
                            </ListItem>
                            {(user.email !== process.env.REACT_APP_ADMIN_USER) && (
                                <ListItem button onClick={() => { history.push({ pathname: '/emailsettings' }) }} >
                                    <ListItemIcon>
                                        <Tooltip title="Email Settings">
                                            <div>
                                                <MailSettingIcon />
                                            </div>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={"Email Settings"} />
                                </ListItem>
                            )}
                            <div style={{ marginBottom: "110%" }}></div>
                            {(applicationVersion !== "" && open === "true") && (
                                <div style={{ marginLeft: "3%" }}>
                                    <span
                                        style={{ padding: "0%", margin: "0%", fontSize: "12px", color: "#AEAEAE", position: "fixed" }}>Revision: {applicationVersion}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    <div className={classes.bottomTool}></div>
                </MUIDrawer>
            )}
        </Grid>
    )
}
export default withRouter(Drawer);