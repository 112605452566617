import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import { useHistory } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.css";
import Swal from 'sweetalert2';
import Notifications from '../Notifications';
import { Button } from "@material-ui/core";

const Header = (props) => {
  const history = useHistory();
  const { user, logout, loginWithRedirect, isAuthenticated } = useAuth0();
  const [shouldOpenUploadForm, setShouldOpenUploadForm] = useState(false)
  const [loggedInCompanyRole, setLoggedInCompanyRole] = useState(window.localStorage.getItem('company_role'));
  const [loggedInUserRole, setLoggedInUserRole] = useState(window.localStorage.getItem('user_role'));

  // function to handle sign in button click
  const handleSignInUser = () => {
    loginWithRedirect()
    window.localStorage.removeItem('LoadId');
  }

  // function to handle signout button click
  async function logoutWithRedirect() {
    Swal.fire({
      text: "Are you sure you want to sign out?",
      cancelButtonColor: '#0066cc',
      confirmButtonText: 'Ok',
      type: null,
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#0066cc'
    }).then(async (result) => {
      if (result.value) {
        window.localStorage.removeItem('panelValue');
        window.localStorage.removeItem('company_role');
        window.localStorage.removeItem('company_mc_number');
        window.localStorage.removeItem('login_status');
        window.localStorage.removeItem('ifUserExist');
        window.localStorage.removeItem('user_role');
        window.localStorage.removeItem('userCompanyName');
        window.localStorage.removeItem('mc_certified');
        window.localStorage.removeItem('userName');
        logout({
          returnTo: window.location.origin,
        });
      }
    })
  };

  const handleOpenCertificationForm = () => {
    history.push('/documents')
  }

  return (
    <header>
      <div className="header-style-1" style={{ height: 70 }}>
        <div>
          <div className="row">
            <div className="col-lg-3 col-md-10 col-sm-10 col-8 col-t">
              <div className="logo">
                <Link to="/#" style={{ marginLeft: 50 }}>

                  <img
                    width="220"
                    height="48"
                    src="https://loadeo-logo.s3.us-east-2.amazonaws.com/Loadeo_logo_header.png"
                    alt=""
                    className="imgsize"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-8 d-none d-lg-block col-m">
              <div className="main-menu">
                <nav className="nav_mobile_menu">
                  <ul>
                    {
                      isAuthenticated && (!window.localStorage.getItem('mc_certified') || window.localStorage.getItem('mc_certified') !== "Verified") && (loggedInCompanyRole === "Carrier") && (user.email !== process.env.REACT_APP_ADMIN_USER) && (
                        <Button disabled={loggedInUserRole !== "CompanyAdmin"} onClick={() => handleOpenCertificationForm()} style={{ textTransform: "none", color: "#FEDE00", marginTop: "1%" }}>Click Here to Verify your Account</Button>
                      )
                    }

                    {
                      (isAuthenticated && user.email !== "support.team@loadeo.com") && (
                        <li style={{ paddingBottom: 60 }}>
                          <Notifications closeSecondaryPane={props.closeSecondaryPane} notificationSent={props.notificationSent} />
                        </li>
                      )
                    }
                    {
                      !isAuthenticated && (
                        <li onClick={() => loginWithRedirect({ action: 'signUp' })}><Link style={{ textTransform: "none", fontSize: 16 }}> Create New Account</Link></li>
                      )
                    }
                    {isAuthenticated ? (
                      <li><Link style={{ textTransform: "capitalize", fontSize: 16 }} >{user.nickname}</Link>
                        <ul className="submenu">
                          {/* // Removing Update profile as it is not modified
                          <li><Link to="/updateprofile">Update Profile</Link></li> */}
                          {(user.email !== "support.team@loadeo.com" && user.email !== "admin@loadeo.com") && (
                            <li onClick={() => history.push({ pathname: '/settings' })}><Link to="/profile" style={{ textTransform: "none", fontSize: 16, textAlign: "center" }}>Settings</Link></li>
                          )}
                          <li onClick={() => logoutWithRedirect()}><Link style={{ textTransform: "none", fontSize: 16, textAlign: "center" }}>Sign Out</Link></li>

                        </ul>
                      </li>

                    ) : (
                      <li onClick={() => handleSignInUser()}>
                        <Link style={{ textTransform: "none", fontSize: 16 }}>Sign In</Link>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-md-1 col-sm-1 col-2">
              <MobileMenu />
            </div>
            {/* {
                (windowOpen) && (
                 
                )
              } */}
          </div>
        </div>
      </div>
    </header >
  );
};
export default Header;
