export const ExpiredBidsAdminIcon = () => {
    return (
        <svg width="49" height="40" style= {{backgroundColor: "#14b93a", margin: "10% 20% 25% 15%"}} viewBox="0 0 150 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="145" height="141" rx="10" />
            <path d="M66.4281 61.5716V78H73V58.2863C73 56.4697 71.5303 55 69.7146 55H50V61.5716H66.4281Z" fill="white" />
            <path d="M64.4994 22C41.5815 22 23 40.5815 23 63.4994C23 86.4172 41.5815 105 64.4994 105H72.7998V96.6996H64.4994C46.1644 96.6996 31.3004 81.8344 31.3004 63.4994C31.3004 45.1644 46.1644 30.3004 64.4994 30.3004C82.8344 30.3004 97.6996 45.1644 97.6996 63.4994V71.7998H106V63.4994C106 40.5815 87.4172 22 64.4994 22Z" fill="white" />
            <path d="M69 95C69 93.9895 69 89.2456 69 87" stroke="white" stroke-width="3" />
            <path d="M42.7124 58.0479C41.7195 57.8603 37.0581 56.9795 34.8516 56.5625" stroke="white" stroke-width="3" />
            <path d="M43.898 72.0009C43.0267 72.5127 38.9363 74.9154 37 76.0527" stroke="white" stroke-width="3" />
            <path d="M52.917 83C52.2959 83.7971 49.3802 87.5392 48 89.3105" stroke="white" stroke-width="3" />
            <path d="M44.0004 39.9991C44.6867 40.7408 47.9085 44.2228 49.4336 45.8711" stroke="white" stroke-width="3" />
            <path d="M62.9995 41.995C63.0351 40.9851 63.2022 36.2442 63.2812 34" stroke="white" stroke-width="3" />
            <path d="M77.2639 44.192C77.869 43.3827 80.7099 39.5836 82.0547 37.7852" stroke="white" stroke-width="3" />
            <path d="M86.2065 54.0868C87.1106 53.6352 91.3544 51.5152 93.3633 50.5117" stroke="white" stroke-width="3" />
            <path d="M95 68.9991C93.9894 69.0022 89.2456 69.0166 87 69.0234" stroke="white" stroke-width="3" />
            <path d="M96.2732 76.3158C89.7009 88.7748 78.8961 107.723 74.7211 115.005C73.9555 116.34 74.9235 118 76.4628 118H119.601C121.126 118 122.09 116.362 121.349 115.029L99.8189 76.274C99.0423 74.8762 97.0193 74.9015 96.2732 76.3158Z" fill="white" />
            <path d="M99.8561 93.3529L99.5772 103.609H96.9596L96.6735 93.3529H99.8561ZM98.2684 108.186C97.7964 108.186 97.3911 108.019 97.0526 107.685C96.7141 107.347 96.5472 106.942 96.552 106.469C96.5472 106.002 96.7141 105.602 97.0526 105.268C97.3911 104.934 97.7964 104.767 98.2684 104.767C98.7214 104.767 99.1195 104.934 99.4628 105.268C99.8061 105.602 99.9801 106.002 99.9849 106.469C99.9801 106.784 99.8967 107.073 99.7345 107.335C99.5772 107.592 99.3698 107.8 99.1123 107.957C98.8549 108.11 98.5736 108.186 98.2684 108.186Z" fill="#17B8A6" />
        </svg>
    )
}
