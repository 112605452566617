import React, { useState, useEffect } from 'react';
import {
    Grid, TextField,
    Box, Button, Snackbar, Typography, IconButton, Tooltip,
} from '@material-ui/core';
import {
    makeStyles,
} from "@material-ui/core/styles";
import { useAuth0 } from '@auth0/auth0-react';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DatePicker from "react-datepicker";
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import useWebSocket, { } from 'react-use-websocket';
import MuiAlert from "@material-ui/lab/Alert";
import { createMuiTheme, MuiThemeProvider, } from "@material-ui/core/styles";

//import statement for mui data table loader
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from "axios";
import { Col } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import Swal from 'sweetalert2';
import { Row } from "react-bootstrap";
import { uploadFile, deleteFile } from "react-s3";
import * as AWS from "aws-sdk";
import Navbar from '../../Navbar';
import MUIDrawer from '../../Drawer';
import { environmentVariables } from '../../../environment';
import './index.css'
import { handleLocalStorageValue } from '../../../LocalStorageValue';

const s3 = new AWS.S3({
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY, accessKeyId: process.env.REACT_APP_ACCESS_KEY, region: process.env.REACT_APP_PROSPECTIVE_BUCKET
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        marginTop: 10,
    },
    menuButton: {
        marginLeft: 5,
        marginRight: 10,
    },
    hide: {
        display: "none",
    },
    toolbar: {
        marginTop: 91,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    customPaper: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: "100%",
    },
    CustomInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    rightAlign: {
        marginLeft: "25px",
        textTransform: "none",
        fontSize: 16,
    },
    leftAlign: {
        marginLeft: "25px",
        textTransform: "none",
        fontSize: 16,
        color: "#101e35"
    },
    indicator: {
        backgroundColor: '#101e35',
        color: "#101e35"
    }

}));

const Certifications = () => {

    const classes = useStyles();
    const { user } = useAuth0();
    const DIRECTORY = "loadeo-MC-Certifications";
    const [serviceStartDate, setServiceStartDate] = useState(new Date())
    const [errorMessage, setErrorMessage] = useState("")
    const [fileName, setFileName] = useState("")
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileInBinary, setSelectedFileInBinary] = useState("")
    const [isPaneOpen, setIsPaneOpen] = useState(false)
    const [verificationStatus, setVerificationStatus] = useState("");
    const [certificateLink, setCertificateLink] = useState("")
    const [mainGridPercent, setMainGridPercent] = useState("100%");
    const [certificationID, setCertificationID] = useState()
    const [verifiedTimeStamp ,setVerifiedTimeStamp] = useState("")
    const [companyId, setCompanyId] = useState("")
    const [documentComment, setDocumentComment] = useState("")
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [colorseverity, setColorseverity] = useState("")
    const [snackBarMessageText, setSnackBarMessageText] = useState("")
    const [loading, setLoading] = useState(false)
    const [isFetched, setIsFetched] = useState(false)
    const [data, setData] = useState([])

    // Constant to handle errors
    const [errors, setErrors] = useState({});

    //constant to handle logged in company name
    const [loggedInCompanyName, setLoggedInCompanyName] = useState(window.localStorage.getItem('userCompanyName'));
    const [loggedInCompanyMCNumber, setLoggedInCompanyMCNumber] = useState(window.localStorage.getItem('company_mc_number'));

    const config = {
        bucketName: process.env.REACT_APP_PROSPECTIVE_BUCKET,
        region: process.env.REACT_APP_S3_BUCKET_REGION,
        dirName: DIRECTORY,
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,

    }

    useEffect(() => {
        handleGetCertificate()
        handleLocalStorageValue(user.email)
    }, []);

    //Constant to handle socket connections
    const {
        sendJsonMessage,
    } = useWebSocket(environmentVariables.websocketurl + '?userid=' + user.email,
        {
            onMessage: (msg) => recieveMessageForSocketIo(msg),
            shouldReconnect: (closeEvent) => true,
        });

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: "none",
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        "&:nth-child(odd)": {
                            backgroundColor: "#ECECEC",
                        },
                        "&:hover": { backgroundColor: "#aab5be!important" },
                        "&:focus": { backgroundColor: "#000!important" },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        paddingLeft: "1.5%"
                    }
                },
                MUIDataTableHeadCell: {
                    root: {
                        paddingLeft: "2%"
                    }
                }
            }
        });

    const options = {
        filter: false,
        search: false,
        responsive: "vertical",
        selectableRows: 'none',
        download: false,
        print: false,
        viewColumns: false,
        onRowClick: (rowData, rowState) =>
            handleDetails(rowData, rowState),
        textLabels: {
            body: {
                noMatch: !loading ?
                    <CircularProgress /> :
                    'No loads are posted'
            },
        }
    };

    // Function to get all certificates 
    async function handleGetCertificate() {
        if (loggedInCompanyMCNumber) {
            try {
                let response = await axios({
                    url: environmentVariables.getmccertificate +
                        "?mc_number=" +
                        loggedInCompanyMCNumber,
                    method: "get"
                })
                if (response) {
                    let newResponse = JSON.parse(response.data.body)
                    if (newResponse.length > 0 && newResponse !== "NoCertificateAreAvailable") {
                        setData(newResponse)
                        setLoading(false)
                        setIsFetched(true)
                    }
                    else {
                        setLoading(true)
                        setIsFetched(true)
                    }
                }
            } catch (error) {
                console.log("get cretificate error", error)
            }
        }
    }

    const emptyData = [["", loggedInCompanyName, loggedInCompanyMCNumber, "NA", "MC Certificate", "Upload Pending", "NA"]]
    const emptyColumnDefs = ["Certicate ID", "Company Name", "MC Number", "Certificate Issue Date", "Document Type", "Status", "Comments"]

    const columnDefs = [
        { label: "Certificate ID", name: "certificate_id", options: { display: false } },
        {
            label: "Company Name", name: 'company_name'
        },
        {
            label: "MC Number",
            name: 'company_mc_number',
        },
        {
            label: "Certificate Issue Date",
            name: "service_start_date",
        },
        {
            label: "Document Type",
            name: "",
            options: {
                customBodyRender: (tableMeta, rowuserdata) => {
                    return "MC Certificate"
                }
            }
        },
        {
            name: "verification_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, rowData) => {
                    if (value === "Verified") {
                        return (
                           <span style={{color: "#32CD32", fontWeight:"bold"}}>{value}</span>
                        )
                    }
                    else if (value === "Not Verified" | value === "Error") {
                        return (
                            <span style={{ color: "Red", fontWeight: "bold" }}>{value}</span>
                        )
                    }
                    else if (value === "Document not matching") {
                        return (
                            <span style={{ color: "#FAD02C", fontWeight: "bold" }}>{value}</span>
                        )
                    }
                    else {
                        return (
                            <span>{value}</span>
                        )
                    }
                }
            }
        },
        {
            name: "comment_reason",
            label: "Comments",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, rowData) => {
                    if (value !== null && value !== "") {
                        return (
                            <div>
                                <Tooltip title={value}>
                                    <p style={{ fontFamily: "sans-serif", paddingTop: "5%", color: "black" }}>
                                        {value.slice(0, 19) + "..."}
                                    </p>
                                </Tooltip>
                            </div>
                        )
                    }
                    else {
                        return "NA"
                    }
                }
            }
        },
    ];

    // Function to recive all the socket io messages
    async function recieveMessageForSocketIo(message) {
        let recievedMessage = message.data.replace(/\"/g, "");

        if (recievedMessage.includes("Your MC Certificate Verification is Succeeded")) {
            window.localStorage.removeItem('mc_certified')
            handleLocalStorageValue(user.email)
        }
    }

    // Function to handle MuiAlert
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // Function to handle field validation 
    const validate = () => {
        let certificateValidation = {}
        certificateValidation.fileName = fileName ? "" : "error"

        if (selectedFile === null) {
            setErrorMessage("empty file")
        }
        else {
            setErrorMessage("")
        }

        setErrors({
            ...certificateValidation
        })
        return Object.values(certificateValidation).every(x => x === "");
    }

    // Function to handle Snackbar close event
    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false)
    }

    // Function to handle Date picker Onchange event
    const handleServiceStartDateChange = (date) => {
        setServiceStartDate(date);
    }

    async function handleGetCompanyInfo() {
        try {
            let response = await axios({
                url: environmentVariables.getcompanyinformation + "?company_mc_number=" + loggedInCompanyMCNumber,
                method: 'get',
            })
            if (response) {
                setCompanyId(JSON.parse(response.data.body)[0].company_id)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    // Function to handle Row click
    const handleDetails = (selectedRowState, rowState) => {
        if (data.length > 0) {
            if (selectedRowState !== undefined && selectedRowState !== null) {
                let completeSelectedRowData = data.find(({ certificate_id }) => certificate_id === selectedRowState[0])
                if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
                    setVerificationStatus(completeSelectedRowData.verification_status);
                    setFileName(completeSelectedRowData.certificate_link.split("loadeo-MC-Certifications/")[1])
                    setCertificateLink(completeSelectedRowData.certificate_link)
                    setCertificationID(completeSelectedRowData.certificate_id)
                    setServiceStartDate(new Date(completeSelectedRowData.service_start_date))
                    setDocumentComment(completeSelectedRowData.comment_reason)
                    setVerifiedTimeStamp(moment.utc(completeSelectedRowData.time_stamp).local().format("YYYY-MM-DD HH:mm:ss"))
                }
            }
        }
        else {
            setVerificationStatus("Upload Pending");
        }
        handleGetCompanyInfo();
        setMainGridPercent("45%");
        setIsPaneOpen(true);
    }

    // Function to handle Cancel button click
    const handleClearFields = () => {
        setVerificationStatus("Upload Pending");
        setFileName("")
        setCertificateLink("")
        setCertificationID("")
        setDocumentComment("")
        setSelectedFile(null)
        setErrorMessage("")
        setServiceStartDate(new Date())
    }

    // Function to handle Secondary panel close
    const closeSecondaryPane = () => {
        setMainGridPercent("100%");
        setIsPaneOpen(false);
        handleClearFields()
    }

    // Function to handle Onchange event of input file field
    const handleFileChange = event => {
        if (event.target.files[0] && event.target.files[0].name) {
            setFileName(event.target.files[0].name)
        }
        if (event.target.files[0] && event.target.files[0].size > 5e6) {
            setErrorMessage("max size")
        }
        else {
            setErrorMessage("")
        }
        var file = event.target.files[0];
        var temp = file.slice(0, file.size);
        setSelectedFile(new File([temp], `${loggedInCompanyName}.pdf`));
        handleConvertingBase64(file).then(
            (data) => {
                setSelectedFileInBinary(data)
            }
        );
    }

    // function to convert normal file to base64 format
    function handleConvertingBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded);
            };
            reader.onerror = error => reject(error);
        });
    }

    // Function to reset input File field
    const onInputClick = (event) => {
        event.target.value = ''
    }

    const handleRemoveFileButton = () => {
        setFileName("")
        setSelectedFile(null)
        setErrorMessage("")
    }

    // Function to handle Save button click
    async function handleSave() {
        if (validate() && errorMessage === "") {
            setDisableSubmitButton(true)
            uploadFile(selectedFile, config)
                .then((data) => {
                    handleSaveButtonChange(data.location)
                })
                .catch((err) => {
                    console.log("upload error", err)
                }
                )
        }
    }

    // Function to handle Delete button click
    async function handleDeleteFile() {
        if (verificationStatus === "Verified") {
            Swal.fire({
                title: '<h5>Are you sure you want to Delete this file?</h5>',
                html:
                    '<i>Note : Deleting file will change status to Unverified and you will have to Re-verify your MC certificate</i>',
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            })
                .then((result) => {
                    if (result.value) {
                        deleteFile(certificateLink.split("loadeo-MC-Certifications/")[1], config)
                            .then(response => {
                                handleDeleteButtonClick()
                            })
                            .catch(err => console.error("delete file error", err))
                    }
                })
        }
        else {
            Swal.fire({
                text: "Are you sure you want to Delete this file?",
                cancelButtonColor: '#0066cc',
                confirmButtonText: 'Ok',
                type: null,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0066cc'
            }).then((result) => {
                if (result.value) {
                    deleteFile(certificateLink.split("loadeo-MC-Certifications/")[1], config)
                        .then(response => {
                            handleDeleteButtonClick()
                        })
                        .catch(err => console.error("delete file error", err))
                }
            })
        }

    }

    async function handleGetVerificationStatus(mcNumber) {
        try {
            let response = await axios({
                url: "https://flask-api.stage.loadeo.com/api/loadeo_get_verification_status?mc_number=" + mcNumber,
                method: 'get',
            })
            if (response) {
                setVerificationStatus(JSON.parse(response.data.body)[0].verification_status)
                setOpenSnackBar(false);
            }
        }
        catch (error) {
            console.log("get status error", error)
        }
    }

    // Function to handle Submit confirmation button click
    async function handleSaveButtonChange(fileLocation) {
        let diffDays
        setOpenSnackBar(true)
        setSnackBarMessageText("Verification processing...")
        setColorseverity("info")
        closeSecondaryPane()

        try {
            let response = await axios({
                url: environmentVariables.extractJSONdata,
                method: 'post',
                data: {
                    customerId: companyId,
                    fileBinary: selectedFileInBinary,
                    fileName: `${loggedInCompanyName}.pdf`,
                    Environment: "stage"
                }
            })
            if (response) {
                let completeSelectedRowData = response.data
                if (completeSelectedRowData.service_date) {
                    completeSelectedRowData.service_date = moment(completeSelectedRowData.service_date).format("MM/DD/YYYY")
                }
                if(response !== "Exception in text extraction :'NoneType' object has no attribute 'group'"){
                    const date1 = new Date(completeSelectedRowData.service_date);
                    const date2 = new Date();
                    const diffTime = Math.abs(date2 - date1);
                    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                }
                try {
                    let uploadResponse = await axios({
                        url: environmentVariables.addmccertificate,
                        method: 'post',
                        data: {
                            'time_stamp': moment.utc().format("YYYY-MM-DD HH:mm:ss"),
                            'certificate_link': fileLocation,
                            'company_name': loggedInCompanyName,
                            'service_start_date': moment(serviceStartDate).format("MM/DD/YYYY"),
                            'extracted_data': completeSelectedRowData,
                            'company_mc_number': loggedInCompanyMCNumber,
                            'company_id': companyId,
                            'day_difference':diffDays
                        }
                    })
                    if (uploadResponse) {
                        handleGetVerificationStatus(loggedInCompanyMCNumber)
                        setOpenSnackBar(false)
                        handleGetCertificate()
                        handleLocalStorageValue(user.email)
                        setDisableSubmitButton(false)
                    }
                }
                catch (error) {
                    setDisableSubmitButton(false)
                }
            }
        }
        catch (error) {
            setDisableSubmitButton(false)
            setOpenSnackBar(true)
            setSnackBarMessageText("Verification Failed")
            setColorseverity("error")
        }
    }

    // Function to handle Delete confirmation button click
    async function handleDeleteButtonClick() {
        let completeSelectedRowData = data.find(({ certificate_id }) => certificate_id === certificationID)
        if (completeSelectedRowData !== undefined && completeSelectedRowData !== null) {
            completeSelectedRowData.verification_status = ""
        }
        try {
            let response = await axios({
                url: environmentVariables.deletemccertificate,
                method: "patch",
                data: completeSelectedRowData
            })
            if (response) {
                window.localStorage.removeItem('mc_certified')
                handleLocalStorageValue(user.email)
                closeSecondaryPane()
                setColorseverity("error")
                setSnackBarMessageText("File Delete Succeeded")
                setOpenSnackBar(true)
                handleGetCertificate()
                setData([])
                closeSecondaryPane()
            }
        } catch (error) {
            console.log("delete cretificate error", error)
        }
    }

    return (
        <div className={classes.root}>
            <Navbar />
            <MUIDrawer />
            <Snackbar
                open={openSnackBar}
                autoHideDuration={snackBarMessageText === "Verification processing..." ? null : 3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={colorseverity}>
                    {snackBarMessageText}
                </Alert>
            </Snackbar>
            <main className={classes.content}>
                <Typography component={'div'} style={{ paddingBottom: "15%" }}>
                    <Box boxShadow={3} color="black" border={0} padding={1} >
                        <Grid container direction="row">
                            <Grid xs={12} sm={14}>
                                <Typography component={'div'}>
                                    <Grid container direction="row" margin={2}>
                                        <div style={{ width: mainGridPercent }} >
                                            <MuiThemeProvider theme={getMuiTheme()}>
                                                <MUIDataTable
                                                    title={"Documents"}
                                                    data={(data.length === 0 && isFetched === true) ? emptyData : data}
                                                    columns={(data.length === 0 && isFetched === true) ? emptyColumnDefs : columnDefs}
                                                    options={options}
                                                ></MUIDataTable>
                                            </MuiThemeProvider>
                                        </div>
                                        {isPaneOpen && (
                                            <React.Fragment>
                                                <div style={{ width: "1%" }}></div>
                                                <div style={{ width: "54%" }} border={1}>
                                                    <Box
                                                        boxShadow={3}
                                                        style={{ width: "100%", overflowY: "scroll", maxHeight: "100%", height: "100%" }}
                                                        color="black"
                                                        border={0}
                                                        padding={2}
                                                    >
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={4}>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ fontSize: 18 }} justify-content="flex-end">
                                                                {(verificationStatus === "Upload Pending") ? <b>Upload Certificate</b> : <b>View Certificate</b>}
                                                            </Grid>
                                                            <Grid container xs={4} justify="flex-end">
                                                                <IconButton onClick={closeSecondaryPane}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                        <Row >
                                                            <Col sm>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ height: 5, paddingTop: "5%" }} />
                                                        <a style={{ fontSize: "14px",fontStyle: "italic", paddingLeft: "9%" }} href="https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-stage/loadeo-mc-certificates/sample-mc-certificate/sample_template.pdf" rel="noopener nor">
                                                            Click here
                                                        </a>
                                                        <Typography variant='caption' style={{ fontSize: "14px", fontStyle: "italic" }}> to view the sample of the document needed   </Typography>
                                                        <div style={{ height: 5, paddingTop: 5 }} />
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={1}>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <div style={{ height: "15%" }}></div>
                                                                <Grid container direction="column" spacing={1}>
                                                                    <Grid item>
                                                                        <TextField disabled label="Company Name" style={{ width: "85%" }} variant="outlined" value={loggedInCompanyName}></TextField>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <TextField disabled label="MC Number" style={{ width: "85%" }} variant="outlined" value={loggedInCompanyMCNumber}></TextField>
                                                                    </Grid>
                                                                    {(verificationStatus === "Verified") && (
                                                                        <Grid item>
                                                                            <TextField disabled label="Verified Time" style={{ width: "85%" }} variant="outlined" value={verifiedTimeStamp}></TextField>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <span style={{ fontSize: "12px" }}>Certificate Issue Date</span>
                                                                <Grid container direction="column" spacing={1}>
                                                                    <Grid item>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} width={{ width: "100%", height: "90%" }}>
                                                                            <DatePicker
                                                                                id="servicedate"
                                                                                selected={serviceStartDate}
                                                                                value={new Date()}
                                                                                dateFormat="MM/dd/yyyy"
                                                                                onChange={handleServiceStartDateChange}
                                                                                autoComplete='off'
                                                                                disabled={verificationStatus !== "Upload Pending"}
                                                                            />
                                                                        </MuiPickersUtilsProvider>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <input type="file" id="myFile" disabled={verificationStatus !== "Upload Pending"} onChange={handleFileChange} onClick={onInputClick}></input>
                                                                        <br />
                                                                        {(fileName !== "" && verificationStatus === "Upload Pending") ? (
                                                                            <div className='fileName'>
                                                                                <Row>
                                                                                    <Col >
                                                                                        <span id="file-chosen">{fileName === "" ? "No file chosen" : fileName.slice(0, 22) + ".."}</span>
                                                                                    </Col>
                                                                                    <Col sm={3}>
                                                                                        <IconButton style={{ fontSize: '5px', padding: '0px' }} onClick={handleRemoveFileButton}>
                                                                                            <CloseIcon style={{ fontSize: '12px', padding: '0px' }} />
                                                                                        </IconButton>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ) :
                                                                            (<span id="file-chosed">{fileName}</span>)}
                                                                        {(errorMessage !== "") && (
                                                                            <span id="invalid-file-chosen">{errorMessage === "empty file" ? "Choose file (Max 5MB)" : "File exceeded 5MB"}</span>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {(documentComment !== "" && documentComment !== null) && (
                                                            <Grid container spacing={3}>
                                                                <Grid item sm={1}>
                                                                </Grid >
                                                                <Grid item>
                                                                    <TextField
                                                                        label="Comment"
                                                                        autoComplete='off'
                                                                        multiline
                                                                        rows={4}
                                                                        variant="outlined"
                                                                        style={{
                                                                            width: "235%",
                                                                        }}
                                                                        value={documentComment}
                                                                        disabled={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={1}>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                        <div style={{ paddingTop: "10%" }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space between', width: "100%" }}>
                                                                {(verificationStatus === "Upload Pending") && (
                                                                    <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <Button variant="contained" onClick={handleSave} disabled={disableSubmitButton} style={{ textTransform: "none", color: "white", backgroundColor: "black" }}>Submit</Button>
                                                                        <span style={{ margin: "3%" }}></span>
                                                                        <Button variant="contained" onClick={handleClearFields} style={{ textTransform: "none", color: "white", backgroundColor: "black" }}>Clear</Button>
                                                                    </Box>
                                                                )}
                                                                {(verificationStatus !== "Upload Pending") && (
                                                                    <Box paddingRight={5} paddingLeft={5} style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <Button variant="contained" href={certificateLink} style={{ textTransform: "none", color: "white", backgroundColor: "black" }}>View</Button>
                                                                        <span style={{ margin: "3%" }}></span>
                                                                        <Button variant="contained" onClick={handleDeleteFile} style={{ textTransform: "none", color: "white", backgroundColor: "black" }}>Delete</Button>
                                                                    </Box>
                                                                )}

                                                            </div>
                                                        </div>
                                                        <Grid style={{ fontSize: 14 }} xs={12}>
                                                            <Grid container direction="row">
                                                                <div style={{ paddingTop: "5%" }}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={1}>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                        </Grid>
                                                                        <Grid item xs={5}>
                                                                        </Grid>
                                                                        <Grid item xs={3}>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Typography>
            </main >
        </div >
    )
}
export default Certifications;